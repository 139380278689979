import {
    Box,
    SimpleGrid,
} from "@chakra-ui/react";
import ItemList from "./components/itemList";




export default function Item() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px"></SimpleGrid>
                <ItemList />
            </SimpleGrid>
            <SimpleGrid
            columns={{ base: 1, md: 1, xl: 1 }}
            gap="20px"
            mb="20px"
            ></SimpleGrid>
        </Box>
    )
}