import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#E6EAF2", // Gris très clair
      200: "#B8C4D9", // Bleu-gris clair
      300: "#8A9CBF", // Bleu-gris moyen
      400: "#5C74A6", // Bleu-gris foncé
      500: "#2E4C8C", // Bleu corporatif principal
      600: "#1E3366", // Bleu foncé
      700: "#0F1A40", // Bleu très foncé
      800: "#060D20", // Presque noir
      900: "#020408", // Noir profond
    },
    brandScheme: {
      100: "#E6EAF2",
      200: "#B8C4D9",
      300: "#8A9CBF",
      400: "#5C74A6",
      500: "#2E4C8C",
      600: "#1E3366",
      700: "#0F1A40",
      800: "#060D20",
      900: "#020408",
    },
    brandTabs: {
      100: "#E6EAF2",
      200: "#2E4C8C",
      300: "#2E4C8C",
      400: "#2E4C8C",
      500: "#2E4C8C",
      600: "#1E3366",
      700: "#0F1A40",
      800: "#060D20",
      900: "#020408",
    },
    secondaryGray: {
      100: "#F7F9FC",
      200: "#EDF1F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
    red: {
      100: "#FFF5F5",
      500: "#E53E3E",
      600: "#C53030",
    },
    blue: {
      50: "#EBF8FF",
      500: "#3182CE",
    },
    orange: {
      100: "#FFFAF0",
      500: "#DD6B20",
    },
    green: {
      100: "#F0FFF4",
      500: "#38A169",
    },
    navy: {
      50: "#E6EAF2",
      100: "#B8C4D9",
      200: "#8A9CBF",
      300: "#5C74A6",
      400: "#2E4C8C",
      500: "#1E3366",
      600: "#0F1A40",
      700: "#060D20",
      800: "#020408",
      900: "#010204",
    },
    gray: {
      100: "#F7FAFC",
    },
    darkBg: "#0A1929", // Fond sombre pour le mode sombre
    darkPaper: "#0F2942", // Couleur de fond pour les éléments de type "paper" en mode sombre
    darkBorder: "#1E3A5F", // Ajout de la couleur de bordure sombre
    darkText: "#E6EAF2", // Couleur de texte principale en mode sombre
    darkTextSecondary: "#B8C4D9", // Couleur de texte secondaire en mode sombre
    darkTableHeader: "#1E3A5F", // Ajout de la couleur de ligne de tableau sombre
    darkTableRow: "#0D2235", // Ajout de la couleur de ligne de tableau sombre
    darkTableRowAlt: "#11283E", // Ajout de la couleur de ligne de tableau sombre alternative
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("secondaryGray.100", "darkBg")(props),
        color: mode("secondaryGray.900", "darkText")(props),
        fontFamily: "Roboto, sans-serif",
        letterSpacing: "-0.5px",
      },
      input: {
        color: mode("secondaryGray.700", "darkTextSecondary")(props),
      },
      html: {
        fontFamily: "Roboto, sans-serif",
      },
    }),
  },
};
