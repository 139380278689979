/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    SimpleGrid,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
  } from "@chakra-ui/react";
  import React, { useContext } from "react";
  import { AuthContext } from "contexts/AuthContext";
  import AgenciesList from "./components/agenciesList";
  import ManageAgencies from "./components/manageAgencies";
  import ManagersManagement from "./managers/ManagersManagement";
  
  export default function UserReports() {
    const { user } = useContext(AuthContext);
  
    if (!user.profileData?.admin) {
      return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <AgenciesList />
            <ManageAgencies />
          </SimpleGrid>
        </Box>
      );
    }
  
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Tabs variant="soft-rounded" colorScheme="blue">
          <TabList mb="1em">
            <Tab>Agences</Tab>
            <Tab>Managers</Tab>
          </TabList>
          
          <TabPanels>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
                <AgenciesList />
                <ManageAgencies />
              </SimpleGrid>
            </TabPanel>
            
            <TabPanel>
              <ManagersManagement />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
  