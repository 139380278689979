import React, { useState, useEffect, useContext } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
  Select,
  Text,
  VStack,
  useMediaQuery,
  FormErrorMessage,
  Heading,
  SimpleGrid,
  InputGroup,
  InputLeftAddon,
  Divider,
  Spinner,
  Badge,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
} from '@chakra-ui/react';
import axios from 'axios';
import { AuthContext } from 'contexts/AuthContext';
import { db } from 'lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useData } from 'contexts/DataProvider';

const AdresseForm = () => {
  const [numeroRue, setNumeroRue] = useState('');
  const [rue, setRue] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [vendeur, setVendeur] = useState('');
  const [adressesSoumises, setAdressesSoumises] = useState([]);
  const [adressesRenvoyees, setAdressesRenvoyees] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { user } = useContext(AuthContext);
  const [isMobile] = useMediaQuery("(max-width: 48em)");
  const [teamMembers, setTeamMembers] = useState([]);
  const { getVendorName } = useData();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [allVendors, setAllVendors] = useState([]);

  useEffect(() => {
    const checkUserRole = async () => {
      if (user && user.email) {
        try {
          const usersCollection = collection(db, 'users');
          const q = query(usersCollection, where('email', '==', user.email));
          const userSnapshot = await getDocs(q);
          const userData = userSnapshot.docs[0]?.data();
          const isAdminUser = userData?.admin === true;
          const isManagerUser = userData?.manager === true || userData?.leader === true || userData?.superManager === true;
          
          setIsAdmin(isAdminUser);
          setIsManager(isManagerUser);
          
          // Si c'est un admin, on met "all" par défaut
          if (isAdminUser) {
            setVendeur('all');
          }
          // Si c'est un manager, récupérer son équipe
          else if (isManagerUser) {
            const teamSalescode = userData.teamSalescode || [];
            const teamMembersData = [];
            
            for (const salesCode of teamSalescode) {
              if (salesCode) {
                teamMembersData.push({
                  salesCode,
                  name: getVendorName(salesCode)
                });
              }
            }
            
            setTeamMembers(teamMembersData);
            if (teamMembersData.length > 0) {
              setVendeur(teamMembersData[0].salesCode);
            }
          } else {
            // Si c'est un vendeur normal
            setVendeur(userData?.salesCode);
          }
        } catch (error) {
          console.error("Erreur lors de la vérification des rôles:", error);
        }
      }
    };

    checkUserRole();
  }, [user, getVendorName]);

  useEffect(() => {
    const fetchAllVendors = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const vendors = usersSnapshot.docs
          .map(doc => ({ 
            salesCode: doc.data().salesCode,
            name: doc.data().name || doc.data().email
          }))
          .filter(vendor => vendor.salesCode);
        setAllVendors(vendors);
      } catch (error) {
        console.error("Erreur lors de la récupération des vendeurs:", error);
      }
    };

    if (isAdmin) {
      fetchAllVendors();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (vendeur) {
      fetchAdresses(vendeur);
      const interval = setInterval(() => {
        fetchAdresses(vendeur);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [vendeur]);

  const fetchAdresses = async (salesCode) => {
    try {
      const endpoint = (!salesCode || salesCode === 'all') 
        ? 'https://app.falconmarketing.fr:3001/api/adresse'
        : `https://app.falconmarketing.fr:3001/api/adresse/${salesCode}`;

      const response = await axios.get(endpoint);
      const sortedAdresses = response.data.sort((a, b) => b.id - a.id);
      
      const filteredAdresses = (!salesCode || salesCode === 'all') && isManager && !isAdmin
        ? sortedAdresses.filter(addr => teamMembers.some(member => member.salesCode === addr.salesCode))
        : sortedAdresses;
      
      const soumises = filteredAdresses.filter(addr => !addr.traite).slice(0, 10);
      const renvoyees = filteredAdresses.filter(addr => addr.traite).slice(0, 10);
      
      setAdressesSoumises(soumises);
      setAdressesRenvoyees(renvoyees);
    } catch (error) {
      console.error('Erreur lors de la récupération des adresses:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les adresses',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
  
    // Validation pour le numéro de rue : ne doit pas se terminer par un espace
    if (numeroRue.endsWith(' ')) {
      newErrors.numeroRue = "Le numéro de rue ne doit pas se terminer par un espace.";
    }
  
    // Nouvelle validation pour le nom de la rue
    if (rue.endsWith(' ')) {
      newErrors.rue = "Le nom de la rue ne doit pas se terminer par un espace.";
    }
  
    // Validation pour le code postal
    if (!/^\d{5}$/.test(codePostal)) {
      newErrors.codePostal = "Le code postal doit être composé de 5 chiffres.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsSubmitting(true);
    try {
      await axios.post('https://app.falconmarketing.fr:3001/api/adresse', {
        numeroRue,
        rue,
        codePostal,
        salesCode: vendeur,
      });
      
      toast({
        title: 'Adresse soumise',
        description: 'L\'adresse a été soumise avec succès pour affectation.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNumeroRue('');
      setRue('');
      setCodePostal('');
      
      fetchAdresses(vendeur);
    } catch (error) {
      console.error("Erreur lors de la soumission de l'adresse:", error);
      toast({
        title: 'Erreur',
        description: error.response?.data || 'Une erreur est survenue',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getBadgeContent = (adresse) => {
    if (adresse.traite) {
      if (adresse.AD) {
        return { 
          text: `AD: ${adresse.AD}`, 
          color: "green", 
          icon: null,
          subText: (isAdmin || isManager) ? `Demandé par ${getVendorName(adresse.salesCode)}` : null
        };
      }
      return { 
        text: "Pas d'adresse", 
        color: "red", 
        icon: null,
        subText: (isAdmin || isManager) ? `Demandé par ${getVendorName(adresse.salesCode)}` : null
      };
    }
    const position = adresse.position || 'N/A';
    return { 
      text: `Position ${position}`, 
      color: "orange", 
      icon: <Spinner size="xs" />,
      subText: (isAdmin || isManager) ? `Demandé par ${getVendorName(adresse.salesCode)}` : null
    };
  };

  return (
    <Box p={4} mt={isMobile ? '20px' : '20px'}>
      <Heading as="h1" size="xl" mb={6}>Soumission d'adresse</Heading>
      
      <Accordion allowToggle mb={6}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Comment remplir le formulaire ?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text mb={2}>Pour le numéro :</Text>
            <Text mb={2}>- Exemple : "35" est OK</Text>
            <Text mb={2}>- Exemple : pour "35 BIS", mettez "35 B" (avec l'espace entre les deux)</Text>
            <Text mb={2}>Pour la rue :</Text>
            <Text mb={2}>- Exemple : pour "AVENUE DU PEUPLE BELGE", mettez "BELGE" (un seul mot)</Text>
            <Text mb={2}>- Ne pas mettre d'accent ou d'apostrophe</Text>
            <Text mb={4}>Avant de demander l'affectation, vérifiez les adresses éligibles à la fibre ici :</Text>
            <Link href="https://www.sfr.fr/offre-internet/test-eligibilite-adsl-vdsl-fibre" isExternal color="blue.500">
              Test d'éligibilité SFR
            </Link>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={[1, null, 2]} spacing={6}>
          <FormControl isRequired mb={4}>
            <FormLabel>Vendeur</FormLabel>
            {isAdmin ? (
              <Select
                value={vendeur}
                onChange={(e) => setVendeur(e.target.value)}
                placeholder="Choisir un vendeur"
              >
                <option value="all">Tous les vendeurs</option>
                {allVendors.map((v) => (
                  <option key={v.salesCode} value={v.salesCode}>
                    {v.name}
                  </option>
                ))}
              </Select>
            ) : isManager ? (
              <Select
                value={vendeur}
                onChange={(e) => setVendeur(e.target.value)}
                placeholder="Choisir un vendeur"
              >
                <option value="all">Tous les vendeurs</option>
                {teamMembers.map((member) => (
                  <option key={member.salesCode} value={member.salesCode}>
                    {member.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Input
                value={getVendorName(vendeur)}
                isReadOnly
                bg="gray.100"
                _hover={{ cursor: 'not-allowed' }}
              />
            )}
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.numeroRue}>
            <FormLabel htmlFor='numeroRue'>Numéro de rue</FormLabel>
            <Input
              id='numeroRue'
              placeholder='Ex: 123 ou 123 B'
              value={numeroRue}
              onChange={(e) => setNumeroRue(e.target.value)}
            />
            <FormErrorMessage>{errors.numeroRue}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.rue}>
            <FormLabel htmlFor='rue'>Rue</FormLabel>
            <Input
              id='rue'
              placeholder='Ex: Paix'
              value={rue}
              onChange={(e) => setRue(e.target.value)}
            />
            <FormErrorMessage>{errors.rue}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.codePostal}>
            <FormLabel htmlFor='codePostal'>Code Postal</FormLabel>
            <Input
              id='codePostal'
              placeholder='Ex: 75000'
              value={codePostal}
              onChange={(e) => setCodePostal(e.target.value)}
            />
            <FormErrorMessage>{errors.codePostal}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Button
          mt={6}
          colorScheme="blue"
          isLoading={isSubmitting}
          type="submit"
          width="100%"
        >
          Soumettre l'adresse
        </Button>
      </form>

      <Divider my={8} />

      <VStack spacing={6} align="stretch" mt={6}>
        {adressesSoumises.length > 0 && (
          <Box>
            <Heading as="h2" size="md" mb={4}>Adresses en attente d'affectation</Heading>
            <SimpleGrid columns={[1, 1, 2]} spacing={4}>
              {adressesSoumises.map((adresse) => (
                <Box
                  key={adresse.id}
                  borderWidth="1px"
                  borderRadius="lg"
                  bg="white"
                  shadow="sm"
                  overflow="hidden"
                >
                  <Box p={4} bg="gray.50">
                    <Badge
                      colorScheme={getBadgeContent(adresse).color}
                      fontSize="sm"
                      px={2}
                      py={1}
                      borderRadius="md"
                    >
                      {getBadgeContent(adresse).text}
                      {getBadgeContent(adresse).icon && (
                        <Box as="span" ml={1} verticalAlign="middle">
                          {getBadgeContent(adresse).icon}
                        </Box>
                      )}
                    </Badge>
                    {getBadgeContent(adresse).subText && (
                      <Text fontSize="sm" color="gray.600" mt={2}>
                        {getBadgeContent(adresse).subText}
                      </Text>
                    )}
                  </Box>
                  <Box p={4}>
                    <Text fontSize="lg" fontWeight="500" mb={2}>
                      {adresse.numeroRue || ''} {adresse.rue || ''}
                    </Text>
                    <Text fontSize="md" color="gray.600">
                      {adresse.codePostal || ''}
                    </Text>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        )}

        {adressesRenvoyees.length > 0 && (
          <Box>
            <Heading as="h2" size="md" mb={4}>Adresses traitées</Heading>
            <SimpleGrid columns={[1, 1, 2]} spacing={4}>
              {adressesRenvoyees.map((adresse) => (
                <Box
                  key={adresse.id}
                  borderWidth="1px"
                  borderRadius="lg"
                  bg="white"
                  shadow="sm"
                  overflow="hidden"
                >
                  <Box p={4} bg="gray.50">
                    <Badge
                      colorScheme={getBadgeContent(adresse).color}
                      fontSize="sm"
                      px={2}
                      py={1}
                      borderRadius="md"
                    >
                      {getBadgeContent(adresse).text}
                      {getBadgeContent(adresse).icon && (
                        <Box as="span" ml={1} verticalAlign="middle">
                          {getBadgeContent(adresse).icon}
                        </Box>
                      )}
                    </Badge>
                    {getBadgeContent(adresse).subText && (
                      <Text fontSize="sm" color="gray.600" mt={2}>
                        {getBadgeContent(adresse).subText}
                      </Text>
                    )}
                  </Box>
                  <Box p={4}>
                    <Text fontSize="lg" fontWeight="500" mb={2}>
                      {adresse.numeroRue || ''} {adresse.rue || ''}
                    </Text>
                    <Text fontSize="md" color="gray.600">
                      {adresse.codePostal || ''}
                    </Text>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default AdresseForm;