import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  Flex,
  Box,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';

function ForgotPassword() {
  const [email, setEmail] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { resetPassword } = React.useContext(AuthContext);
  const toast = useToast();

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const result = await resetPassword(email);
    
    if (result.success) {
      toast({
        title: 'Email envoyé',
        description: 'Vérifiez votre boîte mail pour réinitialiser votre mot de passe.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setEmail('');
    } else {
      toast({
        title: 'Erreur',
        description: result.error || 'Une erreur est survenue',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Mot de passe oublié
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Entrez votre email pour réinitialiser votre mot de passe
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <FormLabel
              color={textColor}
              fontSize="sm"
              fontWeight="500"
              mb="8px"
            >
              Email
            </FormLabel>
            <Input
              type="email"
              placeholder="Entrez votre email"
              color={textColor}
              fontSize="md"
              borderRadius="15px"
              _hover={{ borderColor: 'brand.500' }}
              _focus={{ borderColor: 'brand.500' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button
            color={textColorBrand}
            fontSize="sm"
            fontWeight="500"
            w="100%"
            h="40px"
            mt="24px"
            borderRadius="15px"
            _hover={{ bg: 'brand.500' }}
            _focus={{ bg: 'brand.500' }}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            Réinitialiser le mot de passe
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword; 