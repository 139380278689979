// AgencyCard.js
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Text,
  VStack,
  Spinner,
  useColorModeValue,
  Icon,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Divider,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  Badge,
  Tooltip,
  Progress,
  SimpleGrid,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Tag,
  TagLabel,
  TagLeftIcon,
  Circle,
  Heading,
} from '@chakra-ui/react';
import {
  FaUserTie,
  FaUsers,
  FaExternalLinkAlt,
  FaEdit,
  FaTrash,
  FaBuilding,
  FaLayerGroup,
  FaUserAlt,
  FaEllipsisV,
  FaCrown,
  FaChartLine,
  FaCalendarAlt,
  FaCheckCircle,
  FaExclamationCircle,
  FaUserPlus,
  FaInfoCircle,
} from 'react-icons/fa';
import { useData } from 'contexts/DataProvider';
import LeaderTable from './leaderTable';
import { collection, getDocs, doc, updateDoc, deleteDoc, query, where, writeBatch, getDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { AuthContext } from 'contexts/AuthContext';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';

// Ajouter ces styles personnalisés au début du fichier
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#E2E8F0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#CBD5E0',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

// Composant MenuList virtualisé pour react-select
const VirtualizedMenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={Math.min(maxHeight, options.length * height)}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const AgencyCard = ({ agency, onDelete, onUpdate, isTeam = false }) => {
  const { user, userAgencies } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorNames, setVendorNames] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedAgency, setEditedAgency] = useState({ ...agency });
  const [availableVendors, setAvailableVendors] = useState([]);
  const [agencyStats, setAgencyStats] = useState({
    teamsCount: 0,
    activeVendors: 0,
    totalVendors: 0,
    performance: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const statBg = useColorModeValue('blue.50', 'blue.900');
  const menuBg = useColorModeValue('white', 'gray.700');

  // Fonction pour charger les statistiques de l'agence
  const fetchAgencyStats = async () => {
    setIsLoading(true);
    try {
      if (!isTeam && agency.id) {
        // Récupérer le nombre d'équipes
        const teamsQuery = query(collection(db, 'teams'), where('agencyId', '==', agency.id));
        const teamsSnapshot = await getDocs(teamsQuery);
        
        // Récupérer les statistiques des vendeurs
        const vendorsQuery = query(collection(db, 'vendors'), where('agencyId', '==', agency.id));
        const vendorsSnapshot = await getDocs(vendorsQuery);
        
        const activeVendors = vendorsSnapshot.docs.filter(doc => doc.data().isActive).length;
        
        // Calculer la performance (exemple simple)
        const performance = (activeVendors / (agency.vendors?.length || 1)) * 100;

        setAgencyStats({
          teamsCount: teamsSnapshot.size,
          activeVendors,
          totalVendors: agency.vendors?.length || 0,
          performance: Math.round(performance)
        });
      } else {
        // Pour une équipe, calculer uniquement les statistiques pertinentes
        const activeVendors = (agency.vendors || []).length;
        setAgencyStats({
          teamsCount: 0,
          activeVendors,
          totalVendors: activeVendors,
          performance: 95 // Valeur par défaut pour l'exemple
        });
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour charger les noms des vendeurs
  const fetchVendorNames = async () => {
    try {
      const vendorsRef = collection(db, 'vendors');
      const vendorsSnapshot = await getDocs(vendorsRef);
      const vendors = {};
      vendorsSnapshot.forEach((doc) => {
        vendors[doc.id] = doc.data().Name;
      });
      setVendorNames(vendors);
    } catch (error) {
      console.error('Erreur lors du chargement des noms des vendeurs:', error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        fetchVendorNames(),
        fetchAgencyStats()
      ]);
    };
    loadData();
  }, [agency.id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEdit = () => {
    setEditedAgency({
      ...agency,
      vendors: agency.vendors?.map(id => ({
        value: id,
        label: vendorNames[id] || id
      })) || []
    });
    fetchAvailableVendors();
    setIsEditModalOpen(true);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();

    const confirmMessage = isTeam
      ? "Êtes-vous sûr de vouloir supprimer cette équipe ?"
      : "Êtes-vous sûr de vouloir supprimer cette agence ?";

    if (window.confirm(confirmMessage)) {
      try {
        const batch = writeBatch(db);

        if (isTeam) {
          // Supprimer l'équipe
          const teamRef = doc(db, 'teams', agency.id);
          batch.delete(teamRef);

          // Mettre à jour l'agence parente pour retirer l'ID de l'équipe
          const agencyRef = doc(db, 'agencies', agency.agencyId);
          const agencyDoc = await getDoc(agencyRef);
          if (agencyDoc.exists()) {
            const teams = agencyDoc.data().teams || [];
            batch.update(agencyRef, {
              teams: teams.filter(id => id !== agency.id)
            });
          }

          // Mettre à jour le teamSalescode des managers
          const managersQuery = query(
            collection(db, 'users'),
            where('salesCode', '==', agency.manager)
          );
          const managersSnapshot = await getDocs(managersQuery);
          managersSnapshot.forEach(managerDoc => {
            const teamSalescode = managerDoc.data().teamSalescode || [];
            batch.update(doc(db, 'users', managerDoc.id), {
              teamSalescode: teamSalescode.filter(code => !agency.vendors.includes(code))
            });
          });
        } else {
          // Supprimer l'agence
          const agencyRef = doc(db, 'agencies', agency.id);
          batch.delete(agencyRef);

          // Supprimer toutes les équipes associées
          const teamsQuery = query(collection(db, 'teams'), where('agencyId', '==', agency.id));
          const teamsSnapshot = await getDocs(teamsQuery);
          teamsSnapshot.forEach(teamDoc => {
            batch.delete(teamDoc.ref);
          });

          // Mettre à jour le teamSalescode des managers
          if (agency.managers && agency.managers.length > 0) {
            const managersQuery = query(
              collection(db, 'users'),
              where('salesCode', 'in', agency.managers)
            );
            const managersSnapshot = await getDocs(managersQuery);
            managersSnapshot.forEach(managerDoc => {
              const teamSalescode = managerDoc.data().teamSalescode || [];
              batch.update(doc(db, 'users', managerDoc.id), {
                teamSalescode: teamSalescode.filter(code => !agency.vendors.includes(code))
              });
            });
          }

          // Mettre à jour les vendeurs pour retirer leur agencyId
          agency.vendors?.forEach(vendorId => {
            const vendorRef = doc(db, 'vendors', vendorId);
            batch.update(vendorRef, {
              agencyId: null
            });
          });
        }

        await batch.commit();
        await onDelete(agency.id);

        alert(`${isTeam ? "L'équipe" : "L'agence"} a été supprimée avec succès`);
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        alert(`Une erreur est survenue lors de la suppression de ${isTeam ? "l'équipe" : "l'agence"}`);
      }
    }
  };

  const handleUpdateAgency = async () => {
    try {
      const batch = writeBatch(db);
      const newVendorIds = editedAgency.vendors.map(v => v.value);
      const oldVendorIds = agency.vendors || [];

      // Mettre à jour les vendeurs retirés (retirer agencyId)
      const removedVendors = oldVendorIds.filter(id => !newVendorIds.includes(id));
      removedVendors.forEach(vendorId => {
        const vendorRef = doc(db, 'vendors', vendorId);
        batch.update(vendorRef, { agencyId: null });
      });

      // Mettre à jour les nouveaux vendeurs (ajouter agencyId)
      const addedVendors = newVendorIds.filter(id => !oldVendorIds.includes(id));
      addedVendors.forEach(vendorId => {
        const vendorRef = doc(db, 'vendors', vendorId);
        batch.update(vendorRef, { agencyId: agency.id });
      });

      // Mettre à jour le teamSalescode des managers
      if (!isTeam) {
        const managersQuery = query(
          collection(db, 'users'),
          where('salesCode', 'in', agency.managers || [])
        );
        const managersSnapshot = await getDocs(managersQuery);
        managersSnapshot.forEach(managerDoc => {
          const existingTeamSalescode = managerDoc.data().teamSalescode || [];
          const updatedTeamSalescode = Array.from(new Set([
            ...existingTeamSalescode,
            ...newVendorIds
          ]));
          batch.update(doc(db, 'users', managerDoc.id), {
            teamSalescode: updatedTeamSalescode
          });
        });
      } else {
        const managerQuery = query(
          collection(db, 'users'),
          where('salesCode', '==', agency.manager)
        );
        const managerSnapshot = await getDocs(managerQuery);
        managerSnapshot.forEach(managerDoc => {
          const existingTeamSalescode = managerDoc.data().teamSalescode || [];
          const updatedTeamSalescode = Array.from(new Set([
            ...existingTeamSalescode,
            ...newVendorIds
          ]));
          batch.update(doc(db, 'users', managerDoc.id), {
            teamSalescode: updatedTeamSalescode
          });
        });
      }

      // Mettre à jour l'agence ou l'équipe
      const docRef = doc(db, isTeam ? 'teams' : 'agencies', agency.id);
      batch.update(docRef, {
        name: editedAgency.name,
        vendors: newVendorIds
      });

      await batch.commit();
      await onUpdate(agency.id, {
        ...editedAgency,
        vendors: newVendorIds
      });
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      alert('Une erreur est survenue lors de la mise à jour');
    }
  };

  // Ajouter une fonction pour charger les vendeurs disponibles
  const fetchAvailableVendors = async () => {
    try {
      if (user.profileData?.admin) {
        // Admin peut voir tous les vendeurs
        const vendorsSnapshot = await getDocs(collection(db, 'vendors'));
        const vendorsData = vendorsSnapshot.docs.map(doc => ({
          value: doc.id,
          label: doc.data().Name
        }));
        vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
        setAvailableVendors(vendorsData);
      } else if (user.profileData?.manager) {
        // Manager ne peut voir que les vendeurs de ses agences
        const vendorIds = new Set();
        userAgencies.forEach(userAgency => {
          if (userAgency.vendors && Array.isArray(userAgency.vendors)) {
            userAgency.vendors.forEach(vendorId => vendorIds.add(vendorId));
          }
        });

        if (vendorIds.size > 0) {
          const vendorsQuery = query(
            collection(db, 'vendors'),
            where('__name__', 'in', Array.from(vendorIds))
          );
          const vendorsSnapshot = await getDocs(vendorsQuery);
          const vendorsData = vendorsSnapshot.docs.map(doc => ({
            value: doc.id,
            label: doc.data().Name
          }));
          vendorsData.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
          setAvailableVendors(vendorsData);
        } else {
          setAvailableVendors([]);
        }
      }
    } catch (error) {
      console.error('Erreur lors du chargement des vendeurs:', error);
    }
  };

  const canEdit = user.profileData?.admin || 
                  (user.profileData?.superManager && agency.superManager === user.profileData.salesCode) ||
                  (user.profileData?.manager && isTeam);

  const canDelete = user.profileData?.admin || 
                   (user.profileData?.superManager && agency.superManager === user.profileData.salesCode);

  // Calcul des statistiques
  const vendorsCount = agency.vendors?.length || 0;
  const managersCount = isTeam ? 1 : (agency.managers?.length || 0);
  const isActive = vendorsCount > 0;

  return (
    <Box
      bg={cardBg}
      color={textColor}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      p={6}
      width="100%"
      boxShadow="sm"
      transition="all 0.3s"
      _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
      position="relative"
      opacity={isLoading ? 0.7 : 1}
    >
      {isLoading && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          justify="center"
          align="center"
          bg="rgba(255, 255, 255, 0.7)"
          zIndex="1"
          borderRadius="xl"
        >
          <Spinner size="xl" color="blue.500" />
        </Flex>
      )}

      {/* Header avec avatar et badges */}
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <HStack spacing={4}>
          <Avatar
            size="lg"
            icon={<Icon as={isTeam ? FaUsers : FaBuilding} fontSize="2rem" />}
            bg={isTeam ? 'green.500' : 'blue.500'}
            color="white"
          />
          <Box>
            <Heading size="md" mb={2}>{agency.name}</Heading>
            <HStack spacing={2} flexWrap="wrap">
              <Badge 
                colorScheme={isTeam ? 'green' : 'blue'} 
                fontSize="sm"
                px={2} 
                py={1} 
                borderRadius="full"
              >
                {isTeam ? 'Équipe' : 'Agence'}
              </Badge>
              <Badge 
                colorScheme={isActive ? 'green' : 'yellow'}
                fontSize="sm"
                px={2}
                py={1}
                borderRadius="full"
              >
                {isActive ? 'Active' : 'En attente'}
              </Badge>
              {agency.createdAt && (
                <Tooltip label={`Créé le ${new Date(agency.createdAt).toLocaleDateString()}`}>
                  <Tag size="sm" variant="subtle" colorScheme="gray">
                    <TagLeftIcon as={FaCalendarAlt} />
                    <TagLabel>
                      {new Date(agency.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
                    </TagLabel>
                  </Tag>
                </Tooltip>
              )}
              {agencyStats.performance > 0 && (
                <Tooltip label="Performance globale">
                  <Tag size="sm" colorScheme={agencyStats.performance > 80 ? 'green' : 'yellow'}>
                    <TagLeftIcon as={FaChartLine} />
                    <TagLabel>{agencyStats.performance}%</TagLabel>
                  </Tag>
                </Tooltip>
              )}
            </HStack>
          </Box>
        </HStack>

        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FaEllipsisV />}
            variant="ghost"
            size="sm"
            aria-label="Options"
          />
          <MenuList bg={menuBg}>
            <MenuItem icon={<FaExternalLinkAlt />} onClick={handleOpenModal}>
              Voir les détails
            </MenuItem>
            {canEdit && (
              <MenuItem icon={<FaEdit />} onClick={handleEdit}>
                Modifier
              </MenuItem>
            )}
            <MenuDivider />
            {canDelete && (
              <MenuItem icon={<FaTrash />} onClick={handleDelete} color="red.500">
                Supprimer
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>

      <Divider mb={6} />

      {/* Statistiques avec animations */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={6}>
        <Stat 
          bg={statBg} 
          p={3} 
          borderRadius="lg"
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.02)' }}
        >
          <StatLabel>
            <HStack>
              <Icon as={isTeam ? FaUserAlt : FaUsers} />
              <Text>{isTeam ? "Chef d'équipe" : 'Managers'}</Text>
            </HStack>
          </StatLabel>
          <StatNumber fontSize="2xl">{managersCount}</StatNumber>
          <Progress 
            value={managersCount > 0 ? 100 : 0} 
            size="xs" 
            colorScheme="blue" 
            mt={2}
            hasStripe
            isAnimated
          />
        </Stat>

        <Stat 
          bg={statBg} 
          p={3} 
          borderRadius="lg"
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.02)' }}
        >
          <StatLabel>
            <HStack>
              <Icon as={FaUserTie} />
              <Text>Vendeurs</Text>
            </HStack>
          </StatLabel>
          <StatNumber fontSize="2xl">
            {agencyStats.activeVendors}/{agencyStats.totalVendors}
          </StatNumber>
          <Progress 
            value={(agencyStats.activeVendors / Math.max(agencyStats.totalVendors, 1)) * 100} 
            size="xs" 
            colorScheme="green" 
            mt={2}
            hasStripe
            isAnimated
          />
        </Stat>

        <Stat 
          bg={statBg} 
          p={3} 
          borderRadius="lg"
          transition="all 0.3s"
          _hover={{ transform: 'scale(1.02)' }}
        >
          <StatLabel>
            <HStack>
              <Icon as={isTeam ? FaChartLine : FaLayerGroup} />
              <Text>{isTeam ? 'Performance' : 'Équipes'}</Text>
            </HStack>
          </StatLabel>
          <StatNumber fontSize="2xl">
            {isTeam ? `${agencyStats.performance}%` : agencyStats.teamsCount}
          </StatNumber>
          {isTeam ? (
            <StatHelpText>
              <StatArrow type="increase" />
              Performance mensuelle
            </StatHelpText>
          ) : (
            <Progress 
              value={(agencyStats.teamsCount / 5) * 100} 
              size="xs" 
              colorScheme="purple" 
              mt={2}
              hasStripe
              isAnimated
            />
          )}
        </Stat>
      </SimpleGrid>

      {/* Détails */}
      <Box>
        {agency.superManager && (
          <HStack mb={4}>
            <Circle size="30px" bg="purple.100">
              <Icon as={FaCrown} color="purple.500" />
            </Circle>
            <Text>
              Super Manager: {vendorNames[agency.superManager] || agency.superManager}
            </Text>
          </HStack>
        )}

        {/* Indicateurs d'état */}
        <SimpleGrid columns={2} spacing={4}>
          <HStack>
            <Icon 
              as={isActive ? FaCheckCircle : FaExclamationCircle} 
              color={isActive ? 'green.500' : 'yellow.500'} 
            />
            <Text>{isActive ? 'Agence active' : 'En attente d\'activation'}</Text>
          </HStack>
          <HStack>
            <Icon as={FaUserPlus} color="blue.500" />
            <Text>{vendorsCount} vendeur{vendorsCount > 1 ? 's' : ''} assigné{vendorsCount > 1 ? 's' : ''}</Text>
          </HStack>
        </SimpleGrid>
      </Box>

      {/* Modals */}
      <LeaderTable
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        salesCodes={agency.vendors}
        agencyId={agency.id}
        isTeam={isTeam}
      />

      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Icon as={isTeam ? FaUsers : FaBuilding} />
              <Text>Modifier {isTeam ? "l'équipe" : "l'agence"}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6}>
              <FormControl>
                <FormLabel>Nom {isTeam ? "de l'équipe" : "de l'agence"}</FormLabel>
                <Input
                  value={editedAgency.name}
                  onChange={(e) =>
                    setEditedAgency({ ...editedAgency, name: e.target.value })
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  <HStack>
                    <Icon as={FaUserTie} />
                    <Text>Gestion des vendeurs</Text>
                    <Tooltip label="Sélectionnez les vendeurs à assigner">
                      <Icon as={FaInfoCircle} color="gray.500" />
                    </Tooltip>
                  </HStack>
                </FormLabel>
                <Select
                  isMulti
                  options={availableVendors}
                  value={editedAgency.vendors}
                  onChange={(selectedOptions) =>
                    setEditedAgency({ ...editedAgency, vendors: selectedOptions || [] })
                  }
                  placeholder="Sélectionnez les vendeurs..."
                  closeMenuOnSelect={false}
                  components={{ MenuList: VirtualizedMenuList }}
                  styles={customStyles}
                />
                <Text fontSize="sm" color="gray.500" mt={2}>
                  {editedAgency.vendors?.length || 0} vendeur(s) sélectionné(s)
                </Text>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateAgency}>
              Mettre à jour
            </Button>
            <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AgencyCard;
