import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Box,
    useDisclosure,
    Image,
    Checkbox,
    ButtonGroup,
} from "@chakra-ui/react";
import { nFormatter } from "components/utils/utils";

import Coin from "../../../../assets/img/dashboards/coin.png"
import Chest from "../../../../assets/img/dashboards/chest.png"
import { fetchWaitingReward, changeWaitingRewardState } from "../services/waitingRewardService";


// Fonction pour afficher la liste des items
export default function RewardList() {
    // État local pour stocker la liste des items
    const [documents, setDocuments] = useState([]);
    const [allDocuments, setAllDocuments] = useState([]);
    // État local pour gérer la pagination
    const [currentPage, setCurrentPage] = useState(1);
    // État local pour stocker l'ID du item à supprimer
    const [deletingItemId, setDeletingItemId] = useState(null);
    // State pour le gerer la selection d'un item
    const [selectedDoc, setSelectedDoc] = useState(null);
    // État de chargement
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Hooks de gestion de l'ouverture et de la fermeture des modales
    const {
        isOpen: isModifyOpen,
        onOpen: onModifyOpen,
        onClose: onModifyClose
    } = useDisclosure();
    const {
        isOpen: isAddOpen,
        onOpen: onAddOpen,
        onClose: onAddClose
    } = useDisclosure();
    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure();

    // Constantes pour la pagination
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageCount = Math.ceil(documents.length / itemsPerPage);

    // Fonction pour gérer le changement de page
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    const fetchDocuments = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const waitingRewards = await fetchWaitingReward();
            // Vérification et nettoyage des données
            const validRewards = waitingRewards.filter(reward => 
                reward && reward.id && reward.user && reward.item
            );
            setDocuments(validRewards);
            setAllDocuments(validRewards);
        } catch (error) {
            console.error("Error fetching items: ", error);
            setError("Une erreur est survenue lors du chargement des récompenses.");
        } finally {
            setIsLoading(false);
        }
    };

    const modifyDocument = async (waitingRewardId, isCompleted) => {
        try {
            await changeWaitingRewardState(waitingRewardId, isCompleted);
            setDocuments(documents.map((doc) => doc.id === waitingRewardId ? { ...doc, completed: isCompleted } : doc));
            setAllDocuments(allDocuments.map((doc) => doc.id === waitingRewardId ? { ...doc, completed: isCompleted } : doc)); // Met à jour la variable allDocuments
            console.log("statut changé !");
        } catch(error) {
            console.error("Error modifying items: ", error);
        }
    }

    // Fonction pour ouvrir la modal de confirmation de suppression d'un item
    const openDeleteConfirmation = (itemId) => {
        setDeletingItemId(itemId);
        onDeleteOpen();
    };

    // Fonction pour ouvrir la modal de modification d'un item
    const editDocument = (doc) => {
        setSelectedDoc(doc);
        onModifyOpen();
    };

    // Effet pour récupérer la liste des items lors du premier rendu
    useEffect(() => {
        fetchDocuments()
    }, []);

    // Fonction pour afficher l'icône de récompense en fonction du type
    const getIcon = (rewardType) => {
        if (rewardType === "falcoins") return <Image src={Coin} width={6} height={6} />
        if (rewardType === "coffre") return <Image src={Chest} width={6} height={6} />
        else return (" " + rewardType)
    }

    // Rendu du composant
    return (
        <>
            {/* Bouton pour filtrer les éléments */}
            <Box mb={4}>
                <ButtonGroup>
                    <Button onClick={() => setDocuments(allDocuments.filter((doc) => doc.completed))}>Compléter</Button>
                    <Button onClick={() => setDocuments(allDocuments.filter((doc) => !doc.completed))}>Non Compléter</Button>
                    <Button onClick={() => setDocuments(allDocuments)}>Tout</Button>
                </ButtonGroup>
            </Box>

            {/* Tableau pour afficher la liste des items */}
            <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
                <Table variant="striped" colorScheme="blue">
                <Thead>
                    <Tr>
                        <Th>User</Th>
                        <Th>Item</Th>
                        <Th>Image</Th>
                        <Th>Date du drop</Th>
                        <Th>Completé ?</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {documents
                    .slice(indexOfFirstItem, indexOfLastItem)
                    .map((doc) => (
                        <Tr key={doc.id}>
                        <Td>{doc.user.name}</Td>
                        <Td>{doc.item.quantity}x {doc.item.name}</Td>
                        <Td><img style={{width: "100px"}} src={doc.item.image} /></Td>
                        <Td>{doc.date}</Td>
                        <Td>
                            <Checkbox
                                style={{ backgroundColor: "white", border: "1px solid black" }}
                                isChecked={doc.completed} 
                                onChange={(e) => {
                                    modifyDocument(doc.id, e.target.checked)
                                }}
                            />
                        </Td>
                        </Tr>
                    ))}
                </Tbody>
                </Table>
            </Box>

            {/* Pagination */}
            <Box mt={6}>
                <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </Box>

            {isLoading ? (
                <Box>Chargement des récompenses...</Box>
            ) : error ? (
                <Box color="red.500">{error}</Box>
            ) : documents.length === 0 ? (
                <Box>Aucune récompense à afficher.</Box>
            ) : (
                <>
                    {/* Tableau existant */}
                    <Box overflowX="auto" shadow="md" borderWidth="1px" borderRadius="lg">
                        {/* ... contenu du tableau ... */}
                    </Box>

                    {/* Pagination existante */}
                    <Box mt={6}>
                        {/* ... contenu de la pagination ... */}
                    </Box>
                </>
            )}
        </>
    )
}

