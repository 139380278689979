import React from 'react'
import { nFormatter } from 'components/utils/utils';
import ReactSelect, { components } from "react-select";
import { Text, Flex } from '@chakra-ui/react';

const CustomSelectChest = ({ chests, formData, setFormData }) => {
    const options = chests.map((chest) => ({
        id: chest.id,
        name: chest.name
    }));

    const { Option } = components;

    const ChestOption = (props) => (
        <Option {...props}>
            <div style={{display: "flex"}}>
                <img 
                    src="https://png.pngtree.com/png-clipart/20221211/ourmid/pngtree-old-rusty-closed-treasure-chest-side-view-transparent-png-image_6520038.png"
                    alt={props.data.name} style={{ width: 100, marginRight: 10 }}
                />
                <p>{props.data.name}</p>
            </div>
        </Option>
    );

    return (
        <>
            <ReactSelect
                name="chest"
                options={options}
                components={{ Option: ChestOption }}
                onChange={(e) => setFormData({ ...formData, rewardItem: e })}
            />

            {formData.rewardItem && (
                <>
                    <Text mt={2} mb={1}>Item sélectionnée :</Text>
                    <Flex alignItems="center" gap={5}>
                        <Text><strong>{formData.rewardItem.name}</strong></Text>
                        <img
                            src="https://png.pngtree.com/png-clipart/20221211/ourmid/pngtree-old-rusty-closed-treasure-chest-side-view-transparent-png-image_6520038.png"
                            alt="Selected Image" style={{ width: 100 }}
                        />
                    </Flex>
                </>
            )}

            {formData.item && (
                <>
                    <Text mt={2} mb={1}>Item sélectionnée :</Text>
                    <Flex alignItems="center" gap={5}>
                        <Text><strong>{formData.item.name}</strong></Text>
                        <img src="https://png.pngtree.com/png-clipart/20221211/ourmid/pngtree-old-rusty-closed-treasure-chest-side-view-transparent-png-image_6520038.png"
                            alt="Selected Image" style={{ width: 100 }}
                        />
                    </Flex>
                </>
            )}
        </>
    );
}

export default CustomSelectChest