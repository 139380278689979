import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Badge,
  HStack,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Switch,
  FormControl,
  FormLabel,
  Divider,
  Tooltip,
  Spinner,
  Input,
  InputGroup,
  InputLeftElement,
  Collapse,
  Card,
  CardBody,
  Stack,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { collection, query, where, getDocs, updateDoc, doc, writeBatch, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { FaSync, FaSearch, FaUserFriends, FaBuilding } from 'react-icons/fa';

const ManagersManagement = () => {
  const [managers, setManagers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [vendors, setVendors] = useState({});
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [editedRoles, setEditedRoles] = useState({
    manager: false,
    superManager: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [inspectedManager, setInspectedManager] = useState(null);
  const [isInspectModalOpen, setIsInspectModalOpen] = useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [selectedSyncAgencies, setSelectedSyncAgencies] = useState([]);
  const [syncManager, setSyncManager] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedManagerTeam, setSelectedManagerTeam] = useState([]);
  const [realTimeUpdates, setRealTimeUpdates] = useState(true);
  const bgCard = useColorModeValue('white', 'gray.700');

  const fetchData = async () => {
    try {
      // Fetch all users with manager or superManager role
      const usersRef = collection(db, 'users');
      const [managersSnapshot, superManagersSnapshot] = await Promise.all([
        getDocs(query(usersRef, where('manager', '==', true))),
        getDocs(query(usersRef, where('superManager', '==', true)))
      ]);

      const managersData = managersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const superManagersData = superManagersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Combine and sort by role then name
      const allManagers = [...superManagersData, ...managersData]
        .sort((a, b) => {
          if (a.superManager === b.superManager) {
            return (a.displayName || a.email).localeCompare(b.displayName || b.email);
          }
          return a.superManager ? -1 : 1;
        });

      setManagers(allManagers);

      // Fetch agencies
      const agenciesSnapshot = await getDocs(collection(db, 'agencies'));
      const agenciesData = agenciesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAgencies(agenciesData);

      // Fetch vendors data
      const vendorsRef = collection(db, 'vendors');
      const vendorsSnapshot = await getDocs(vendorsRef);
      const vendorsMap = {};
      vendorsSnapshot.docs.forEach(doc => {
        vendorsMap[doc.id] = doc.data().Name;
      });

      // Fetch all users data for salesCodes (using existing usersRef)
      const allUsersSnapshot = await getDocs(usersRef);
      allUsersSnapshot.docs.forEach(doc => {
        const userData = doc.data();
        if (userData.salesCode && userData.displayName) {
          vendorsMap[userData.salesCode] = userData.displayName;
        }
      });

      setVendors(vendorsMap);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger les données',
        status: 'error',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let unsubscribeManagers;
    let unsubscribeAgencies;

    const setupRealTimeUpdates = () => {
      if (realTimeUpdates) {
        // Real-time updates for managers
        const managersQuery = query(collection(db, 'users'), 
          where('manager', '==', true));
        unsubscribeManagers = onSnapshot(managersQuery, (snapshot) => {
          const managersData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setManagers(managersData);
        });

        // Real-time updates for agencies
        unsubscribeAgencies = onSnapshot(collection(db, 'agencies'), 
          (snapshot) => {
          const agenciesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAgencies(agenciesData);
        });
      }
    };

    setupRealTimeUpdates();

    return () => {
      if (unsubscribeManagers) unsubscribeManagers();
      if (unsubscribeAgencies) unsubscribeAgencies();
    };
  }, [realTimeUpdates]);

  const filteredManagers = managers.filter(manager => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      (manager.displayName?.toLowerCase().includes(searchTerm)) ||
      (manager.email?.toLowerCase().includes(searchTerm)) ||
      (manager.salesCode?.toLowerCase().includes(searchTerm))
    );
  });

  const handleEditManager = (manager) => {
    setSelectedManager(manager);
    setSelectedAgencies(manager.managedAgencies || []);
    setEditedRoles({
      manager: manager.manager || false,
      superManager: manager.superManager || false,
    });
    onOpen();
  };

  const handleUpdateManager = async () => {
    try {
      const batch = writeBatch(db);
      const managerRef = doc(db, 'users', selectedManager.id);
      const teamSet = new Set(selectedManager.teamSalescode || []);

      // Récupérer les agences sélectionnées
      for (const agencyId of selectedAgencies) {
        const agencyDoc = await getDoc(doc(db, 'agencies', agencyId));
        const agency = agencyDoc.data();

        // Ajouter les managers de l'agence
        if (agency.managers && Array.isArray(agency.managers)) {
          agency.managers.forEach(managerCode => teamSet.add(managerCode));
        }

        // Ajouter les vendeurs de l'agence
        if (agency.vendors && Array.isArray(agency.vendors)) {
          agency.vendors.forEach(vendorCode => teamSet.add(vendorCode));
        }

        // Récupérer et ajouter les équipes et leurs vendeurs
        const teamsQuery = query(
          collection(db, 'teams'),
          where('agencyId', '==', agencyId)
        );
        const teamsSnapshot = await getDocs(teamsQuery);

        for (const teamDoc of teamsSnapshot.docs) {
          const team = teamDoc.data();
          if (team.manager) teamSet.add(team.manager);
          if (team.vendors && Array.isArray(team.vendors)) {
            team.vendors.forEach(vendorCode => teamSet.add(vendorCode));
          }
        }
      }

      // Update roles and managed agencies
      const updates = {
        manager: editedRoles.manager,
        superManager: editedRoles.superManager,
        managedAgencies: selectedAgencies,
        teamSalescode: Array.from(teamSet)
      };

      // If removing all roles, clean up associated data
      if (!editedRoles.manager && !editedRoles.superManager) {
        updates.teamSalescode = [];
        updates.managedAgencies = [];
      }

      batch.update(managerRef, updates);

      // Update agencies
      for (const agency of agencies) {
        const agencyRef = doc(db, 'agencies', agency.id);
        const agencyData = agency.managers || [];

        if (selectedAgencies.includes(agency.id)) {
          // Add manager to agency if not present
          if (!agencyData.includes(selectedManager.salesCode)) {
            batch.update(agencyRef, {
              managers: [...agencyData, selectedManager.salesCode]
            });
          }
        } else {
          // Remove manager from agency if present
          if (agencyData.includes(selectedManager.salesCode)) {
            batch.update(agencyRef, {
              managers: agencyData.filter(m => m !== selectedManager.salesCode)
            });
          }
        }
      }

      await batch.commit();
      await fetchData();

      toast({
        title: 'Succès',
        description: 'Modifications enregistrées',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error('Error updating manager:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le manager',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleSyncManager = (manager) => {
    setSyncManager(manager);
    setSelectedSyncAgencies([]);
    setSyncModalOpen(true);
  };

  const handleConfirmSync = async () => {
    try {
      const batch = writeBatch(db);
      const teamSet = new Set(syncManager.teamSalescode || []);

      // Parcourir les agences sélectionnées
      for (const agencyId of selectedSyncAgencies) {
        const agencyDoc = await getDoc(doc(db, 'agencies', agencyId));
        const agency = agencyDoc.data();

        // Ajouter les managers
        if (agency.managers && Array.isArray(agency.managers)) {
          agency.managers.forEach(code => teamSet.add(code));
        }

        // Ajouter les vendeurs
        if (agency.vendors && Array.isArray(agency.vendors)) {
          agency.vendors.forEach(code => teamSet.add(code));
        }

        // Récupérer et ajouter les équipes
        const teamsSnapshot = await getDocs(
          query(collection(db, 'teams'), where('agencyId', '==', agencyId))
        );
        
        teamsSnapshot.docs.forEach(teamDoc => {
          const team = teamDoc.data();
          if (team.manager) teamSet.add(team.manager);
          if (team.vendors) team.vendors.forEach(code => teamSet.add(code));
        });
      }

      // Mettre à jour le manager
      batch.update(doc(db, 'users', syncManager.id), {
        teamSalescode: Array.from(teamSet)
      });

      await batch.commit();
      await fetchData();

      toast({
        title: "Synchronisation réussie",
        status: "success",
        duration: 3000,
      });
      setSyncModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      toast({
        title: "Erreur de synchronisation",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleInspectManager = (manager) => {
    setInspectedManager(manager);
    setIsInspectModalOpen(true);
  };

  const handleViewTeam = async (manager) => {
    setIsLoading(true);
    try {
      const teamMembers = [];
      for (const code of (manager.teamSalescode || [])) {
        const userQuery = query(collection(db, 'users'), 
          where('salesCode', '==', code));
        const userSnapshot = await getDocs(userQuery);
        if (!userSnapshot.empty) {
          teamMembers.push(userSnapshot.docs[0].data());
        }
      }
      setSelectedManagerTeam(teamMembers);
      onOpen();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de charger l\'équipe',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatJSON = (obj) => {
    return JSON.stringify(obj, null, 2);
  };

  return (
    <Box p={4}>
      <VStack spacing={4} align="stretch">
        <HStack justify="space-between">
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Rechercher un manager..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
          <FormControl display="flex" alignItems="center" maxW="200px">
            <FormLabel htmlFor="real-time" mb="0" fontSize="sm">
              Mises à jour en temps réel
            </FormLabel>
            <Switch
              id="real-time"
              isChecked={realTimeUpdates}
              onChange={(e) => setRealTimeUpdates(e.target.checked)}
            />
          </FormControl>
        </HStack>

        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Rôles</Th>
                <Th>Code vendeur</Th>
                <Th>Agences gérées</Th>
                <Th>Équipe</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredManagers.map((manager) => (
                <Tr key={manager.id}>
                  <Td>{manager.displayName || manager.email}</Td>
                  <Td>
                    <HStack spacing={2}>
                      {manager.superManager && (
                        <Badge colorScheme="purple">Super Manager</Badge>
                      )}
                      {manager.manager && (
                        <Badge colorScheme="blue">Manager</Badge>
                      )}
                    </HStack>
                  </Td>
                  <Td>{manager.salesCode}</Td>
                  <Td>
                    <HStack>
                      <FaBuilding />
                      <Text>{manager.managedAgencies?.length || 0}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack>
                      <FaUserFriends />
                      <Text>{manager.teamSalescode?.length || 0}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <Button
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditManager(manager)}
                      >
                        Gérer
                      </Button>
                      <Button
                        colorScheme="purple"
                        size="sm"
                        onClick={() => handleSyncManager(manager)}
                        leftIcon={<FaSync />}
                      >
                        Sync
                      </Button>
                      <Button
                        colorScheme="teal"
                        size="sm"
                        onClick={() => handleViewTeam(manager)}
                        isLoading={isLoading}
                      >
                        Équipe
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Vue d'équipe</ModalHeader>
            <ModalBody>
              <Stack spacing={4}>
                {selectedManagerTeam.map((member, index) => (
                  <Card key={index} bg={bgCard}>
                    <CardBody>
                      <HStack justify="space-between">
                        <VStack align="start" spacing={1}>
                          <Heading size="sm">{member.displayName}</Heading>
                          <Text fontSize="sm" color="gray.500">
                            {member.salesCode}
                          </Text>
                        </VStack>
                        <Badge
                          colorScheme={member.manager ? "blue" : "green"}
                        >
                          {member.manager ? "Manager" : "Vendeur"}
                        </Badge>
                      </HStack>
                    </CardBody>
                  </Card>
                ))}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Fermer</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isInspectModalOpen} onClose={() => setIsInspectModalOpen(false)} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Inspecter {inspectedManager?.displayName || inspectedManager?.email}
            </ModalHeader>
            <ModalBody>
              <pre>{formatJSON(inspectedManager)}</pre>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => setIsInspectModalOpen(false)}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={syncModalOpen} onClose={() => setSyncModalOpen(false)} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Synchroniser {syncManager?.displayName || syncManager?.email}
            </ModalHeader>
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Text fontWeight="bold">Sélectionnez les agences à synchroniser :</Text>
                {agencies.map((agency) => (
                  <FormControl key={agency.id} display="flex" alignItems="center">
                    <FormLabel mb="0">
                      {agency.name}
                    </FormLabel>
                    <Switch
                      isChecked={selectedSyncAgencies.includes(agency.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSyncAgencies([...selectedSyncAgencies, agency.id]);
                        } else {
                          setSelectedSyncAgencies(
                            selectedSyncAgencies.filter(id => id !== agency.id)
                          );
                        }
                      }}
                    />
                  </FormControl>
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button 
                colorScheme="blue" 
                mr={3} 
                onClick={handleConfirmSync}
                isDisabled={selectedSyncAgencies.length === 0}
              >
                Synchroniser
              </Button>
              <Button variant="ghost" onClick={() => setSyncModalOpen(false)}>
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
};

export default ManagersManagement; 