import React, { useContext } from 'react';
import { Flex, Button, Icon, useColorMode, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny, IoMdLogOut } from 'react-icons/io';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import routes from 'routes.js';
import { AuthContext } from 'contexts/AuthContext'; // Assurez-vous que le chemin d'importation est correct

export default function HeaderLinks(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  
  // Ajoutez cette ligne pour accéder à la fonction de déconnexion
  const { signOut } = useContext(AuthContext);

  return (
    <Flex
      w="auto"
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      p="8px"  // Augmenté de 5px à 10px
      borderRadius="60px"  // Doublé de 30px à 60px
      position="absolute"
      right="10px"  // Doublé de 20px à 40px
      boxShadow="0 8px 12px rgba(0, 0, 0, 0.1)"  // Augmenté légèrement
      transition="all 0.3s ease"
      _hover={{ boxShadow: "0 12px 16px rgba(0, 0, 0, 0.15)" }}
      zIndex={999}
    >
      <SidebarResponsive routes={routes} />

      <Tooltip label={colorMode === 'light' ? 'Mode sombre' : 'Mode clair'} placement="bottom">
        <Button
          variant="no-hover"
          bg="transparent"
          p="0px"
          minW="unset"
          minH="unset"
          h="36px"  // Doublé de 18px à 36px
          w="36px"  // Doublé de 18px à 36px
          onClick={toggleColorMode}
          _hover={{ transform: "scale(1.1)" }}
          transition="all 0.3s ease"
        >
          <Icon
            h="20px"  // Doublé de 18px à 36px
            w="20px"  // Doublé de 18px à 36px
            color={navbarIcon}
            as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
          />
        </Button>
      </Tooltip>

      {/* Ajoutez le bouton de déconnexion ici */}
      <Tooltip label="Déconnexion" placement="bottom">
        <Button
          variant="no-hover"
          bg="transparent"
          p="0px"
          minW="unset"
          minH="unset"
          h="20px"  // Doublé de 18px à 36px
          w="20px"  // Doublé de 18px à 36px
          ml="10px"  // Doublé de 10px à 20px
          onClick={signOut}
          _hover={{ transform: "scale(1.1)" }}
          transition="all 0.3s ease"
        >
          <Icon
            h="20px"  // Doublé de 18px à 36px
            w="20px"  // Doublé de 18px à 36px
            color={navbarIcon}
            as={IoMdLogOut}
          />
        </Button>
      </Tooltip>
    </Flex>
  );
}
