export const firebaseConfig = {
  apiKey: "AIzaSyAmLICaYQg3uIbE7TGz-6Y5fzNXIRKeCg4",
  authDomain: "crm-login-97e21.firebaseapp.com",
  databaseURL:
    "https://crm-login-97e21-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "crm-login-97e21",
  storageBucket: "crm-login-97e21.appspot.com",
  messagingSenderId: "954326107112",
  appId: "1:954326107112:web:267e75a8f2dc6858105a5d",
  measurementId: "G-LX159VQT44",
};
