import React, { useEffect } from "react";
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
// Firebase auth instance
import firebaseAuth, { db } from "lib/firebase";
import { createUserDocument } from "lib/firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";

// Google oauth provider
const provider = new GoogleAuthProvider();
// Contexts
export const AuthContext = React.createContext(null);

export const ContextProvider = (props) => {
  // States to check auth status
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);
  const [userAgencies, setUserAgencies] = React.useState([]);

  useEffect(() => {
    // Essayer de restaurer l'état depuis sessionStorage
    const storedUser = sessionStorage.getItem('user');
    const storedAgencies = sessionStorage.getItem('userAgencies');
    
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setIsSignedIn(true);
      if (storedAgencies) {
        setUserAgencies(JSON.parse(storedAgencies));
      }
    }

    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        await getUserData(user);
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
        setUser(null);
        setUserAgencies([]);
        // Nettoyer le sessionStorage
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userAgencies');
        sessionStorage.removeItem('isSignedIn');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getUserData = async (user) => {
    try {
      console.log("Début getUserData pour:", user.email);
      
      const q = query(collection(db, "users"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      let userData = null;
      
      querySnapshot.forEach((doc) => {
        userData = { id: doc.id, ...doc.data() };
      });

      console.log("userData récupéré:", userData);

      if (userData) {
        // Assurons-nous que les rôles sont bien des booléens
        const isAdmin = Boolean(userData.admin);
        const isSuperManager = Boolean(userData.superManager);
        const isManager = Boolean(userData.manager);
        const isLeader = Boolean(userData.leader);
        
        // Mettre à jour l'état de l'utilisateur avec les rôles
        const updatedUser = { 
          ...user, 
          profileData: { 
            ...userData,
            isAdmin,
            isSuperManager,
            isManager,
            isLeader
          } 
        };
        
        // Sauvegarder dans le sessionStorage
        sessionStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);

        // Récupération des agences si nécessaire
        if (isManager || isSuperManager) {
          const agenciesQuery = query(
            collection(db, "agencies"),
            where(isManager ? "managers" : "superManager", isManager ? "array-contains" : "==", userData.salesCode)
          );
          const agenciesSnapshot = await getDocs(agenciesQuery);
          const agenciesData = agenciesSnapshot.docs.map(doc => ({ 
            id: doc.id, 
            ...doc.data() 
          }));
          
          // Sauvegarder les agences dans le sessionStorage
          sessionStorage.setItem('userAgencies', JSON.stringify(agenciesData));
          setUserAgencies(agenciesData);
        }
        
        // Sauvegarder l'état de connexion
        sessionStorage.setItem('isSignedIn', 'true');
      }
    } catch (error) {
      console.error("Erreur dans getUserData:", error);
    }
  };
  
  // Nouvelle fonction pour synchroniser avec PostgreSQL
  const synchronizeUser = async (firebaseUser) => {
    try {
      // Envoie une requête à votre backend avec l'UID Firebase de l'utilisateur
      // Votre backend va vérifier si cet utilisateur existe dans PostgreSQL
      // et l'ajouter si nécessaire
      const response = await fetch('https://app.falconmarketing.fr/synchronize-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: firebaseUser.uid, email: firebaseUser.email }),
      });
      const data = await response.json();
      console.log('User synchronized with PostgreSQL:', data);
    } catch (error) {
      console.error('Error synchronizing user:', error);
    }
  };

  // Modification des fonctions signIn et signUp
  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signUp = async (email, password, salesCode) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      await createUserDocument(userCredential.user, { salesCode });
      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signOut = async () => {
    try {
      // Nettoyage explicite des données stockées localement
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('userAgencies');
      sessionStorage.removeItem('isSignedIn');
      
      // Réinitialisation des états React
      setUser(null);
      setUserAgencies([]);
      setIsSignedIn(false);
      
      // Déconnexion Firebase
      await firebaseSignOut(firebaseAuth);
    } catch (err) {
      console.error('Erreur lors de la déconnexion:', err.message);
    }
  };
  const googleSignIn = async () => {
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (err) {
      console.log(err.message);
    }
  };

  const createUserDocument = async (user, additionalData) => {
    const { email } = user;
    const { salesCode } = additionalData; // Ne prend pas en compte "isAdmin"

    try {
      await addDoc(collection(db, "users"), {
        money: 0,
        salesCode,
        email,
        createdAt: new Date(),
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      return { success: true };
    } catch (err) {
      console.error('Error sending password reset email:', err);
      return { 
        success: false, 
        error: err.message 
      };
    }
  };

  // Ajouter la fonction refreshUserAgencies
  const refreshUserAgencies = async () => {
    try {
      if (user?.profileData?.manager) {
        const agenciesQuery = query(
          collection(db, "agencies"),
          where("managers", "array-contains", user.profileData.salesCode)
        );
        const agenciesSnapshot = await getDocs(agenciesQuery);
        
        const agencies = agenciesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setUserAgencies(agencies);
        return agencies;
      }
    } catch (error) {
      console.error("Erreur lors du rafraîchissement des agences:", error);
      return [];
    }
  };

  // Context provider
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        userAgencies,
        refreshUserAgencies,
        signIn,
        signUp,
        signOut,
        googleSignIn,
        getUserData,
        isLoading,
        resetPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
