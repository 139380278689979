// AgenciesList.js
import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, writeBatch, orderBy, getDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import {
  SimpleGrid,
  Text,
  Box,
  Heading,
  Button,
  Flex,
  useDisclosure,
  HStack,
  useToast,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Grid,
  Progress,
  useColorModeValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Collapse,
  Alert,
  AlertIcon,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import {
  FaPlus,
  FaSync,
  FaSearch,
  FaFilter,
  FaSortAmountDown,
  FaSortAmountUp,
  FaChartLine,
  FaUsers,
  FaBuilding,
  FaEllipsisV,
  FaDownload,
  FaFileExcel,
} from 'react-icons/fa';
import AgencyCard from './agencyCard';
import { AuthContext } from 'contexts/AuthContext';
import * as XLSX from 'xlsx';

const filterAndSortData = (data, searchQuery, filterStatus, sortField, sortOrder) => {
  let filtered = [...data];

  // Appliquer la recherche
  if (searchQuery) {
    const query = searchQuery.toLowerCase();
    filtered = filtered.filter(item =>
      item.name?.toLowerCase().includes(query) ||
      item.id?.toLowerCase().includes(query)
    );
  }

  // Appliquer le filtre de statut
  if (filterStatus !== 'all') {
    filtered = filtered.filter(item => {
      const isActive = (item.vendors?.length || 0) > 0;
      return filterStatus === 'active' ? isActive : !isActive;
    });
  }

  // Appliquer le tri
  filtered.sort((a, b) => {
    let comparison = 0;
    switch (sortField) {
      case 'name':
        comparison = (a.name || '').localeCompare(b.name || '');
        break;
      case 'vendors':
        comparison = (a.vendors?.length || 0) - (b.vendors?.length || 0);
        break;
      case 'teams':
        comparison = (a.teams?.length || 0) - (b.teams?.length || 0);
        break;
      case 'date':
        comparison = new Date(b.createdAt || 0) - new Date(a.createdAt || 0);
        break;
      default:
        comparison = 0;
    }
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  return filtered;
};

const AgenciesList = () => {
  const [agencies, setAgencies] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterStatus, setFilterStatus] = useState('all');
  const [selectedTab, setSelectedTab] = useState(0);
  const [stats, setStats] = useState({
    totalAgencies: 0,
    activeAgencies: 0,
    totalVendors: 0,
    totalTeams: 0,
    performance: 0
  });
  const [isTeam, setIsTeam] = useState(false);
  
  const { user } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const bgCard = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  // Fonction de chargement des données
  const fetchData = async () => {
    setIsLoading(true);
    try {
      let agenciesData = [];
      let teamsData = [];

      if (user.profileData?.admin) {
        // Admin ne voit que les agences
        const agenciesSnapshot = await getDocs(collection(db, 'agencies'));
        agenciesData = agenciesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: 'agency'
        }));

      } else if (user.profileData?.superManager) {
        // Super manager voit ses agences et leurs équipes
        const agenciesSnapshot = await getDocs(
          query(collection(db, 'agencies'), 
          where('superManager', '==', user.profileData.salesCode))
        );
        agenciesData = agenciesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: 'agency'
        }));

        // Charger les équipes des agences gérées
        if (agenciesData.length > 0) {
          const agencyIds = agenciesData.map(agency => agency.id);
          const teamsSnapshot = await getDocs(
            query(collection(db, 'teams'), 
            where('agencyId', 'in', agencyIds))
          );
          teamsData = teamsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            type: 'team'
          }));
        }

      } else if (user.profileData?.manager) {
        // Manager ne voit que ses équipes
        const teamsSnapshot = await getDocs(
          query(collection(db, 'teams'), 
          where('manager', '==', user.profileData.salesCode))
        );
        teamsData = teamsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: 'team'
        }));
      }

      // Mettre à jour les états
      setAgencies(agenciesData);
      setTeams(teamsData);

      // Mettre à jour les données filtrées selon le rôle
      const dataToFilter = user.profileData?.admin ? agenciesData :
                          user.profileData?.manager ? teamsData :
                          [...agenciesData, ...teamsData];

      setFilteredData(dataToFilter);

      // Mettre à jour les statistiques
      const newStats = {
        totalAgencies: agenciesData.length,
        activeAgencies: agenciesData.filter(a => (a.vendors?.length || 0) > 0).length,
        totalTeams: teamsData.length,
        totalVendors: dataToFilter.reduce((sum, item) => sum + (item.vendors?.length || 0), 0),
        performance: 0
      };

      if (newStats.totalAgencies > 0) {
        newStats.performance = Math.round((newStats.activeAgencies / newStats.totalAgencies) * 100);
      }

      setStats(newStats);

    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      toast({
        title: "Erreur de chargement",
        description: "Impossible de charger les données",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Effet pour charger les données initiales
  useEffect(() => {
    if (user?.profileData) {
      console.log("Initial data load for user:", user.profileData);
      fetchData();
    }
  }, [user?.profileData]);

  // Effet pour mettre à jour isTeam
  useEffect(() => {
    if (user?.profileData) {
      setIsTeam(!!user.profileData?.manager);
    }
  }, [user?.profileData]);

  // Effet pour filtrer les données
  useEffect(() => {
    if (agencies.length > 0 || teams.length > 0) {
      // Sélectionner les données selon le rôle
      const baseData = user.profileData?.admin ? agencies :
                      user.profileData?.manager ? teams :
                      [...agencies, ...teams];

      let filtered = [...baseData];

      // Appliquer la recherche
      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        filtered = filtered.filter(item =>
          item.name?.toLowerCase().includes(query) ||
          item.id?.toLowerCase().includes(query)
        );
      }

      // Appliquer le filtre de statut
      if (filterStatus !== 'all') {
        filtered = filtered.filter(item => {
          const isActive = (item.vendors?.length || 0) > 0;
          return filterStatus === 'active' ? isActive : !isActive;
        });
      }

      // Appliquer le tri
      filtered.sort((a, b) => {
        let comparison = 0;
        switch (sortField) {
          case 'name':
            comparison = (a.name || '').localeCompare(b.name || '');
            break;
          case 'vendors':
            comparison = (a.vendors?.length || 0) - (b.vendors?.length || 0);
            break;
          case 'teams':
            comparison = (a.teams?.length || 0) - (b.teams?.length || 0);
            break;
          case 'date':
            comparison = new Date(b.createdAt || 0) - new Date(a.createdAt || 0);
            break;
          default:
            comparison = 0;
        }
        return sortOrder === 'asc' ? comparison : -comparison;
      });

      setFilteredData(filtered);
    }
  }, [agencies, teams, searchQuery, filterStatus, sortField, sortOrder, user.profileData]);

  const handleExportToExcel = () => {
    const dataToExport = filteredData.map(item => ({
      Nom: item.name,
      'Nombre de vendeurs': item.vendors?.length || 0,
      'Nombre d\'équipes': item.teams?.length || 0,
      Statut: (item.vendors?.length || 0) > 0 ? 'Active' : 'En attente',
      'Date de création': new Date(item.createdAt).toLocaleDateString(),
      'Super Manager': item.superManager || 'N/A'
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Agences");
    XLSX.writeFile(wb, "agences_export.xlsx");
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSyncTeamSalescode = async () => {
    try {
      setIsLoading(true);
      const batch = writeBatch(db);
      
      // Récupérer toutes les agences
      const agenciesSnapshot = await getDocs(collection(db, 'agencies'));
      
      // Map pour stocker les teamSalescode par super manager
      const superManagerTeams = new Map();
      
      // Première passe : collecter toutes les agences par super manager
      const agenciesBySuperManager = new Map();
      agenciesSnapshot.docs.forEach(agencyDoc => {
        const agency = agencyDoc.data();
        const superManagerSalesCode = agency.superManager;
        if (superManagerSalesCode) {
          if (!agenciesBySuperManager.has(superManagerSalesCode)) {
            agenciesBySuperManager.set(superManagerSalesCode, []);
          }
          agenciesBySuperManager.get(superManagerSalesCode).push({
            id: agencyDoc.id,
            ...agency
          });
        }
      });

      // Pour chaque super manager, traiter toutes ses agences
      for (const [superManagerCode, agencies] of agenciesBySuperManager) {
        const teamSet = new Set();
        
        for (const agency of agencies) {
          // Ajouter les managers de l'agence
          if (agency.managers && Array.isArray(agency.managers)) {
            agency.managers.forEach(managerCode => teamSet.add(managerCode));
          }
          
          // Ajouter les vendeurs de l'agence
          if (agency.vendors && Array.isArray(agency.vendors)) {
            agency.vendors.forEach(vendorCode => teamSet.add(vendorCode));
          }
          
          // Récupérer et ajouter les équipes et leurs vendeurs
          const teamsQuery = query(
            collection(db, 'teams'),
            where('agencyId', '==', agency.id)
          );
          const teamsSnapshot = await getDocs(teamsQuery);
          
          for (const teamDoc of teamsSnapshot.docs) {
            const team = teamDoc.data();
            if (team.manager) {
              teamSet.add(team.manager);
            }
            if (team.vendors && Array.isArray(team.vendors)) {
              team.vendors.forEach(vendorCode => teamSet.add(vendorCode));
            }
          }
        }
        
        // Mettre à jour le super manager
        const superManagerQuery = query(
          collection(db, 'users'),
          where('salesCode', '==', superManagerCode)
        );
        const superManagerSnapshot = await getDocs(superManagerQuery);
        
        if (!superManagerSnapshot.empty) {
          const superManagerDoc = superManagerSnapshot.docs[0];
          batch.update(doc(db, 'users', superManagerDoc.id), {
            teamSalescode: Array.from(teamSet),
            superManager: true,
            managedAgencies: agencies.map(agency => agency.id)
          });
        }
      }
      
      await batch.commit();
      await fetchData(); // Rafraîchir les données après la synchronisation
      
      toast({
        title: "Synchronisation réussie",
        description: "Les teamSalescode ont été mis à jour pour tous les super managers",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      toast({
        title: "Erreur de synchronisation",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAgency = async (agencyId) => {
    try {
      setIsLoading(true);
      const isTeam = user.profileData?.manager;
      const batch = writeBatch(db);

      if (isTeam) {
        // Supprimer l'équipe
        const teamRef = doc(db, 'teams', agencyId);
        batch.delete(teamRef);

        // Mettre à jour l'agence parente
        const teamDoc = await getDoc(teamRef);
        if (teamDoc.exists()) {
          const agencyId = teamDoc.data().agencyId;
          const agencyRef = doc(db, 'agencies', agencyId);
          const agencyDoc = await getDoc(agencyRef);
          if (agencyDoc.exists()) {
            const teams = agencyDoc.data().teams || [];
            batch.update(agencyRef, {
              teams: teams.filter(id => id !== agencyId)
            });
          }
        }
      } else {
        // Supprimer l'agence
        const agencyRef = doc(db, 'agencies', agencyId);
        batch.delete(agencyRef);

        // Supprimer les équipes associées
        const teamsQuery = query(collection(db, 'teams'), where('agencyId', '==', agencyId));
        const teamsSnapshot = await getDocs(teamsQuery);
        teamsSnapshot.forEach(teamDoc => {
          batch.delete(doc(db, 'teams', teamDoc.id));
        });

        // Mettre à jour les vendeurs
        const vendorsQuery = query(collection(db, 'vendors'), where('agencyId', '==', agencyId));
        const vendorsSnapshot = await getDocs(vendorsQuery);
        vendorsSnapshot.forEach(vendorDoc => {
          batch.update(doc(db, 'vendors', vendorDoc.id), { agencyId: null });
        });
      }

      await batch.commit();

      // Mettre à jour l'état local
      if (isTeam) {
        setTeams(prev => prev.filter(team => team.id !== agencyId));
      } else {
        setAgencies(prev => prev.filter(agency => agency.id !== agencyId));
      }
      
      // Rafraîchir les données filtrées
      const data = isTeam ? teams : agencies;
      const filtered = filterAndSortData(
        data.filter(item => item.id !== agencyId),
        searchQuery,
        filterStatus,
        sortField,
        sortOrder
      );
      setFilteredData(filtered);

      toast({
        title: "Suppression réussie",
        description: `${isTeam ? "L'équipe" : "L'agence"} a été supprimée avec succès`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      toast({
        title: "Erreur",
        description: `Impossible de supprimer ${isTeam ? "l'équipe" : "l'agence"}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAgency = async (agencyId, updatedData) => {
    try {
      setIsLoading(true);
      const isTeam = user.profileData?.manager;
      const agencyRef = doc(db, isTeam ? 'teams' : 'agencies', agencyId);
      
      await updateDoc(agencyRef, updatedData);

      // Mettre à jour l'état local
      if (isTeam) {
        setTeams(prev =>
          prev.map(team =>
            team.id === agencyId ? { ...team, ...updatedData } : team
          )
        );
      } else {
        setAgencies(prev =>
          prev.map(agency =>
            agency.id === agencyId ? { ...agency, ...updatedData } : agency
          )
        );
      }

      // Rafraîchir les données filtrées
      const data = isTeam ? teams : agencies;
      const filtered = filterAndSortData(
        data.map(item =>
          item.id === agencyId ? { ...item, ...updatedData } : item
        ),
        searchQuery,
        filterStatus,
        sortField,
        sortOrder
      );
      setFilteredData(filtered);

      toast({
        title: "Mise à jour réussie",
        description: `${isTeam ? "L'équipe" : "L'agence"} a été mise à jour avec succès`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour:", error);
      toast({
        title: "Erreur",
        description: `Impossible de mettre à jour ${isTeam ? "l'équipe" : "l'agence"}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!user.profileData?.admin && !user.profileData?.manager) {
    return (
      <Alert status="warning">
        <AlertIcon />
        Vous n'avez pas les autorisations nécessaires pour voir cette page.
      </Alert>
    );
  }

  return (
    <Box>
      <VStack spacing={6} align="stretch">
        {/* En-tête avec titre et actions */}
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="lg">
            {user.profileData?.admin ? 'Liste des agences' : 
             user.profileData?.manager ? 'Mes équipes' : 
             'Agences et équipes'}
          </Heading>
          <HStack spacing={4}>
            {user.profileData?.admin && (
              <Button
                colorScheme="purple"
                onClick={handleSyncTeamSalescode}
                isLoading={isLoading}
                leftIcon={<FaSync />}
              >
                Synchroniser
              </Button>
            )}
            <IconButton
              icon={<FaFileExcel />}
              colorScheme="green"
              onClick={handleExportToExcel}
              aria-label="Exporter en Excel"
            />
          </HStack>
        </Flex>

        {/* Statistiques */}
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={4}>
          <Card bg={bgCard} borderColor={borderColor} borderWidth="1px">
            <CardBody>
              <Stat>
                <StatLabel>Total {isTeam ? 'Équipes' : 'Agences'}</StatLabel>
                <StatNumber>{isTeam ? stats.totalTeams : stats.totalAgencies}</StatNumber>
                <Progress value={100} size="xs" colorScheme="blue" mt={2} />
              </Stat>
            </CardBody>
          </Card>
          
          <Card bg={bgCard} borderColor={borderColor} borderWidth="1px">
            <CardBody>
              <Stat>
                <StatLabel>{isTeam ? 'Équipes Actives' : 'Agences Actives'}</StatLabel>
                <StatNumber>
                  {isTeam ? 
                    stats.totalTeams : 
                    stats.activeAgencies}
                </StatNumber>
                <Progress 
                  value={isTeam ? 
                    (stats.totalTeams > 0 ? 100 : 0) : 
                    ((stats.activeAgencies / stats.totalAgencies) * 100)} 
                  size="xs" 
                  colorScheme="green" 
                  mt={2} 
                />
              </Stat>
            </CardBody>
          </Card>
          
          <Card bg={bgCard} borderColor={borderColor} borderWidth="1px">
            <CardBody>
              <Stat>
                <StatLabel>Total Vendeurs</StatLabel>
                <StatNumber>{stats.totalVendors}</StatNumber>
                <Progress value={75} size="xs" colorScheme="orange" mt={2} />
              </Stat>
            </CardBody>
          </Card>
          
          <Card bg={bgCard} borderColor={borderColor} borderWidth="1px">
            <CardBody>
              <Stat>
                <StatLabel>Performance</StatLabel>
                <StatNumber>{stats.performance}%</StatNumber>
                <StatHelpText>
                  <StatArrow type={stats.performance >= 75 ? 'increase' : 'decrease'} />
                  Taux d'activation
                </StatHelpText>
              </Stat>
            </CardBody>
          </Card>
        </Grid>

        {/* Filtres et recherche */}
        <Card bg={bgCard} borderColor={borderColor} borderWidth="1px">
          <CardBody>
            <Grid templateColumns={{ base: "1fr", md: "2fr 1fr 1fr" }} gap={4}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaSearch color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Rechercher une agence..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>

              <Select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                leftIcon={<FaFilter />}
              >
                <option value="all">Tous les statuts</option>
                <option value="active">Actives</option>
                <option value="pending">En attente</option>
              </Select>

              <Select
                value={sortField}
                onChange={(e) => toggleSort(e.target.value)}
                leftIcon={<FaSortAmountDown />}
              >
                <option value="name">Nom</option>
                <option value="vendors">Nombre de vendeurs</option>
                <option value="teams">Nombre d'équipes</option>
                <option value="date">Date de création</option>
              </Select>
            </Grid>
          </CardBody>
        </Card>

        {/* Liste des agences/équipes */}
        {isLoading ? (
          <Flex justify="center" align="center" h="200px">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : filteredData.length > 0 ? (
          <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing={6}>
            {filteredData.map((item) => (
              <AgencyCard
                key={item.id}
                agency={item}
                onDelete={handleDeleteAgency}
                onUpdate={handleUpdateAgency}
                isTeam={item.type === 'team'}
              />
            ))}
          </SimpleGrid>
        ) : (
          <Alert status="info">
            <AlertIcon />
            {user.profileData?.admin ? 
              "Aucune agence disponible" :
              user.profileData?.manager ?
              "Aucune équipe disponible" :
              "Aucune agence ou équipe disponible"}
          </Alert>
        )}
      </VStack>
    </Box>
  );
};

export default AgenciesList;
