import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ContextProvider } from 'contexts/AuthContext';
import 'firebase/functions';
import { NotificationProvider } from 'contexts/NotificationContext';
import NotificationActivation from './contexts/NotificationActivation';
import GpsCollector from 'views/admin/default/components/gpscollector';
import { DataProvider } from 'contexts/DataProvider';



// Déplacer l'enregistrement du service worker dans une fonction séparée
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('./firebase-messaging-sw.js');
      console.log('Service Worker Registered', registration);
    } catch (err) {
      console.warn('Service Worker registration failed', err);
      // On continue l'exécution même en cas d'échec
    }
  }
};

const handleLocation = (location) => {
  console.log('Position GPS actuelle:', location);
};




ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <NotificationProvider>
        <NotificationActivation />
      <ContextProvider>
      <GpsCollector onLocation={handleLocation}/>
        <HashRouter>
        <DataProvider>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Redirect from="/" to="/admin" />
          </Switch>
          </DataProvider>
        </HashRouter>
      </ContextProvider>
      </NotificationProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root'),
  // Callback après le rendu
  () => {
    registerServiceWorker();
  }
);