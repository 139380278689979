import React, { useEffect, useState, useContext } from "react";
import moment from 'moment';
import { useData } from "contexts/DataProvider";
import { AuthContext } from "contexts/AuthContext";

import {
  Box,
  Text,
  Stack,
  Heading,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";

const Moyenne = () => {
  const { user } = useContext(AuthContext); // S'assurer que l'utilisation de useContext est correcte
  const jsonData = useData(); // Utilisation des données fournies par DataProvider
  const [averageDailySales, setAverageDailySales] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(30);

  const filterOptions = [
    { label: '1 An', days: 365 },
    { label: '6 Mois', days: 180 },
    { label: '3 Mois', days: 90 },
    { label: '30 Jours', days: 30 },
    { label: '15 Jours', days: 15 },
    { label: '1 Semaine', days: 7 },
  ];

  const calculateAverageDailySales = (salesData) => {
    const currentDate = moment();
    const startPeriod = moment().subtract(selectedFilter, 'days');
    let workingDaysInPeriod = 0;

    for (let day = moment(startPeriod); day.isBefore(currentDate); day.add(1, 'days')) {
      if (day.isoWeekday() < 6) {
        workingDaysInPeriod += 1;
      }
    }

    const salesInPeriod = salesData.filter(sale => {
      const saleDate = moment(sale.CreatedDate);
      return saleDate.isSameOrAfter(startPeriod) && saleDate.isSameOrBefore(currentDate) && (sale.Status__c === 'Validated' || sale.Status__c === 'Progress');
    });

    return salesInPeriod.length / workingDaysInPeriod;
  };

  useEffect(() => {
    if (jsonData) {
      const avgSales = calculateAverageDailySales(jsonData);
      setAverageDailySales(avgSales);
    }
  }, [jsonData, selectedFilter]);

  const getEmoji = () => {
    if (averageDailySales <= 1) return '☠️';
    if (averageDailySales <= 2) return '😐';
    if (averageDailySales > 2 && averageDailySales < 3) return '😃';
    if (averageDailySales >= 3) return '🤯';
    return '';
  };

  return (
    <Box bg="white" borderRadius="lg" boxShadow="md" p={6}>
      <Heading as="h4" size="md" mb={4}>
        Moyenne de ventes quotidiennes
      </Heading>
      <ButtonGroup spacing={2} mb={4}>
        {filterOptions.map((option) => (
          <Button 
            key={option.label} 
            variant={selectedFilter === option.days ? 'solid' : 'outline'}
            onClick={() => setSelectedFilter(option.days)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
      <Stack direction="row" spacing={4} align="center">
        <Text fontSize="2xl">{getEmoji()}</Text>
        <Text fontSize="xl">{averageDailySales.toFixed(2)}</Text>
      </Stack>
    </Box>
  );
};

export default Moyenne;
