import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  useColorMode,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
  VStack,
  Text,
  Flex,
  Select,
  Grid,
  useMediaQuery,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  GridItem,
  Divider,
  Tag,
  Link as ChakraLink,
  Button,
} from "@chakra-ui/react";
import axios from "axios";
import { AuthContext } from "contexts/AuthContext";
import "./pagination.css";
import { FaAngleDown } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useData } from "contexts/DataProvider";

const PER_PAGE = 100;


const Raccordements = () => {
  
  const { colorMode } = useColorMode();
  const { user } = React.useContext(AuthContext);
  const periods = ["Semaine", "Mois", "Année"];
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCardId, setSelectedCardId] = useState(null);
const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [sortConfig, setSortConfig] = useState({
    
    key: "CreatedDate",
    ascending: false,
  });
  const [collapsedRowId, setCollapsedRowId] = useState(null);
  const [filter, setFilter] = useState({ period: "Tous", status: "Tous" });
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendorNames, setVendorNames] = useState({});
  const { data } = useData();

  const getRowColor = (status) => {
    const colors = getRowColors(status);
    return colorMode === "light" ? colors.light : colors.dark;
  };

  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

    // Mettre à jour les records et filteredRecords quand data change
    useEffect(() => {
      console.log("Données chargées du DataProvider:", data);
      if (data && Array.isArray(data)) {
        setRecords(data);
        setFilteredRecords(data);
    
        // Extraire les noms de vendeurs uniques
        const uniqueVendorNames = {};
        data.forEach((record) => {
          if (record.VendorName) {
            uniqueVendorNames[record.VendorName] = true;
          }
        });
        setVendorNames(uniqueVendorNames);
      } else {
        console.error("Les données reçues ne sont pas un tableau:", data);
        setRecords([]);
        setFilteredRecords([]);
        setVendorNames({});
      }
    }, [data]);
  
    useEffect(() => {
      console.log(data); // Affiche les données récupérées
    }, [data]); // Se met à jour lorsque data change

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    const d = new Date(date);
    return isNaN(d.getTime())
      ? ""
      : `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${d.getFullYear()}`;
  };

  const sortRecords = (records) => {
    return records.sort((a, b) => {
      const dateA = new Date(a[sortConfig.key]);
      const dateB = new Date(b[sortConfig.key]);
      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
        return 0;
      }
      if (isNaN(dateA.getTime())) {
        return sortConfig.ascending ? -1 : 1;
      }
      if (isNaN(dateB.getTime())) {
        return sortConfig.ascending ? 1 : -1;
      }
      if (sortConfig.key === 'ConnectingDatePlanned__c') {
        // Inverse the direction for 'ConnectingDatePlanned__c'
        return sortConfig.ascending ? dateB - dateA : dateA - dateB;
      } else {
        return sortConfig.ascending ? dateA - dateB : dateB - dateA;
      }
    });
  };

  const toggleSortDirection = (key) => {
    setSortConfig((prevConfig) => {
      return {
        key: key,
        ascending: prevConfig.key === key ? !prevConfig.ascending : true,
      };
    });
  };

  const handleCollapseToggle = (rowId) => {
    if (collapsedRowId === rowId) {
      setCollapsedRowId(null);
    } else {
      setCollapsedRowId(rowId);
    }
  };

  const statuses = ["EnCoursDeRattrapage", "Error", "Validated", "Payed"];

  const filterRecords = (period, status, hasConnectingDate) => {
    let filteredByPeriod = records.filter((record) => {
      const recordDate = new Date(record.CreatedDate);
      const diffDays = Math.round(Math.abs((now - recordDate) / oneDay));

      switch (period) {
        case "Semaine":
          return diffDays <= 7;
        case "Mois":
          return diffDays <= 30;
        case "Année":
          return diffDays <= 365;
        default:
          return true;
      }
    });

    let filteredByStatus =
      status === "Tous"
        ? filteredByPeriod
        : filteredByPeriod.filter(
            (record) =>
              record.Status__c === status || record.ConnectionStatus__c === status
          );

    if (hasConnectingDate) {
      filteredByStatus = filteredByStatus.filter((record) => {
        if (
          record.ConnectingDatePlanned__c &&
          record.ConnectingDatePlanned__c.length > 0
        ) {
          const connectingDate = new Date(record.ConnectingDatePlanned__c);
          const diffDaysConnecting = Math.round(
            Math.abs((now - connectingDate) / oneDay)
          );
          return (
            diffDaysConnecting >= -1 && diffDaysConnecting <= 1
          );
        }
        return false;
      });
      
    }

      // Appliquer le filtrage par vendeur
  if (selectedVendor) {
    filteredByStatus = filteredByStatus.filter((record) => record.VendorName === selectedVendor);
  }

  return filteredByStatus;
};

useEffect(() => {
  let filtered = filterRecords(filter.period, filter.status, filter.hasConnectingDate);
  setFilteredRecords(filtered);
}, [records, filter, selectedVendor]);


  const handleFilter = (period, status, hasConnectingDate) => {
    const filtered = filterRecords(period, status, hasConnectingDate);
    setFilteredRecords(filtered);
    setFilter({ period, status });
  };

 
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(filteredRecords.length / PER_PAGE);

  const sortedRecords = sortRecords(filteredRecords)
    .filter((record) => {
      const connectingDate = new Date(record.ConnectingDatePlanned__c);
      const diffDaysConnecting = Math.round(
        Math.abs((now - connectingDate) / oneDay)
      );
      return (
        diffDaysConnecting >= -1 && diffDaysConnecting <= 1
      );
    })
    .map((record) => ({
      ...record,
      CreatedDate: formatDate(record.CreatedDate),
      ConnectingDatePlanned__c: formatDate(record.ConnectingDatePlanned__c),
    }));

  const bgColor = useColorModeValue("white", "gray.700");
  const cardBg = useColorModeValue("white", "gray.700");
  const expandedRowBg = useColorModeValue("gray.50", "gray.700");

  const getRowColors = (status) => {
    const lightColors = {
      ToConfirm: "rgba(0, 108, 254, 0.03)",
      Validated: "rgba(3, 255, 0, 0.3)",
      Progress: "rgba(3, 255, 0, 0.1)",
      Error: "rgba(255, 0, 0, 0.3)",
      Payed: "rgba(8, 254, 0, 0.91)",
    };

    const darkColors = {
      ToConfirm: "rgba(0, 108, 254, 0.1)",
      Validated: "rgba(3, 255, 0, 0.4)",
      Progress: "rgba(3, 255, 0, 0.2)",
      Error: "rgba(255, 0, 0, 0.4)",
      Payed: "rgba(8, 254, 0, 0.91)",
    };

    return {
      light: lightColors[status] || "",
      dark: darkColors[status] || "",
    };
  };

  const [isOpen, setIsOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const handleOpenModal = (record) => {
    setCurrentRecord(record);
    setIsOpen(true);
  };

  const getStatusColorScheme = (status) => {
    switch (status) {
      case "EnCours":
        return "blue";
      case "EnCoursDeRattrapage":
        return "orange";
      case "Error":
        return "red";
      case "Validated":
        return "green";
      case "Payed":
        return "teal";
      case "RaccordOK":
        return "cyan";
      case "RaccordKO":
        return "pink";
      case "ToConfirm":
        return "purple";
      case "Progress":
        return "yellow";
      default:
        return "gray";
    }
  };

  function translateStatus(status) {
    switch (status) {
      case "EnCours":
        return "En cours";
      case "RaccordKO":
        return "Raccordement KO";
      case "RaccordOK":
        return "Raccordé";
      case "Inconnu":
        return "Inconnu";
      case "EnCoursDeRattrapage":
        return "En cours de rattrapage";
      default:
        return status;
    }
  }

  return (
    <Box
      bg={bgColor}
      borderRadius="lg"
      boxShadow="md"
      p={6}
      overflow="auto"
      maxH="80vh"
      maxW="100%"
      minH="400px"
    >
      <Heading as="h2" size="lg" mb={6}>Vos raccordements à venir</Heading>
      
      <Flex direction={{ base: "column", md: "row" }} mb={6} gap={4}>
        <Select 
          value={selectedVendor} 
          onChange={(e) => setSelectedVendor(e.target.value)} 
          placeholder="Filtrer par vendeur"
          maxW={{ base: "100%", md: "300px" }}
        >
          <option value="">Tous</option>
          {Object.keys(vendorNames).sort().map((vendorName) => (
            <option key={vendorName} value={vendorName}>
              {vendorName}
            </option>
          ))}
        </Select>

        <Select 
          value={filter.period} 
          onChange={(e) => handleFilter(e.target.value, filter.status, filter.hasConnectingDate)}
          maxW={{ base: "100%", md: "200px" }}
        >
          <option value="Tous">Toutes les périodes</option>
          {periods.map((period) => (
            <option key={period} value={period}>
              {period}
            </option>
          ))}
        </Select>

        <Select 
          value={filter.status} 
          onChange={(e) => handleFilter(filter.period, e.target.value, filter.hasConnectingDate)}
          maxW={{ base: "100%", md: "200px" }}
        >
          <option value="Tous">Tous les statuts</option>
          {statuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      </Flex>
  
      {isMobile ? (
        <Grid templateColumns="repeat(1, 1fr)" gap={6}>
          {sortedRecords.map((record) => (
            <Box
              key={record.Id}
              bg={cardBg}
              boxShadow="md"
              borderRadius="md"
              p={6}
              maxW="100%"
              onClick={() => setSelectedCardId(record.Id)}
              cursor="pointer"
              borderLeft="4px solid"
              borderLeftColor={getRowColor(record.Status__c)}
            >
              <Heading as="h3" size="md" mb={2}>
                {record.TchProspectName__c}
              </Heading>
              <Text mb={2}>
                <strong>Numéro de téléphone :</strong>{" "}
                <a href={`tel:${record.ProspectMobilePhone__c}`} style={{ color: "blue" }}>
                  {record.ProspectMobilePhone__c}
                </a>
              </Text>
              <Text mb={2}>
                <strong>Offre :</strong> {record.OfferName__c}
              </Text>
              <Text mb={2}>
                <strong>Date de raccordement :</strong> {record.ConnectingDatePlanned__c}
              </Text>
              <Text>
                <strong>Statut :</strong> {record.Status__c}
              </Text>
            </Box>
          ))}
        </Grid>
      ) : (
        <Table variant="simple" overflow={{ base: "auto", md: "auto" }}>
          <Thead>
            <Tr>
              <Th>Détails</Th>
              <Th
                onClick={() => toggleSortDirection("CreatedDate")}
                cursor="pointer"
              >
                Date de la vente {sortConfig.key === "CreatedDate" && (sortConfig.ascending ? "↑" : "↓")}
              </Th>
              <Th>Nom du vendeur</Th>
              <Th>Nom</Th>
              <Th>Numéro de téléphone</Th>
              <Th
                onClick={() => toggleSortDirection("ConnectingDatePlanned__c")}
                cursor="pointer"
              >
                Date de raccordement {sortConfig.key === "ConnectingDatePlanned__c" && (sortConfig.ascending ? "↑" : "↓")}
              </Th>
              <Th>Statut</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedRecords
              .slice(offset, offset + PER_PAGE)
              .map((record) => (
                <React.Fragment key={record.Id}>
                  <Tr bg={getRowColor(record.Status__c)} onClick={() => handleOpenModal(record)} cursor="pointer">
                    <Td
                      onClick={() => handleCollapseToggle(record.Id)}
                      cursor="pointer"
                    >
                      <Flex alignItems="center">
                        <Text mr={2}>{record.TchPhone__c}</Text>
                        <ChevronDownIcon 
                          transform={collapsedRowId === record.Id ? "rotate(180deg)" : "rotate(0deg)"}
                          transition="transform 0.2s"
                        />
                      </Flex>
                    </Td>
                    <Td>{record.CreatedDate}</Td>
                    <Td>{record.VendorName}</Td>
                    <Td>{record.TchProspectName__c}</Td>
                    <Td>
                      <a
                        href={`tel:${record.ProspectMobilePhone__c}`}
                        style={{ color: "blue" }}
                      >
                        {record.ProspectMobilePhone__c}
                      </a>
                    </Td>
                    <Td>{record.ConnectingDatePlanned__c}</Td>
                    <Td>{record.Status__c}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={7} p={0}>
                      <Collapse in={collapsedRowId === record.Id}>
                        <Box p={4} bg={expandedRowBg}>
                          {/* ... existing detailed view ... */}
                        </Box>
                      </Collapse>
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
          </Tbody>
        </Table>
      )}
      
      {/* Add pagination component here */}

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent bg={bgColor} borderRadius="md">
          <ModalHeader bg="blue.600" color="white" borderTopRadius="md">
            Détails du raccordement
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody py={6}>
            {currentRecord && (
              <VStack align="stretch" spacing={6} width="100%">
                <Box>
                  <Heading size="md" color="blue.600" mb={3}>
                    Informations client
                  </Heading>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold">Nom</Text>
                      <Text>{currentRecord.TchProspectName__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Téléphone</Text>
                      <ChakraLink href={`tel:${currentRecord.ProspectMobilePhone__c}`} color="blue.500">
                        {currentRecord.ProspectMobilePhone__c}
                      </ChakraLink>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Email</Text>
                      <Text>{currentRecord.ProspectEmail__c}</Text>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Text fontWeight="bold">Adresse</Text>
                      <Text>{currentRecord.TchAddress__c}</Text>
                    </GridItem>
                  </Grid>
                </Box>

                <Divider />

                <Box>
                  <Heading size="md" color="blue.600" mb={3}>
                    Détails de la commande
                  </Heading>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold">Offre</Text>
                      <Text>{currentRecord.OfferName__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Date de création</Text>
                      <Text>{currentRecord.CreatedDate}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Date de raccordement prévue</Text>
                      <Text>{currentRecord.ConnectingDatePlanned__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Statut</Text>
                      <Tag colorScheme={getStatusColorScheme(currentRecord.Status__c)}>
                        {translateStatus(currentRecord.Status__c)}
                      </Tag>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Statut de raccordement</Text>
                      <Tag colorScheme={getStatusColorScheme(currentRecord.ConnectionStatus__c)}>
                        {translateStatus(currentRecord.ConnectionStatus__c)}
                      </Tag>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Numéro de commande</Text>
                      <Text>{currentRecord.OrderNumber__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Numéro de panier</Text>
                      <Text>{currentRecord.BasketNumber__c}</Text>
                    </GridItem>
                  </Grid>
                </Box>

                <Divider />

                <Box>
                  <Heading size="md" color="blue.600" mb={3}>
                    Informations vendeur
                  </Heading>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold">Nom du vendeur</Text>
                      <Text>{currentRecord.VendorName}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">ID du vendeur</Text>
                      <Text>{currentRecord.VendorId__c}</Text>
                    </GridItem>
                  </Grid>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter bg="gray.100" borderBottomRadius="md">
            <Button colorScheme="blue" onClick={() => setIsOpen(false)}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );

}

export default Raccordements;