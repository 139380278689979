import { useEffect, useState } from "react";
import { addChallenge } from "../services/challengesService"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    NumberInput,
    NumberInputField,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper,
    Text,
    Flex,
    Radio,
    RadioGroup,
} from "@chakra-ui/react"
import { fetchItems } from "views/admin/items/services/itemService";
import { fetchChests } from "views/admin/chest/services/chestsService";
import CustomSelect from "./CustomSelect";
import CustomSelectChest from "./customSelectChest";

export default function AddModal({ isOpen, onClose, updateDocuments }) {
    const toast = useToast();

    const [items, setItems] = useState([]);
    const [chests, setChests] = useState([]);

    const [formData, setFormData] = useState({
        description: "",
        rewardType: "item",
        rewardItem: null,
        stepNumber: 0
    });

    // Gestion du changement de valeur des champs du formulaire
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    // Fonction pour sauvegarder le nouveau défi
    const handleSave = async () => {
        try {
            console.log(formData)
            addChallenge(formData.description, formData.rewardItem.id, formData.stepNumber, formData.rewardType);

            // Mettre à jour la liste des documents après avoir ajouté un nouveau défi
            updateDocuments();

            // Réinitialiser le formulaire après avoir sauvegardé le défi
            setFormData({
                description: '',
                rewardType: "item",
                rewardItem: null,
                stepNumber: 0
            });

            // Afficher un toast de succès
            toast({
                title: "Succès",
                description: "Le challenge a été créé !",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            // Fermer la modal après avoir sauvegardé le défi
            onClose();
        } catch (error) {
            // En cas d'erreur, afficher un toast d'erreur
            console.error(error);
            toast({
                title: 'Erreur',
                description: 'Une erreur est survenue lors de l\'ajout du challenge. Veuillez réessayer.',
                status: 'error',
                isClosable: true,
                duration: 5000
            });
        }
    }

    useEffect(() => {
        fetchItems().then((items) => {
            setItems(items);
        });
        fetchChests().then((chests) => {
            setChests(chests);
        });
    }, [])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Ajouter un challenge</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* Formulaire pour ajouter un nouveau défi */}
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Input
                            name="description"
                            onChange={(e) => handleChange(e)}
                        />
                    </FormControl>

                    <RadioGroup mt={4} onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, rewardType: value }))} value={formData.rewardType}>
                        <Radio value="item" mr={3}>Item</Radio>
                        <Radio value="chest">Coffre</Radio>
                    </RadioGroup>
                    {formData.rewardType === "item" ? (
                        <FormControl>
                            <FormLabel>Récompense</FormLabel>
                            <CustomSelect items={items} formData={formData} setFormData={setFormData} />
                        </FormControl>
                    ) : (
                        <FormControl>
                            <FormLabel>Récompense</FormLabel>
                            <CustomSelectChest chests={chests} formData={formData} setFormData={setFormData} />
                        </FormControl>
                    )}

                    <FormControl mt={4}>
                        <FormLabel>Nombre de d'étapes</FormLabel>
                        <NumberInput defaultValue={0}>
                            <NumberInputField
                                name="stepNumber"
                                onChange={(e) => handleChange(e)}
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    {/* Bouton pour sauvegarder le défi */}
                    <Button colorScheme="green" mr={3} onClick={handleSave}>
                        Sauvegarder
                    </Button>
                    {/* Bouton pour annuler l'ajout du défi */}
                    <Button colorScheme="red" onClick={onClose}>Annuler</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
