import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  useToast,
  Input,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Card,
  CardBody,
  Text,
  Badge,
  Heading,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  IconButton,
  Tooltip,
  Progress,
  Alert,
  AlertIcon,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Divider,
  Stack,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/react";
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  writeBatch,
  doc,
  arrayUnion,
  updateDoc,
  getDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "lib/firebase";
import { AuthContext } from "contexts/AuthContext";
import { FaBuilding, FaSearch, FaSync, FaUserPlus, FaUsers, FaChartLine } from 'react-icons/fa';

// Styles personnalisés pour react-select
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#E2E8F0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#CBD5E0',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

// MenuList virtualisé pour gérer un grand nombre d'options
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={Math.min(maxHeight, options.length * height)}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const ManageAgencies = () => {
  const [name, setName] = useState('');
  const [manager, setManager] = useState([]);
  const [superManager, setSuperManager] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [availableVendors, setAvailableVendors] = useState([]);
  const [availableSuperManagers, setAvailableSuperManagers] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [recentAgencies, setRecentAgencies] = useState([]);
  const [agencyStats, setAgencyStats] = useState({
    total: 0,
    active: 0,
    pending: 0
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAgency, setSelectedAgency] = useState(null);
  
  const toast = useToast();
  const { user, userAgencies, refreshUserAgencies } = useContext(AuthContext);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (user.profileData?.admin) {
        const [vendorsSnapshot, usersSnapshot] = await Promise.all([
          getDocs(collection(db, "vendors")),
          getDocs(query(collection(db, "users"), where("manager", "==", true)))
        ]);

        const vendorsData = vendorsSnapshot.docs
          .map(doc => ({ 
            value: doc.id, 
            label: doc.data().Name,
            data: doc.data()
          }))
          .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

        const managersData = usersSnapshot.docs
          .map(doc => ({
            value: doc.data().salesCode,
            label: doc.data().displayName || doc.data().email,
            data: doc.data()
          }))
          .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

        setAvailableVendors(vendorsData);
        setAvailableSuperManagers(managersData);

      } else if (user.profileData?.superManager) {
        // Récupérer les agences gérées par le super manager
        const agenciesQuery = query(
          collection(db, "agencies"),
          where("superManager", "==", user.profileData.salesCode)
        );
        const agenciesSnapshot = await getDocs(agenciesQuery);
        const agencyIds = agenciesSnapshot.docs.map(doc => doc.id);

        if (agencyIds.length > 0) {
          // Récupérer les vendeurs de ces agences
          const vendorsQuery = query(
            collection(db, "vendors"),
            where("agencyId", "in", agencyIds)
          );
          const vendorsSnapshot = await getDocs(vendorsQuery);
          const vendorsData = vendorsSnapshot.docs
            .map(doc => ({
              value: doc.id,
              label: doc.data().Name,
              data: doc.data()
            }))
            .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

          // Récupérer les managers de ces agences
          const managersSet = new Set();
          agenciesSnapshot.docs.forEach(doc => {
            const agencyData = doc.data();
            if (agencyData.managers) {
              agencyData.managers.forEach(managerCode => managersSet.add(managerCode));
            }
          });

          if (managersSet.size > 0) {
            const managersQuery = query(
              collection(db, "users"),
              where("salesCode", "in", Array.from(managersSet))
            );
            const managersSnapshot = await getDocs(managersQuery);
            const managersData = managersSnapshot.docs
              .map(doc => ({
                value: doc.data().salesCode,
                label: doc.data().displayName || doc.data().email,
                data: doc.data()
              }))
              .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

            setAvailableSuperManagers(managersData);
          }

          setAvailableVendors(vendorsData);
        }

        // Mettre à jour les statistiques des agences gérées
        const stats = {
          total: agencyIds.length,
          active: 0,
          pending: 0
        };

        agenciesSnapshot.docs.forEach(doc => {
          const agency = doc.data();
          if (agency.vendors && agency.vendors.length > 0) {
            stats.active++;
          } else {
            stats.pending++;
          }
        });

        setAgencyStats(stats);
        
        // Mettre à jour les agences récentes
        const recentAgenciesData = agenciesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || ''))
          .slice(0, 5);

        setRecentAgencies(recentAgenciesData);

      } else if (user.profileData?.manager) {
        try {
          const agenciesQuery = query(
            collection(db, "agencies"),
            where("managers", "array-contains", user.profileData.salesCode)
          );
          const agenciesSnapshot = await getDocs(agenciesQuery);
          
          if (!agenciesSnapshot.empty) {
            const agencyDoc = agenciesSnapshot.docs[0];
            const agencyVendors = agencyDoc.data().vendors || [];

            if (agencyVendors.length > 0) {
              const vendorsQuery = query(
                collection(db, "vendors"),
                where("__name__", "in", agencyVendors)
              );
              const vendorsSnapshot = await getDocs(vendorsQuery);
              const vendorsData = vendorsSnapshot.docs
                .map(doc => ({
                  value: doc.id,
                  label: doc.data().Name,
                  data: doc.data()
                }))
                .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
              setAvailableVendors(vendorsData);
            }

            // Mettre à jour les statistiques pour ce manager
            const stats = {
              total: 1,
              active: agencyVendors.length > 0 ? 1 : 0,
              pending: agencyVendors.length === 0 ? 1 : 0
            };
            setAgencyStats(stats);

            // Mettre à jour les agences récentes
            setRecentAgencies([{
              id: agencyDoc.id,
              ...agencyDoc.data()
            }]);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des vendeurs:", error);
          toast({
            title: "Erreur",
            description: "Impossible de charger les vendeurs",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      toast({
        title: "Erreur",
        description: "Impossible de charger les données",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!name.trim()) {
        throw new Error("Le nom de l'agence est requis");
      }

      if (user.profileData?.admin) {
        if (manager.length === 0) {
          throw new Error("Au moins un manager est requis");
        }

        const batch = writeBatch(db);

        const agencyData = {
          name,
          managers: manager.map(m => m.value),
          superManager: superManager ? superManager.value : null,
          vendors: vendors.map(vendor => vendor.value),
          teams: [],
          createdAt: new Date().toISOString(),
          createdBy: user.uid,
          status: 'active',
          metadata: {
            vendorCount: vendors.length,
            managerCount: manager.length,
            hasSuperManager: !!superManager
          }
        };

        const agencyDocRef = await addDoc(collection(db, "agencies"), agencyData);

        vendors.forEach(vendor => {
          const vendorRef = doc(db, "vendors", vendor.value);
          batch.update(vendorRef, { agencyId: agencyDocRef.id });
        });

        for (const m of manager) {
          const managerUserQuery = query(
            collection(db, "users"),
            where("salesCode", "==", m.value)
          );
          const managerUserSnapshot = await getDocs(managerUserQuery);
          
          managerUserSnapshot.forEach((managerUserDoc) => {
            const userRef = doc(db, "users", managerUserDoc.id);
            const existingTeamSalescode = managerUserDoc.data().teamSalescode || [];
            const newVendors = vendors.map(vendor => vendor.value);
            const updatedTeamSalescode = Array.from(new Set([...existingTeamSalescode, ...newVendors]));

            batch.update(userRef, {
              teamSalescode: updatedTeamSalescode
            });
          });
        }

        if (superManager) {
          const superManagerQuery = query(
            collection(db, "users"),
            where("salesCode", "==", superManager.value)
          );
          const superManagerSnapshot = await getDocs(superManagerQuery);
          
          superManagerSnapshot.forEach((userDoc) => {
            const userRef = doc(db, "users", userDoc.id);
            const existingTeamSalescode = userDoc.data().teamSalescode || [];
            
            const allSalesCodes = [
              ...manager.map(m => m.value),
              ...vendors.map(v => v.value)
            ];
            
            const updatedTeamSalescode = Array.from(new Set([
              ...existingTeamSalescode,
              ...allSalesCodes
            ]));
            
            batch.update(userRef, {
              superManager: true,
              managedAgencies: arrayUnion(agencyDocRef.id),
              teamSalescode: updatedTeamSalescode
            });
          });
        }

        await batch.commit();
        
        await Promise.all([
          fetchData(),
          fetchData()
        ]);

        toast({
          title: "Succès",
          description: "Agence créée avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setName('');
        setManager([]);
        setSuperManager(null);
        setVendors([]);
        
      } else if (user.profileData?.manager) {
        console.log("Création d'équipe pour le manager:", user.profileData.salesCode);
        
        if (!userAgencies || userAgencies.length === 0) {
          await refreshUserAgencies();
        }

        if (!userAgencies || userAgencies.length === 0) {
          throw new Error("Impossible de trouver l'agence associée à ce manager. Veuillez réessayer.");
        }

        const agencyId = userAgencies[0].id;
        console.log("Agence trouvée:", { id: agencyId, data: userAgencies[0] });

        const teamData = {
          name,
          manager: user.profileData.salesCode,
          vendors: vendors.map(vendor => vendor.value),
          agencyId: agencyId,
          createdAt: new Date().toISOString(),
        };

        console.log("Données de l'équipe à créer:", teamData);

        const batch = writeBatch(db);
        
        const teamDocRef = doc(collection(db, "teams"));
        batch.set(teamDocRef, teamData);

        const agencyRef = doc(db, "agencies", agencyId);
        batch.update(agencyRef, {
          teams: arrayUnion(teamDocRef.id)
        });

        const agencyDoc = await getDoc(agencyRef);
        const superManagerSalesCode = agencyDoc.data().superManager;

        if (superManagerSalesCode) {
          const superManagerQuery = query(
            collection(db, "users"),
            where("salesCode", "==", superManagerSalesCode)
          );
          const superManagerSnapshot = await getDocs(superManagerQuery);

          superManagerSnapshot.forEach((superManagerDoc) => {
            const existingTeamSalescode = superManagerDoc.data().teamSalescode || [];
            const newVendors = vendors.map(vendor => vendor.value);
            
            const updatedTeamSalescode = Array.from(new Set([
              ...existingTeamSalescode,
              user.profileData.salesCode,
              ...newVendors
            ]));

            batch.update(doc(db, "users", superManagerDoc.id), {
              teamSalescode: updatedTeamSalescode
            });
          });
        }

        await batch.commit();
        console.log("Équipe créée avec succès");

        toast({
          title: "Succès",
          description: "Équipe créée avec succès",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setName('');
        setVendors([]);
      }
    } catch (error) {
      console.error("Erreur lors de la création:", error);
      toast({
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de la création",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSync = async () => {
    try {
      const batch = writeBatch(db);
      
      const usersSnapshot = await getDocs(
        query(collection(db, "users"), 
        where("teamSalescode", "!=", null))
      );

      const agencyManagersMap = new Map();

      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data();
        const teamSalescode = userData.teamSalescode || [];

        if (!Array.isArray(teamSalescode) || teamSalescode.length === 0) continue;
        if (!userData.salesCode) continue;

        if (userData.manager) {
          if (!userData.salesCode) continue;

          const existingAgencyQuery = query(
            collection(db, "agencies"),
            where("managers", "array-contains", userData.salesCode)
          );
          const existingAgencySnapshot = await getDocs(existingAgencyQuery);

          if (existingAgencySnapshot.empty) {
            const agencyData = {
              name: `Agence de ${userData.displayName || userData.email}`,
              managers: [userData.salesCode],
              vendors: teamSalescode,
              teams: []
            };

            const agencyRef = doc(collection(db, "agencies"));
            batch.set(agencyRef, agencyData);
          } else {
            const agencyDoc = existingAgencySnapshot.docs[0];
            const currentVendors = agencyDoc.data().vendors || [];
            const updatedVendors = Array.from(new Set([...currentVendors, ...teamSalescode]));
            
            batch.update(doc(db, "agencies", agencyDoc.id), {
              vendors: updatedVendors
            });
          }
        }
        else if (userData.leader) {
          if (!userData.managerSalesCode || !userData.salesCode) continue;

          const managerQuery = query(
            collection(db, "agencies"),
            where("managers", "array-contains", userData.managerSalesCode)
          );
          const managerSnapshot = await getDocs(managerQuery);

          if (!managerSnapshot.empty) {
            const agencyDoc = managerSnapshot.docs[0];

            const existingTeamQuery = query(
              collection(db, "teams"),
              where("manager", "==", userData.salesCode),
              where("agencyId", "==", agencyDoc.id)
            );
            const existingTeamSnapshot = await getDocs(existingTeamQuery);

            if (existingTeamSnapshot.empty) {
              const teamData = {
                name: `Équipe de ${userData.displayName || userData.email}`,
                manager: userData.salesCode,
                vendors: teamSalescode,
                agencyId: agencyDoc.id
              };

              const teamRef = doc(collection(db, "teams"));
              batch.set(teamRef, teamData);
              
              batch.update(doc(db, "agencies", agencyDoc.id), {
                teams: arrayUnion(teamRef.id)
              });
            } else {
              const teamDoc = existingTeamSnapshot.docs[0];
              const currentVendors = teamDoc.data().vendors || [];
              const updatedVendors = Array.from(new Set([...currentVendors, ...teamSalescode]));
              
              batch.update(doc(db, "teams", teamDoc.id), {
                vendors: updatedVendors
              });
            }
          }
        }
      }

      await batch.commit();

      toast({
        title: "Succès",
        description: "Synchronisation effectuée avec succès",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      toast({
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de la synchronisation",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const AgencyStats = () => (
    <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={6}>
      <GridItem>
        <Card>
          <CardBody>
            <VStack>
              <FaBuilding size="24" />
              <Text fontSize="2xl" fontWeight="bold">{agencyStats.total}</Text>
              <Text>Total Agences</Text>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem>
        <Card>
          <CardBody>
            <VStack>
              <FaUsers size="24" />
              <Text fontSize="2xl" fontWeight="bold">{agencyStats.active}</Text>
              <Text>Agences Actives</Text>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem>
        <Card>
          <CardBody>
            <VStack>
              <FaUserPlus size="24" />
              <Text fontSize="2xl" fontWeight="bold">{agencyStats.pending}</Text>
              <Text>En Attente</Text>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );

  const RecentAgencies = () => (
    <Card mb={6}>
      <CardBody>
        <Heading size="md" mb={4}>Agences Récentes</Heading>
        <Stack spacing={4}>
          {recentAgencies.map(agency => (
            <HStack key={agency.id} justify="space-between">
              <VStack align="start" spacing={1}>
                <Text fontWeight="bold">{agency.name}</Text>
                <Text fontSize="sm" color="gray.500">
                  {agency.managers?.length || 0} managers · {agency.vendors?.length || 0} vendeurs
                </Text>
              </VStack>
              <Badge colorScheme={agency.vendors?.length > 0 ? "green" : "yellow"}>
                {agency.vendors?.length > 0 ? "Active" : "En attente"}
              </Badge>
            </HStack>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );

  if (!user.profileData?.admin && !user.profileData?.manager) {
    return null;
  }

  return (
    <Box>
      <Tabs index={selectedTab} onChange={setSelectedTab} mb={6}>
        <TabList>
          <Tab>Création</Tab>
          <Tab>Aperçu</Tab>
          {(user.profileData?.admin || user.profileData?.superManager) && (
            <Tab>Gestion des Utilisateurs</Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            {user.profileData?.admin && (
              <HStack mb={4} spacing={4}>
                <Button
                  leftIcon={<FaSync />}
                  colorScheme="purple"
                  onClick={handleSync}
                  isLoading={isLoading}
                >
                  Synchroniser
                </Button>
              </HStack>
            )}

            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>
                    {user.profileData?.admin ? "Nom de l'agence" : "Nom de l'équipe"}
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      <FaBuilding />
                    </InputLeftElement>
                    <Input 
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Entrez un nom..."
                    />
                  </InputGroup>
                </FormControl>

                {user.profileData?.admin && (
                  <>
                    <FormControl isRequired>
                      <FormLabel>Managers</FormLabel>
                      <Select
                        styles={customStyles}
                        components={{ MenuList }}
                        options={availableVendors}
                        value={manager}
                        onChange={setManager}
                        isMulti
                        placeholder="Sélectionnez les managers..."
                        closeMenuOnSelect={false}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Super Manager</FormLabel>
                      <Select
                        styles={customStyles}
                        components={{ MenuList }}
                        options={availableSuperManagers}
                        value={superManager}
                        onChange={setSuperManager}
                        placeholder="Sélectionnez un super manager..."
                        isClearable
                      />
                    </FormControl>
                  </>
                )}

                <FormControl isRequired>
                  <FormLabel>Vendeurs</FormLabel>
                  <Select
                    styles={customStyles}
                    components={{ MenuList }}
                    options={availableVendors}
                    value={vendors}
                    onChange={setVendors}
                    isMulti
                    placeholder="Sélectionnez des vendeurs..."
                    closeMenuOnSelect={false}
                  />
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isLoading}
                  loadingText="Création en cours..."
                  width="100%"
                >
                  {user.profileData?.admin ? "Créer l'agence" : "Créer l'équipe"}
                </Button>
              </VStack>
            </form>
          </TabPanel>

          <TabPanel>
            <VStack spacing={6} align="stretch">
              <AgencyStats />
              <RecentAgencies />
            </VStack>
          </TabPanel>

          {(user.profileData?.admin || user.profileData?.superManager) && (
            <TabPanel>
              <VStack spacing={6} align="stretch">
                <Card>
                  <CardBody>
                    <Heading size="md" mb={4}>Managers</Heading>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Nom</Th>
                          <Th>Code Vendeur</Th>
                          <Th>Équipe</Th>
                          <Th>Statut</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {availableSuperManagers.map((manager) => (
                          <Tr key={manager.value}>
                            <Td>{manager.label}</Td>
                            <Td>{manager.value}</Td>
                            <Td>{manager.data?.teamSalescode?.length || 0} membres</Td>
                            <Td>
                              <Badge colorScheme={manager.data?.managedAgencies?.length > 0 ? "green" : "yellow"}>
                                {manager.data?.managedAgencies?.length > 0 ? "Assigné" : "Non assigné"}
                              </Badge>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Heading size="md" mb={4}>Vendeurs</Heading>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Nom</Th>
                          <Th>Code Vendeur</Th>
                          <Th>Agence</Th>
                          <Th>Statut</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {availableVendors.map((vendor) => (
                          <Tr key={vendor.value}>
                            <Td>{vendor.label}</Td>
                            <Td>{vendor.value}</Td>
                            <Td>{vendor.data?.agencyId || "Non assigné"}</Td>
                            <Td>
                              <Badge colorScheme={vendor.data?.agencyId ? "green" : "yellow"}>
                                {vendor.data?.agencyId ? "Assigné" : "Non assigné"}
                              </Badge>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ManageAgencies;
