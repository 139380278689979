import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Text, useColorMode, Heading, Flex, Stat, StatLabel, StatNumber, StatHelpText, StatArrow } from "@chakra-ui/react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useData } from "contexts/DataProvider"; // Importez useData pour accéder aux données globales

const ContratsJournaliers = () => {
  const jsonData = useData(); // Utilisez useData pour accéder aux données
  const { colorMode } = useColorMode();
  const [chartData, setChartData] = useState([]);
  const [period, setPeriod] = useState("Tous");
  const [totalContracts, setTotalContracts] = useState(0);
  const [growthRate, setGrowthRate] = useState(0);
  

  const prepareChartData = (records, period) => {
    let filteredRecords = [...records];

    if (period !== "Tous") {
      const now = new Date();
      let periodStart = new Date();
      switch (period) {
        case "Semaine":
          periodStart.setDate(now.getDate() - 7);
          break;
        case "Mois":
          periodStart.setMonth(now.getMonth() - 1);
          break;
        case "Année":
          periodStart.setFullYear(now.getFullYear() - 1);
          break;
        default:
          break;
      }

      filteredRecords = records.filter((record) => new Date(record.CreatedDate) >= periodStart);
    }

    let obj = {};
    filteredRecords.sort((a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate)).forEach((record) => {
      const date = new Date(record.CreatedDate);
      const day = date.getDay();
      
      // Exclure samedi (6) et dimanche (0)
      if (day !== 0 && day !== 6) {
        const dateString = date.toLocaleDateString('fr-FR');
      
        if (obj[dateString]) {
          obj[dateString]++;
        } else {
          obj[dateString] = 1;
        }
      }
    });

    let total = 0; // Déclaration de la variable total
    const arr = Object.keys(obj).map((key) => {
      total += obj[key];
      return {
        date: key,
        count: obj[key],
      };
    });

    setChartData(arr);
    setTotalContracts(total);

    // Calculate growth rate
    if (arr.length >= 2) {
      const firstValue = arr[0].count;
      const lastValue = arr[arr.length - 1].count;
      const growth = ((lastValue - firstValue) / firstValue) * 100;
      setGrowthRate(growth.toFixed(2));
    }
  };

  useEffect(() => {
    if (jsonData) {
      prepareChartData(jsonData, period);
    }
  }, [jsonData, period]); // Ajoutez jsonData comme dépendance pour recalculer le graphique à chaque fois que les données ou la période changent

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  return (
    <Box p={5} boxShadow="xl" bg={colorMode === "dark" ? "gray.700" : "white"} borderRadius="md">
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Heading size="lg">Évolution des ventes</Heading>
        <Stat textAlign="right">
          <StatLabel>Total des contrats</StatLabel>
          <StatNumber>{totalContracts}</StatNumber>
          <StatHelpText>
            <StatArrow type={growthRate >= 0 ? "increase" : "decrease"} />
            {growthRate}%
          </StatHelpText>
        </Stat>
      </Flex>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData}>
          <Line type="monotone" dataKey="count" stroke="#3182CE" strokeWidth={3} dot={{ r: 4, strokeWidth: 2, fill: "#fff" }} />
          <CartesianGrid stroke="#E2E8F0" strokeDasharray="5 5" />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} />
          <YAxis tick={{ fontSize: 12 }} />
          <Tooltip
            contentStyle={{ backgroundColor: colorMode === "dark" ? "#2D3748" : "#fff", border: "none", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0,0,0,0.1)" }}
            itemStyle={{ color: "#3182CE" }}
          />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
      <ButtonGroup mt={5} width="100%" justifyContent="center">
        {["Tous", "Semaine", "Mois", "Année"].map((p) => (
          <Button
            key={p}
            colorScheme={period === p ? "blue" : "gray"}
            onClick={() => handlePeriodChange(p)}
            flex={1}
            maxW="150px"
          >
            {p}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default ContratsJournaliers;
