import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  useColorMode,
  useColorModeValue,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  Select,
  Grid,
  GridItem,
  useBreakpointValue,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  IconButton,
  HStack,
  Link as ChakraLink,
  Divider,
  InputRightElement,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { AuthContext } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useData } from "contexts/DataProvider";
import * as XLSX from "xlsx";
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from "@chakra-ui/icons";
import { MdBarChart, MdOutlineRotateLeft, MdDownload, MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";

const SFR_OFFERS = [
  "Mobile 200 Go 5G",
  "SFR PREMIUM",
  "Mobile 2h 100 Mo",
  "Mobile 100 Go 5G",
  "Mobile 250 Go 5G",
  "SFR POWER",
  "100Go 5G SIM Seule",
  "Mobile 5 Go 4G+",
  "Mobile 220 Go 5G",
  "SFR",
  "Sfr Power TV box 8",
  "Sfr Power box 8",
  "Mobile 140 Go 5G",
  "Mobile 80 Go 4G+",
  "Mobile 120 Go 5G",
  "Mobile 110 Go 5G",
  "Mobile 160 Go 5G",
  "Sfr box 8",
  "Sfr Power TV",
];

const STATUS_COLOR_SCHEMES = {
  EnCours: "blue",
  EnCoursDeRattrapage: "orange",
  Error: "red",
  Validated: "green",
  Payed: "teal",
  RaccordOK: "cyan",
  RaccordKO: "pink",
  ToConfirm: "purple",
  Progress: "yellow",
  default: "gray",
};

const periods = ["Semaine", "Mois", "Année"];
const statuses = [
  "Tous",
  "EnCours",
  "EnCoursDeRattrapage",
  "Error",
  "Validated",
  "Payed",
  "RaccordOK",
  "RaccordKO",
];
const periodsMap = {
  Semaine: 7,
  Mois: 30,
  Année: 365,
};

// Fonctions utilitaires
const formatDate = (date, isPaymentDate = false, displayTime = true) => {
  if (!date) return "";
  const d = new Date(date);
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hours = d.getHours();
  let minutes = d.getMinutes();

  if (isPaymentDate) {
    month += 1;
    if (month > 12) {
      month = 1;
      year++;
    }
  }

  const datePart = isNaN(d.getTime())
    ? ""
    : `${String(d.getDate()).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`;
  const timePart = displayTime
    ? ` ${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
    : "";

  return `${datePart}${timePart}`;
};

const getDiffDays = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((date1 - date2) / oneDay));
};

const extractPostalCode = (address) => {
  const postalCodeRegex = /(\d{5})/;
  const matches = address.match(postalCodeRegex);
  return matches ? matches[0] : "";
};

const translateStatus = (status) => {
  const translations = {
    EnCours: "En cours",
    RaccordKO: "Raccordement KO",
    RaccordOK: "Raccordé",
    Inconnu: "Inconnu",
    EnCoursDeRattrapage: "En cours de rattrapage",
  };
  return translations[status] || "";
};

const getStatusColorScheme = (status) => {
  return STATUS_COLOR_SCHEMES[status] || STATUS_COLOR_SCHEMES.default;
};

const ManagerTable = () => {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("white", "darkPaper");
  const textColor = useColorModeValue("gray.700", "darkText");
  const borderColor = useColorModeValue("gray.200", "darkBorder");
  const tableHeaderBg = useColorModeValue("gray.50", "darkTableHeader");
  const tableRowBg = useColorModeValue("white", "darkTableRow");
  const tableRowAltBg = useColorModeValue("gray.50", "darkTableRowAlt");

  const { user } = useContext(AuthContext);
  const { data } = useData();
  const { t } = useTranslation();

  const [selectedVendor, setSelectedVendor] = useState("");
  const [records, setRecords] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "CreatedDate",
    ascending: false,
  });
  const [filter, setFilter] = useState({ period: "Tous", status: "Tous" });
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [offerFilter, setOfferFilter] = useState("Tous");
  const [postalCodeFilter, setPostalCodeFilter] = useState("");
  const [appliedPostalCodeFilter, setAppliedPostalCodeFilter] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [selectedOffer, setSelectedOffer] = useState("Tous");

  const now = new Date();

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setRecords(data);
    } else {
      console.error("Les données reçues ne sont pas un tableau:", data);
      setRecords([]);
    }
  }, [data]);

  const vendorNames = useMemo(() => {
    const names = {};
    if (data && Array.isArray(data)) {
      data.forEach((record) => {
        if (record.VendorName) names[record.VendorName] = true;
      });
    }
    return names;
  }, [data]);

  const availableOffers = useMemo(() => {
    const offers = new Set();
    if (data && Array.isArray(data)) {
      data.forEach((record) => {
        if (record.OfferName__c) offers.add(record.OfferName__c);
      });
    }
    return Array.from(offers).sort();
  }, [data]);

  const filteredRecords = useMemo(() => {
    const cutoffDate = new Date("2023-11-01");
    let filtered = records.filter((record) => new Date(record.CreatedDate) >= cutoffDate);

    if (filter.period !== "Tous") {
      filtered = filtered.filter((record) => {
        const recordDate = new Date(record.CreatedDate);
        const diffDays = getDiffDays(now, recordDate);
        return diffDays <= periodsMap[filter.period];
      });
    }

    if (filter.status !== "Tous") {
      filtered = filtered.filter(
        (record) =>
          record.Status__c === filter.status || record.ConnectionStatus__c === filter.status
      );
    }

    if (offerFilter === "SFR") {
      filtered = filtered.filter((record) => SFR_OFFERS.includes(record.OfferName__c));
    } else if (offerFilter === "Bouygues") {
      filtered = filtered.filter((record) => !SFR_OFFERS.includes(record.OfferName__c));
    }

    if (selectedOffer !== "Tous") {
      filtered = filtered.filter((record) => record.OfferName__c === selectedOffer);
    }

    if (selectedDate) {
      const selectedMonth = selectedDate.getMonth();
      const selectedYear = selectedDate.getFullYear();

      filtered = filtered.filter((record) => {
        if (!record.DateOfPayment__c) return false;
        const paymentDate = new Date(record.DateOfPayment__c);
        return (
          paymentDate.getFullYear() === selectedYear &&
          paymentDate.getMonth() === selectedMonth - 1
        );
      });
    }

    if (selectedVendor) {
      filtered = filtered.filter((record) => record.VendorName === selectedVendor);
    }

    if (appliedPostalCodeFilter) {
      filtered = filtered.filter((record) => {
        const postalCode = extractPostalCode(record.TchAddress__c);
        return postalCode.startsWith(appliedPostalCodeFilter);
      });
    }

    // Recherche
    const searchLower = searchValue.toLowerCase();
    filtered = filtered.filter(
      (record) =>
        record.TchProspectName__c?.toLowerCase().includes(searchLower) ||
        record.ProspectMobilePhone__c?.toLowerCase().includes(searchLower) ||
        record.OrderNumber__c?.toLowerCase().includes(searchLower) ||
        record.VendorName?.toLowerCase().includes(searchLower)
    );

    return filtered;
  }, [
    records,
    filter,
    offerFilter,
    selectedOffer,
    selectedDate,
    selectedVendor,
    appliedPostalCodeFilter,
    searchValue,
    now,
  ]);

  const sortedRecords = useMemo(() => {
    const sorted = [...filteredRecords];
    sorted.sort((a, b) => {
      const dateA = new Date(a[sortConfig.key]);
      const dateB = new Date(b[sortConfig.key]);

      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
      if (isNaN(dateA.getTime())) return sortConfig.ascending ? 1 : -1;
      if (isNaN(dateB.getTime())) return sortConfig.ascending ? -1 : 1;

      return sortConfig.ascending ? dateA - dateB : dateB - dateA;
    });

    return sorted.map((record) => ({
      ...record,
      CreatedDate: formatDate(record.CreatedDate, false, true),
      ConnectingDatePlanned__c: formatDate(record.ConnectingDatePlanned__c, false, false),
      DateOfPayment__c: formatDate(record.DateOfPayment__c, true, false),
    }));
  }, [filteredRecords, sortConfig]);

  const offset = currentPage * entriesPerPage;
  const pageCount = Math.ceil(filteredRecords.length / entriesPerPage);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleVendorChange = useCallback((e) => {
    setSelectedVendor(e.target.value);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const handleFilter = useCallback((period, status = "Tous") => {
    setFilter({ period, status });
  }, []);

  const handlePageClick = useCallback(({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }, []);

  const handleDownloadExcel = useCallback(() => {
    if (filteredRecords.length === 0) return;

    const sortedData = sortedRecords.map((record) => ({
      ...record,
      CreatedDate: record.CreatedDate,
      ConnectingDatePlanned__c: record.ConnectingDatePlanned__c,
      DateOfPayment__c: record.DateOfPayment__c,
    }));

    const worksheet = XLSX.utils.json_to_sheet(sortedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SalesData");
    XLSX.writeFile(workbook, "sales_data.xlsx");
  }, [filteredRecords, sortedRecords]);

  const toggleSortDirection = useCallback((key) => {
    setSortConfig((prevConfig) => ({
      key,
      ascending: prevConfig.key === key ? !prevConfig.ascending : true,
    }));
  }, []);

  const handleEntriesPerPageChange = useCallback((e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(0);
  }, []);

  const handlePostalCodeChange = useCallback((e) => {
    setPostalCodeFilter(e.target.value);
  }, []);

  const applyPostalCodeFilter = useCallback(() => {
    setAppliedPostalCodeFilter(postalCodeFilter);
  }, [postalCodeFilter]);

  const resetDatePicker = useCallback(() => {
    setSelectedDate(null);
  }, []);

  const handleOpenModal = useCallback((record) => {
    setCurrentRecord(record);
    setIsOpen(true);
  }, []);

  const renderPaginationButtons = useCallback(() => {
    const buttons = [];
    const totalPages = pageCount;
    const currentPageIndex = currentPage;

    if (!isMobile) {
      // Version Desktop
      buttons.push(
        <Button
          key={0}
          onClick={() => handlePageClick({ selected: 0 })}
          colorScheme={currentPageIndex === 0 ? "blue" : "gray"}
          variant={currentPageIndex === 0 ? "solid" : "outline"}
        >
          1
        </Button>
      );

      if (currentPageIndex > 3) {
        buttons.push(<Text key="ellipsis-1">...</Text>);
      }

      for (
        let i = Math.max(1, currentPageIndex - 1);
        i <= Math.min(totalPages - 2, currentPageIndex + 1);
        i++
      ) {
        buttons.push(
          <Button
            key={i}
            onClick={() => handlePageClick({ selected: i })}
            colorScheme={currentPageIndex === i ? "blue" : "gray"}
            variant={currentPageIndex === i ? "solid" : "outline"}
          >
            {i + 1}
          </Button>
        );
      }

      if (currentPageIndex < totalPages - 4) {
        buttons.push(<Text key="ellipsis-2">...</Text>);
      }

      if (totalPages > 1) {
        buttons.push(
          <Button
            key={totalPages - 1}
            onClick={() => handlePageClick({ selected: totalPages - 1 })}
            colorScheme={currentPageIndex === totalPages - 1 ? "blue" : "gray"}
            variant={currentPageIndex === totalPages - 1 ? "solid" : "outline"}
          >
            {totalPages}
          </Button>
        );
      }
    } else {
      // Version Mobile
      buttons.push(
        <Text key="current-page" fontWeight="bold" mx={2}>
          {currentPageIndex + 1} / {totalPages}
        </Text>
      );
    }

    return buttons;
  }, [currentPage, pageCount, isMobile, handlePageClick]);


  return (
    <Box bg={bgColor} borderRadius="lg" boxShadow="sm" p={6} overflow="hidden" maxW="100%">
      <VStack spacing={6} align="stretch">
        {/* Header */}
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "stretch", md: "center" }}
          gap={4}
        >
          <Heading size="lg" color="blue.600">
            Tableau de bord des ventes
          </Heading>
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={2}
            width={{ base: "100%", md: "auto" }}
          >
            <Link to="/admin/statistiques" style={{ width: "100%" }}>
              <Button
                leftIcon={<MdBarChart />}
                colorScheme="blue"
                variant="outline"
                width="100%"
              >
                Statistiques
              </Button>
            </Link>
            <Button
              onClick={handleDownloadExcel}
              colorScheme="blue"
              variant="solid"
              leftIcon={<MdDownload />}
              width="100%"
            >
              Exporter en Excel
            </Button>
          </Flex>
        </Flex>

        {/* Filters and Search */}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <MdSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Rechercher..."
              value={searchValue}
              onChange={handleSearchChange}
              borderColor="gray.300"
            />
          </InputGroup>
          <Select
            onChange={(e) => setOfferFilter(e.target.value)}
            placeholder="Filtrer par fournisseur"
          >
            <option value="Tous">Tous</option>
            <option value="SFR">SFR</option>
            <option value="Bouygues">Bouygues</option>
          </Select>
          <Select
            onChange={(e) => setSelectedOffer(e.target.value)}
            placeholder="Filtrer par offre"
            value={selectedOffer}
          >
            <option value="Tous">Toutes les offres</option>
            {availableOffers.map((offer) => (
              <option key={offer} value={offer}>
                {offer}
              </option>
            ))}
          </Select>
          <Select
            onChange={(e) => handleFilter(e.target.value, filter.status)}
            value={filter.period}
          >
            <option value="Tous">Toutes les périodes</option>
            {periods.map((period) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </Select>
          <Select
            onChange={(e) => handleFilter(filter.period, e.target.value)}
            value={filter.status}
          >
            {statuses.map((status) => (
              <option key={status} value={status}>
                {t(status)}
              </option>
            ))}
          </Select>
          <Select
            value={selectedVendor}
            onChange={handleVendorChange}
            placeholder="Filtrer par vendeur"
          >
            <option value="">Tous les vendeurs</option>
            {Object.keys(vendorNames)
              .sort()
              .map((vendorName) => (
                <option key={vendorName} value={vendorName}>
                  {vendorName}
                </option>
              ))}
          </Select>
          <Flex alignItems="center">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              customInput={<Input />}
              placeholderText="Choisir une date de facturation"
            />
            <IconButton
              aria-label="Réinitialiser la date"
              icon={<MdOutlineRotateLeft />}
              onClick={resetDatePicker}
              ml={2}
            />
          </Flex>
          {/* Postal Code Filter */}
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Filtrer par code postal"
              value={postalCodeFilter}
              onChange={handlePostalCodeChange}
              borderColor="gray.300"
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={applyPostalCodeFilter}>
                Filtrer
              </Button>
            </InputRightElement>
          </InputGroup>
          {/* Entries Per Page */}
          <Select
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            placeholder="Entrées par page"
          >
            <option value={10}>10 entrées</option>
            <option value={50}>50 entrées</option>
            <option value={100}>100 entrées</option>
          </Select>
        </SimpleGrid>

        {/* Table for Large Screens */}
        <Box display={{ base: "none", lg: "block" }} overflowX="auto">
          <Table variant="simple" size="sm" borderWidth="1px" borderColor="gray.200" color={textColor}>
            <Thead bg={tableHeaderBg}>
              <Tr>
                <Th onClick={() => toggleSortDirection("CreatedDate")} cursor="pointer">
                  Date de la vente {sortConfig.key === "CreatedDate" && (sortConfig.ascending ? "↑" : "↓")}
                </Th>
                <Th>Vendeur</Th>
                <Th>Nom</Th>
                <Th>Numéro de téléphone</Th>
                <Th>Code Postal</Th>
                <Th>Offre</Th>
                <Th onClick={() => toggleSortDirection("ConnectingDatePlanned__c")} cursor="pointer">
                  Date de raccordement prévue {sortConfig.key === "ConnectingDatePlanned__c" && (sortConfig.ascending ? "↑" : "↓")}
                </Th>
                <Th>Statut</Th>
                <Th>Raccordement</Th>
                <Th>Numéro de Panier</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedRecords.slice(offset, offset + entriesPerPage).map((record, index) => (
                <Tr
                  key={record.OrderNumber__c || index}
                  onClick={() => handleOpenModal(record)}
                  _hover={{ bg: colorMode === "light" ? "gray.100" : "darkTableRowAlt" }}
                  cursor="pointer"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  bg={index % 2 === 0 ? tableRowBg : tableRowAltBg}
                >
                  <Td>{record.CreatedDate}</Td>
                  <Td>{record.VendorName}</Td>
                  <Td>{record.TchProspectName__c}</Td>
                  <Td>
                    <ChakraLink href={`tel:${record.ProspectMobilePhone__c}`} color="blue.500">
                      {record.ProspectMobilePhone__c}
                    </ChakraLink>
                  </Td>
                  <Td>{extractPostalCode(record.TchAddress__c)}</Td>
                  <Td>{record.OfferName__c}</Td>
                  <Td>{record.ConnectingDatePlanned__c}</Td>
                  <Td>
                    <Tag colorScheme={getStatusColorScheme(record.Status__c)} size="sm">
                      {t(record.Status__c)}
                    </Tag>
                  </Td>
                  <Td>
                    {record.ConnectionStatus__c && (
                      <Tag colorScheme={getStatusColorScheme(record.ConnectionStatus__c)} size="sm">
                        {translateStatus(record.ConnectionStatus__c)}
                      </Tag>
                    )}
                  </Td>
                  <Td>{record.BasketNumber__c}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        {/* Cards for Mobile and Tablet */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} display={{ base: "grid", lg: "none" }}>
          {sortedRecords.slice(offset, offset + entriesPerPage).map((record, index) => (
            <Box
              key={record.OrderNumber__c || index}
              p={4}
              borderWidth={1}
              borderRadius="md"
              onClick={() => handleOpenModal(record)}
              cursor="pointer"
              _hover={{ boxShadow: "md" }}
              borderColor={borderColor}
              bg={bgColor}
            >
              <VStack align="start" spacing={2}>
                <Heading size="sm">{record.TchProspectName__c}</Heading>
                <Text>
                  <strong>Numéro :</strong>{" "}
                  <ChakraLink href={`tel:${record.ProspectMobilePhone__c}`} color="blue.500">
                    {record.ProspectMobilePhone__c}
                  </ChakraLink>
                </Text>
                <Text>
                  <strong>Offre :</strong> {record.OfferName__c}
                </Text>
                <Text>
                  <strong>Date de raccordement :</strong> {record.ConnectingDatePlanned__c}
                </Text>
                <Tag colorScheme={getStatusColorScheme(record.Status__c)}>
                  {t(record.Status__c)}
                </Tag>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>

        {/* Pagination */}
        <Flex justifyContent="center" mt={4}>
          <HStack spacing={2}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handlePageClick({ selected: Math.max(0, currentPage - 1) })}
              isDisabled={currentPage === 0}
              aria-label="Page précédente"
              variant="outline"
              colorScheme="blue"
              size={isMobile ? "sm" : "md"}
            />
            {renderPaginationButtons()}
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() =>
                handlePageClick({ selected: Math.min(pageCount - 1, currentPage + 1) })
              }
              isDisabled={currentPage === pageCount - 1}
              aria-label="Page suivante"
              variant="outline"
              colorScheme="blue"
              size={isMobile ? "sm" : "md"}
            />
          </HStack>
        </Flex>
      </VStack>

      {/* Modal for Record Details */}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent bg={bgColor} borderRadius="md">
          <ModalHeader bg="blue.600" color="white" borderTopRadius="md">
            Détails de la vente
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody py={6}>
            {currentRecord && (
              <VStack align="stretch" spacing={6} width="100%">
                {/* Client Information */}
                <Box>
                  <Heading size="md" color="blue.600" mb={3}>
                    Informations client
                  </Heading>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold">Nom</Text>
                      <Text>{currentRecord.TchProspectName__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Téléphone</Text>
                      <ChakraLink href={`tel:${currentRecord.ProspectMobilePhone__c}`} color="blue.500">
                        {currentRecord.ProspectMobilePhone__c}
                      </ChakraLink>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Email</Text>
                      <Text>{currentRecord.ProspectEmail__c}</Text>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Text fontWeight="bold">Adresse</Text>
                      <Text>{currentRecord.TchAddress__c}</Text>
                    </GridItem>
                  </Grid>
                </Box>

                <Divider />

                {/* Order Details */}
                <Box>
                  <Heading size="md" color="blue.600" mb={3}>
                    Détails de la commande
                  </Heading>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold">Offre</Text>
                      <Text>{currentRecord.OfferName__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Date de création</Text>
                      <Text>{currentRecord.CreatedDate}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Date de raccordement prévue</Text>
                      <Text>{currentRecord.ConnectingDatePlanned__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Statut</Text>
                      <Tag colorScheme={getStatusColorScheme(currentRecord.Status__c)}>
                        {t(currentRecord.Status__c)}
                      </Tag>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Statut de raccordement</Text>
                      <Tag colorScheme={getStatusColorScheme(currentRecord.ConnectionStatus__c)}>
                        {translateStatus(currentRecord.ConnectionStatus__c)}
                      </Tag>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Numéro de commande</Text>
                      <Text>{currentRecord.OrderNumber__c}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">Numéro de panier</Text>
                      <Text>{currentRecord.BasketNumber__c}</Text>
                    </GridItem>
                  </Grid>
                </Box>

                <Divider />

                {/* Vendor Information */}
                <Box>
                  <Heading size="md" color="blue.600" mb={3}>
                    Informations vendeur
                  </Heading>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <Text fontWeight="bold">Nom du vendeur</Text>
                      <Text>{currentRecord.VendorName}</Text>
                    </GridItem>
                    <GridItem>
                      <Text fontWeight="bold">ID du vendeur</Text>
                      <Text>{currentRecord.VendorId__c}</Text>
                    </GridItem>
                  </Grid>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter bg="gray.100" borderBottomRadius="md">
            <Button colorScheme="blue" onClick={() => setIsOpen(false)}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </Box>
  );
};

export default ManagerTable;