import localforage from 'localforage';

const CACHE_PREFIX = 'myapp_cache_';
const DEFAULT_EXPIRATION = 60 * 60 * 1000; // 1 hour in milliseconds

export const cacheData = async (key, data, expiration = DEFAULT_EXPIRATION) => {
  const cacheKey = `${CACHE_PREFIX}${key}`;
  const cacheItem = {
    data,
    expiration: Date.now() + expiration
  };
  await localforage.setItem(cacheKey, cacheItem);
};

export const getCachedData = async (key) => {
  const cacheKey = `${CACHE_PREFIX}${key}`;
  const cacheItem = await localforage.getItem(cacheKey);
  
  if (!cacheItem) return null;
  
  if (Date.now() > cacheItem.expiration) {
    await localforage.removeItem(cacheKey);
    return null;
  }
  
  return cacheItem.data;
};

export const clearCache = async () => {
  const keys = await localforage.keys();
  const cachesToRemove = keys.filter(key => key.startsWith(CACHE_PREFIX));
  await Promise.all(cachesToRemove.map(key => localforage.removeItem(key)));
};
