import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    NumberInput,
    NumberInputField,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper,
    Text
} from "@chakra-ui/react"
import { modifyItem } from "../services/itemService";
import { useEffect, useState } from "react";
import ReactSelect, { components } from "react-select";
import axios from "axios";

export default function ModifyModal({ isOpen, onClose, selectedDoc, setSelectedDoc, documents, setDocuments }) {
    const toast = useToast();
    const [imageChoice, setImageChoice] = useState("existing"); // 'new' pour nouvelle image, 'existing' pour image existante
    const [imageLinks, setImageLinks] = useState([]);

    const updateLocalDocument = (field, value) => {
        // Créez une copie du document sélectionné avec la modification
        const updatedDocument = { ...selectedDoc, [field]: value };
        setSelectedDoc(updatedDocument);
        // Mettez à jour l'état local du tableau (documents) avec le document modifié
        const updatedDocuments = documents.map((doc) =>
            doc.id === updatedDocument.id ? updatedDocument : doc
        );
        setDocuments(updatedDocuments);
        setSelectedDoc(updatedDocument);
    };

    const [formData, setFormData] = useState({
        name: "",
        quantity: 0,
        image: "",
    });


    function CustomSelect({ imageLinks }) {
        const options = imageLinks.map((image) => ({
            value: image.url,
            label: image.name,
            image: image.url
        }));
    
        const { Option } = components;
        const ImageOption = (props) => (
            <Option {...props}>
                <div style={{display: "flex"}}>
                    <img src={props.data.image} alt={props.data.label} style={{ width: 100, marginRight: 10 }} />
                    {props.data.label}
                </div>
            </Option>
        );
    
        return (
            <>
                <ReactSelect
                    name="image"
                    options={options}
                    components={{ Option: ImageOption }}
                    onChange={(e) => updateLocalDocument("image", e.value)}
                />

                {formData.image && (
                    <>
                        <Text mt={2} mb={1}>Image sélectionnée :</Text>
                        <img src={selectedDoc.image} alt="Selected Image" style={{ width: 200 }} />
                    </>
                )}
            </>
        );
    }

    useEffect(() => {
        const url = 'http://82.180.155.225:3002/images';
    
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            // Mise à jour de l'état imageLinks avec les données récupérées
            setImageLinks(data);
          })
          .catch(error => {
            console.error(error);
          });
        
        setFormData({ ...formData, image: selectedDoc.image });
    }, []); 

    const handleSave = async () => {
        try {

            let image = "";
            if (imageChoice === "new") {
                let data = new FormData();
                data.append("image", formData.image);

                
                const res = await fetch("http://82.180.155.225:3002/upload", {
                    method: "POST",
                    body: data
                });
                
                
                if (res.status == 200) {
                    let ez = await res.json();
                    console.log(ez);
                    image = "http://82.180.155.225:3002/images/" + ez.filename
                    updateLocalDocument("image", image);
                }
                modifyItem(selectedDoc, image)
            } else {
                console.log(selectedDoc)
                modifyItem(selectedDoc, selectedDoc.image)
            }
      
            // Affiche un toast de succès après la mise à jour
            toast({
              title: "Document mis à jour",
              description: "Les modifications ont été enregistrées avec succès.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
      
            onClose(); // Fermez la modale après la sauvegarde
          } catch (error) {
            console.error("Erreur lors de la sauvegarde :", error);
            // Affiche un toast d'erreur si la mise à jour échoue
            toast({
              title: "Échec de la mise à jour",
              description:
                "Une erreur est survenue lors de la sauvegarde des modifications.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
        }
    }

    const handleImageChange = (event) => {
        const { name, value, files } = event.target;
        if (name === "image" && files) {
            // Si c'est un fichier, utilisez URL.createObjectURL pour prévisualiser l'image
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: files[0]
            }));
            
        } else {
            // Pour les autres cas, notamment le choix d'une image existante
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Modifier l'item</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Nom</FormLabel>
                        <Input
                            value={selectedDoc.name}
                            onChange={(e) => updateLocalDocument("name", e.target.value)}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Quantité</FormLabel>
                        <NumberInput
                            defaultValue={selectedDoc.quantity}
                        >
                            <NumberInputField
                                onChange={(e) => updateLocalDocument("quantity", e.target.value)}
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Image</FormLabel>
                        <Select mb={4} name="imageChoice" onChange={(e) => setImageChoice(e.target.value)}>
                            <option value="existing">Sélectionner une image existante</option>
                            <option value="new">Télécharger une nouvelle image</option>
                        </Select>
                        {imageChoice === "new" ? (
                            <Input
                                name="image"
                                type="file"
                                onChange={(e) => handleImageChange(e)}
                            />
                        ) : (
                            <CustomSelect imageLinks={imageLinks} />
                        )}
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="green" mr={3} onClick={handleSave}>
                        Sauvegarder
                    </Button>
                    <Button colorScheme="red" onClick={onClose}>Annuler</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}