import React from "react";
import { deleteItem } from "../services/itemService";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useToast,
} from "@chakra-ui/react";

export default function DeleteModal({ isOpen, onClose, itemId, updateDocuments }) {
    const toast = useToast();
    // Fonction pour confirmer la suppression d'un challenge
    const confirmDeleteItem = async (itemId) => {
        try {
            // Supprime le challenge et la progression pour celui-ci pour tous les users
            await deleteItem(itemId);

            // Notification de succès
            toast({
                title: "Succès",
                description: "L'item a été supprimé avec succès !",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            // Mise à jour de la liste des documents après la suppression
            updateDocuments();
            // Fermeture de la modal de confirmation
            onClose();
        } catch (error) {
            // Notification d'erreur en cas de problème
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la suppression de l'item. Veuillez réessayer.",
                status: 'error',
                isClosable: true,
                duration: 5000
            });
            console.error("Error deleting item: ", error);
        }
    };
    
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmation de suppression</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer cet item ?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="green" mr={3} onClick={() => confirmDeleteItem(itemId)}>
                        Confirmer
                    </Button>
                    <Button colorScheme="red" onClick={onClose}>
                        Annuler
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}