import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "lib/firebase";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Box,
  Tooltip,
  Text,
  Select,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton,
  Checkbox,
  Heading,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
} from "@chakra-ui/react";
import { MdLocationOn, MdInfoOutline } from "react-icons/md";
import Map, { Marker, Popup, NavigationControl, FullscreenControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Nécessaire pour le style de la carte

const CommercialMap = () => {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contactData, setContactData] = useState([]); // Stockez les données des contacts
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [docIdToDelete, setDocIdToDelete] = useState(null);

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiZG9rZ28iLCJhIjoiY2xzZDA4cHNsMGpiNjJpbWhobm03azNyeCJ9.rfLUyJVQL_JaaHkZV-tmhA";

  // useCallback pour optimiser le gestionnaire de clics
  const handleMarkerClick = useCallback((doc) => {
    setSelectedMarker(doc);
  }, []);

  const toast = useToast();
  const itemsPerPage = 10;
  const cancelRef = useRef();

  // Changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Définir fetchDocuments à ce niveau pour qu'elle soit accessible partout dans le composant
  const fetchDocuments = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const docsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Tri et autres opérations omises pour la brièveté
    setDocuments(docsArray);
  };

  useEffect(() => {
    fetchDocuments(); // Appel initial pour charger les documents
  }, []);
  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const docsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDocuments(docsArray);
    };
    fetchDocuments();
  }, []);



  const fetchSalesforceContacts = async () => {
    try {
      const response = await fetch(
        "https://app.falconmarketing.fr:3001/api/vendors"
      ); // Assurez-vous que l'URL correspond à votre configuration de serveur
      const data = await response.json();
      setContactData(data.records); // Supposant que `data` contient les données de la même manière que la réponse directe de Salesforce
      console.log("Contacts Salesforce récupérés avec succès :", data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des contacts Salesforce :",
        error
      );
    }
  };

  useEffect(() => {
    const numberOfPages = Math.ceil(filteredDocuments.length / itemsPerPage);
    setPageNumbers(Array.from({ length: numberOfPages }, (_, i) => i + 1));
  }, [filteredDocuments, itemsPerPage]);

  useEffect(() => {
    fetchSalesforceContacts();
  }, []); // Le tableau vide assure que cela s'exécute une seule fois au montage du composant

  // Mise à jour de useEffect pour le filtrage
  useEffect(() => {
    // Fonction pour obtenir le nom du vendeur à partir de son ID de manière sécurisée
    const getVendorNameById = (vendorId) => {
      const vendor = contactData.find((vendor) => vendor.Id === vendorId);
      return vendor ? `${vendor.Name}` : "Inconnu"; // Adaptez selon vos données, par exemple `${vendor.FirstName} ${vendor.LastName}`
    };
    // Filtrer les documents de manière sécurisée
    const filtered = documents.filter((doc) => {
      // Vérifiez d'abord si l'email existe pour éviter l'erreur
      const emailMatches =
        searchTerm === "" ||
        (doc.email &&
          doc.email.toLowerCase().includes(searchTerm.toLowerCase()));
      // Utilisez getVendorNameById qui gère maintenant les cas où vendorId pourrait être undefined
      const vendorNameMatches = getVendorNameById(doc.salesCode).includes(
        searchTerm.toLowerCase()
      );
      return emailMatches || vendorNameMatches;
    });

    setFilteredDocuments(filtered);
  }, [searchTerm, documents, contactData]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      let docsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Trier les documents par la dernière connexion, les plus récents en premier
      // Assurez-vous que `lastLogin` existe et utilisez `seconds` pour comparer
      docsArray.sort((a, b) => {
        const aLastLogin = a.lastLogin ? a.lastLogin.seconds : 0;
        const bLastLogin = b.lastLogin ? b.lastLogin.seconds : 0;
        return bLastLogin - aLastLogin; // Pour un tri décroissant
      });

      setDocuments(docsArray);
    };
    fetchDocuments();
  }, []);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = documents.slice(indexOfFirstItem, indexOfLastItem);



  const getVendorNameById = (vendorId) => {
    const vendor = contactData.find((vendor) => vendor.Id === vendorId);
    return vendor ? vendor.Name.toLowerCase() : "";
  };

  const [mapSize, setMapSize] = useState({ width: "100%", height: "500px" });

  useEffect(() => {
    // Une légère modification pour déclencher le redimensionnement
    const resizeMap = () => {
      setMapSize({ width: "100%", height: "500px" });
    };

    // Exécuter après le montage pour s'assurer que la carte prend toute la largeur disponible
    window.addEventListener('resize', resizeMap);
    resizeMap(); // Appel immédiat pour ajuster la taille dès le chargement

    // Nettoyage
    return () => window.removeEventListener('resize', resizeMap);
  }, []); // Exécuter une seule fois au montage

  const [viewport, setViewport] = useState({
    latitude: 50.6915893,
    longitude: 3.1741734,
    zoom: 12,
  });

  return (
    <Box height="700px" width="100%" mt="100px" position="relative">
      <Map
        {...viewport}
        onMove={evt => setViewport(evt.viewState)}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        
        {documents.map(doc => 
          doc.geopoint && (
            <Marker
              key={doc.id}
              latitude={doc.geopoint.latitude}
              longitude={doc.geopoint.longitude}
            >
              <Box 
                onClick={() => handleMarkerClick(doc)}
                cursor="pointer"
                _hover={{ transform: "scale(1.1)" }}
                transition="transform 0.2s"
              >
                <MdLocationOn size={30} color="red" />
              </Box>
            </Marker>
          )
        )}

        {selectedMarker && (
          <Popup
            latitude={selectedMarker.geopoint.latitude}
            longitude={selectedMarker.geopoint.longitude}
            onClose={() => setSelectedMarker(null)}
            closeOnClick={false}
          >
            <Box p={2}>
              <Text fontWeight="bold">{getVendorNameById(selectedMarker.salesCode)}</Text>
              <Text fontSize="sm">{selectedMarker.email}</Text>
              {selectedMarker.lastLogin && (
                <Text fontSize="xs" color="gray.500">
                  Dernière connexion: {new Date(selectedMarker.lastLogin.seconds * 1000).toLocaleString()}
                </Text>
              )}
            </Box>
          </Popup>
        )}
      </Map>
      
      <Box position="absolute" top={4} right={4} bg="white" p={2} borderRadius="md" boxShadow="md">
        <Text fontSize="sm" fontWeight="bold">Légende</Text>
        <Flex alignItems="center" mt={2}>
          <MdLocationOn color="red" />
          <Text fontSize="xs" ml={2}>Emplacement du vendeur</Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default CommercialMap;
