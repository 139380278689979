import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    NumberInput,
    NumberInputField,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputStepper
} from "@chakra-ui/react"
import { modifyChallenge } from "../services/challengesService";
import CustomSelect from "./CustomSelect";
import { useEffect, useState } from "react";
import { fetchItems } from "views/admin/items/services/itemService";

export default function ModifyModal({ isOpen, onClose, selectedDoc, setSelectedDoc, documents, setDocuments }) {
    const [items, setItems] = useState([]);
    const toast = useToast();
    console.log(selectedDoc)

    const updateLocalDocument = (field, value) => {
        // Créez une copie du document sélectionné avec la modification
        const updatedDocument = { ...selectedDoc, [field]: value };
        setSelectedDoc(updatedDocument);
        // Mettez à jour l'état local du tableau (documents) avec le document modifié
        const updatedDocuments = documents.map((doc) =>
            doc.id === updatedDocument.id ? updatedDocument : doc
        );
        setDocuments(updatedDocuments);
        setSelectedDoc(updatedDocument);
    };

    const handleSave = async () => {
        try {
            modifyChallenge(selectedDoc)
      
            // Affiche un toast de succès après la mise à jour
            toast({
              title: "Document mis à jour",
              description: "Les modifications ont été enregistrées avec succès.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
      
            onClose(); // Fermez la modale après la sauvegarde
          } catch (error) {
            console.error("Erreur lors de la sauvegarde :", error);
            // Affiche un toast d'erreur si la mise à jour échoue
            toast({
              title: "Échec de la mise à jour",
              description:
                "Une erreur est survenue lors de la sauvegarde des modifications.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchItems().then((items) => {
            console.log(items)
            setItems(items);
        });
    }, [])


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Modifier le challenge</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Input
                            value={selectedDoc.description}
                            onChange={(e) => updateLocalDocument("description", e.target.value)}
                        />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Récompense</FormLabel>
                        <CustomSelect items={items} formData={selectedDoc} setFormData={setSelectedDoc}  />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Nombre d'étape</FormLabel>
                        <NumberInput
                            defaultValue={selectedDoc.stepNumber}
                        >
                            <NumberInputField
                                onChange={(e) => updateLocalDocument("stepNumber", e.target.value)}
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="green" mr={3} onClick={handleSave}>
                        Sauvegarder
                    </Button>
                    <Button colorScheme="red" onClick={onClose}>Annuler</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}