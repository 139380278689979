import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";

export default function AdminNavbar({ onOpen, logoText, secondary, fixed }) {
  const bgColor = useColorModeValue("white", "navy.800");

  return (
    <Box
      position="fixed"
      top="0"
      right="0"
      zIndex="1000"
      bg={bgColor}
      boxShadow="sm"
      py={2}
      px={0}
    >
      <Flex justifyContent="flex-end">
        <AdminNavbarLinks
          onOpen={onOpen}
          logoText={logoText}
          secondary={secondary}
          fixed={fixed}
        />
      </Flex>
    </Box>
  );
}

AdminNavbar.propTypes = {
  onOpen: PropTypes.func,
  logoText: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
};
