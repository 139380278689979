// challengeService.js

import { db } from "lib/firebase";
import {
    collection,
    deleteDoc,
    getDocs,
    doc,
    addDoc,
    updateDoc,
    getDoc
} from "firebase/firestore";

// Fonction pour récupérer la liste des challenges depuis Firestore
export const fetchChallenges = async () => {
    const querySnapshot = await getDocs(collection(db, "challenges"));
    const challenges = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    // Fetch each corresponding item details
    const challengesWithItems = await Promise.all(challenges.map(async (challenge) => {
        const itemSnapshot = await getDoc(doc(db, "items", challenge.rewardItemId));
        const itemData = itemSnapshot.exists() ? itemSnapshot.data() : null;
        const chestSnapshot = await getDoc(doc(db, "chests", challenge.rewardItemId));
        const chestData = chestSnapshot.exists() ? chestSnapshot.data() : null;
        return {
            ...challenge,
            item: challenge.rewardType === 'item' ? itemData : null,
            chest: challenge.rewardType === 'chest' ? chestData : null,
        };
    }));

    console.log(challengesWithItems);

    return challengesWithItems;
};

// Fonction pour supprimer un challenge
export const deleteChallenge = async (challengeId) => {
    try {
        await deleteDoc(doc(db, "challenges", challengeId));
        await deleteProgression(challengeId);
        return true;
    } catch (error) {
        console.error("Error deleting challenge: ", error);
        return false;
    }
};

// Pour chaque utilisateur, suppression de la progression pour le challenge supprimé
const deleteProgression = async (challengeId) => {
    const usersSnapshot = await getDocs(collection(db, "users"));

    usersSnapshot.forEach(async (userDoc) => {

        const userId = userDoc.id;
        const userRef = doc(db, "users", userId);
        const progressionSnapshot = await getDocs(collection(userRef, "progression"));

        progressionSnapshot.forEach(async (progressionDoc) => {
            const progressionData = progressionDoc.data();
            if (progressionData.challengeId === challengeId) {
                await deleteDoc(progressionDoc.ref);
            }
        });
    });
}

export const addChallenge = async (description, rewardItemId, stepNumber, rewardType) => {
    // Ajouter le nouveau défi à la collection "challenges"
    const newChallengeRef = await addDoc(collection(db, "challenges"), {
        description: description,
        rewardItemId: rewardItemId,
        rewardType: rewardType,
        stepNumber: parseInt(stepNumber)
    });

    // Récupérer tous les documents de la collection "users"
    const usersSnapshot = await getDocs(collection(db, "users"));

    // Pour chaque utilisateur, ajouter une sous-collection "progression"
    usersSnapshot.forEach(async (userDoc) => {
        await addUserProgression(userDoc.id, newChallengeRef.id);
    });
}

export const modifyChallenge = async (selectedDoc) => {
    // Update the document in Firestore
    const docRef = doc(db, "challenges", selectedDoc.id);
    const updatedFields = {
        ...selectedDoc, // inclut toutes les modifications apportées à selectedDoc, y compris teamSalescode
    };
    await updateDoc(docRef, updatedFields);
}

const addUserProgression = async (userId, challengeId) => {
    try {
        // Ajouter une sous-collection "progression" à l'utilisateur spécifié
        const userProgressionRef = collection(db, `users/${userId}/progression`);
        await addDoc(userProgressionRef, {
            challengeId: challengeId,
            progression: 0, // La progression initiale est définie à 0 par défaut
            won: false
        });
    } catch (error) {
        console.error("Erreur lors de l'ajout de la progression pour l'utilisateur :", error);
        throw error; // Lancer l'erreur pour la gérer dans le composant
    }
}