// LeaderTable.js
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import axios from 'axios';
import {
  Box,
  VStack,
  Heading,
  Flex,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  useBreakpointValue,
  HStack,
  IconButton,
  Text,
  Grid,
  GridItem,
  Divider,
  Link as ChakraLink,
  Icon,
  InputRightElement,
} from '@chakra-ui/react';
import { AuthContext } from 'contexts/AuthContext';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import {
  MdBarChart,
  MdDownload,
  MdSearch,
  MdOutlineRotateLeft,
} from 'react-icons/md';
import { FaCheckCircle, FaSpinner, FaMobileAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useTranslation } from 'react-i18next';

const SFR_OFFERS = [
  'Mobile 200 Go 5G',
  'SFR PREMIUM',
  'Mobile 2h 100 Mo',
  'Mobile 100 Go 5G',
  'Mobile 250 Go 5G',
  'SFR POWER',
  '100Go 5G SIM Seule',
  'Mobile 5 Go 4G+',
  'Mobile 220 Go 5G',
  'SFR',
  'Sfr Power TV box 8',
  'Sfr Power box 8',
  'Mobile 140 Go 5G',
  'Mobile 80 Go 4G+',
  'Mobile 120 Go 5G',
  'Mobile 110 Go 5G',
  'Mobile 160 Go 5G',
  'Sfr box 8',
  'Sfr Power TV',
];

const STATUS_COLOR_SCHEMES = {
  EnCours: 'blue',
  EnCoursDeRattrapage: 'orange',
  Error: 'red',
  Validated: 'green',
  Payed: 'teal',
  RaccordOK: 'cyan',
  RaccordKO: 'pink',
  ToConfirm: 'purple',
  Progress: 'yellow',
  default: 'gray',
};

const periods = ['Semaine', 'Mois', 'Année'];
const statuses = [
  'Tous',
  'EnCours',
  'EnCoursDeRattrapage',
  'Error',
  'Validated',
  'Payed',
  'RaccordOK',
  'RaccordKO',
];
const periodsMap = {
  Semaine: 7,
  Mois: 30,
  Année: 365,
};

// Fonctions utilitaires
const formatDate = (date, isPaymentDate = false, displayTime = true) => {
  if (!date) return '';
  const d = new Date(date);
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hours = d.getHours();
  let minutes = d.getMinutes();

  if (isPaymentDate) {
    month += 1;
    if (month > 12) {
      month = 1;
      year++;
    }
  }

  const datePart = isNaN(d.getTime())
    ? ''
    : `${String(d.getDate()).padStart(2, '0')}/${String(month).padStart(
        2,
        '0'
      )}/${year}`;
  const timePart = displayTime
    ? ` ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    : '';

  return `${datePart}${timePart}`;
};

const getDiffDays = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((date1 - date2) / oneDay));
};

const extractPostalCode = (address) => {
  const postalCodeRegex = /(\d{5})/;
  const matches = address.match(postalCodeRegex);
  return matches ? matches[0] : '';
};

const translateStatus = (status) => {
  const translations = {
    EnCours: 'En cours',
    RaccordKO: 'Raccordement KO',
    RaccordOK: 'Raccordé',
    Inconnu: 'Inconnu',
    EnCoursDeRattrapage: 'En cours de rattrapage',
  };
  return translations[status] || status;
};

const getStatusColorScheme = (status) => {
  return STATUS_COLOR_SCHEMES[status] || STATUS_COLOR_SCHEMES.default;
};

const LeaderTable = ({ isOpen, onClose, salesCodes, agencyId, isTeam }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const tableHeaderBg = useColorModeValue('gray.50', 'gray.800');
  const tableRowBg = useColorModeValue('white', 'gray.700');
  const tableRowAltBg = useColorModeValue('gray.50', 'gray.600');

  const [leaderData, setLeaderData] = useState([]);
  const [vendorsMap, setVendorsMap] = useState({});
  const [vendors, setVendors] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('all');
  const [statsPeriod, setStatsPeriod] = useState('current');

  const [selectedDate, setSelectedDate] = useState(null);
  const [filter, setFilter] = useState({ period: 'Tous', status: 'Tous' });
  const [searchValue, setSearchValue] = useState('');
  const [offerFilter, setOfferFilter] = useState('Tous');
  const [postalCodeFilter, setPostalCodeFilter] = useState('');
  const [appliedPostalCodeFilter, setAppliedPostalCodeFilter] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [selectedOffer, setSelectedOffer] = useState('Tous');

  const [sortConfig, setSortConfig] = useState({
    key: 'CreatedDate',
    ascending: false,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const now = new Date();

  // Charger les équipes associées à l'agence
  useEffect(() => {
    const fetchTeams = async () => {
      if (agencyId) {
        try {
          const teamsQuery = query(
            collection(db, 'teams'),
            where('agencyId', '==', agencyId)
          );
          const teamsSnapshot = await getDocs(teamsQuery);
          const teamsData = teamsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTeams(teamsData);
        } catch (error) {
          console.error('Erreur lors de la récupération des équipes:', error);
        }
      }
    };

    if (isOpen && !isTeam) {
      fetchTeams();
    }
  }, [agencyId, isOpen, isTeam]);

  // Charger les données des vendeurs
  useEffect(() => {
    const fetchVendors = async () => {
      if (salesCodes && salesCodes.length > 0) {
        try {
          const vendorsQuery = query(
            collection(db, 'vendors'),
            where('__name__', 'in', salesCodes)
          );
          const vendorsSnapshot = await getDocs(vendorsQuery);
          const vendorsData = vendorsSnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().Name,
          }));

          // Créer le mapping des vendeurs
          const mapping = {};
          vendorsSnapshot.docs.forEach((doc) => {
            mapping[doc.id] = doc.data().Name;
          });
          setVendorsMap(mapping);

          setVendors(vendorsData);
        } catch (error) {
          console.error('Erreur lors de la récupération des vendeurs:', error);
        }
      }
    };

    if (isOpen) {
      fetchVendors();
    }
  }, [isOpen, salesCodes]);

  // Charger les données des leaders
  useEffect(() => {
    const fetchLeaderData = async () => {
      try {
        let salesToFetch = salesCodes;

        // Filtrer par équipe si sélectionnée
        if (selectedTeam !== 'all') {
          const selectedTeamData = teams.find(
            (team) => team.id === selectedTeam
          );
          if (selectedTeamData) {
            salesToFetch = selectedTeamData.vendors;
          }
        }

        // Filtrer par vendeur si sélectionné
        if (selectedVendor) {
          salesToFetch = [selectedVendor];
        }

        if (!salesToFetch || salesToFetch.length === 0) {
          setLeaderData([]);
          return;
        }

        const allRecords = [];
        const maxConcurrent = 10;
        for (let i = 0; i < salesToFetch.length; i += maxConcurrent) {
          const batch = salesToFetch.slice(i, i + maxConcurrent);
          const batchRequests = batch.map(async (salesCode) => {
            const response = await axios.get(
              `https://app.falconmarketing.fr:3001/api/salesforce_data?salesCode=${salesCode}`
            );
            return response.data.records.map((record) => ({
              ...record,
              VendorName: vendorsMap[salesCode] || salesCode,
              SalesCode__c: salesCode,
            }));
          });
          const batchResults = await Promise.all(batchRequests);
          allRecords.push(...batchResults.flat());
        }

        setLeaderData(allRecords);
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    if (isOpen) {
      fetchLeaderData();
    }
  }, [isOpen, salesCodes, selectedTeam, selectedVendor, teams, vendorsMap]);

  const availableOffers = useMemo(() => {
    const offers = new Set();
    leaderData.forEach((record) => {
      if (record.OfferName__c) offers.add(record.OfferName__c);
    });
    return Array.from(offers).sort();
  }, [leaderData]);

  const filteredRecords = useMemo(() => {
    const cutoffDate = new Date('2023-11-01');
    let filtered = leaderData.filter(
      (record) => new Date(record.CreatedDate) >= cutoffDate
    );

    if (filter.period !== 'Tous') {
      filtered = filtered.filter((record) => {
        const recordDate = new Date(record.CreatedDate);
        const diffDays = getDiffDays(now, recordDate);
        return diffDays <= periodsMap[filter.period];
      });
    }

    if (filter.status !== 'Tous') {
      filtered = filtered.filter(
        (record) =>
          record.Status__c === filter.status ||
          record.ConnectionStatus__c === filter.status
      );
    }

    if (offerFilter === 'SFR') {
      filtered = filtered.filter((record) =>
        SFR_OFFERS.includes(record.OfferName__c)
      );
    } else if (offerFilter === 'Bouygues') {
      filtered = filtered.filter(
        (record) => !SFR_OFFERS.includes(record.OfferName__c)
      );
    }

    if (selectedOffer !== 'Tous') {
      filtered = filtered.filter(
        (record) => record.OfferName__c === selectedOffer
      );
    }

    if (selectedDate) {
      const selectedMonth = selectedDate.getMonth();
      const selectedYear = selectedDate.getFullYear();

      filtered = filtered.filter((record) => {
        if (!record.DateOfPayment__c) return false;
        const paymentDate = new Date(record.DateOfPayment__c);
        return (
          paymentDate.getFullYear() === selectedYear &&
          paymentDate.getMonth() === selectedMonth - 1
        );
      });
    }

    if (appliedPostalCodeFilter) {
      filtered = filtered.filter((record) => {
        const postalCode = extractPostalCode(record.TchAddress__c);
        return postalCode.startsWith(appliedPostalCodeFilter);
      });
    }

    // Recherche
    const searchLower = searchValue.toLowerCase();
    filtered = filtered.filter(
      (record) =>
        record.TchProspectName__c?.toLowerCase().includes(searchLower) ||
        record.ProspectMobilePhone__c?.toLowerCase().includes(searchLower) ||
        record.OrderNumber__c?.toLowerCase().includes(searchLower) ||
        record.VendorName?.toLowerCase().includes(searchLower)
    );

    return filtered;
  }, [
    leaderData,
    filter,
    offerFilter,
    selectedOffer,
    selectedDate,
    appliedPostalCodeFilter,
    searchValue,
    now,
  ]);

  const sortedRecords = useMemo(() => {
    const sorted = [...filteredRecords];
    sorted.sort((a, b) => {
      const dateA = new Date(a[sortConfig.key]);
      const dateB = new Date(b[sortConfig.key]);

      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
      if (isNaN(dateA.getTime())) return sortConfig.ascending ? 1 : -1;
      if (isNaN(dateB.getTime())) return sortConfig.ascending ? -1 : 1;

      return sortConfig.ascending ? dateA - dateB : dateB - dateA;
    });

    return sorted.map((record) => ({
      ...record,
      CreatedDate: formatDate(record.CreatedDate, false, true),
      ConnectingDatePlanned__c: formatDate(
        record.ConnectingDatePlanned__c,
        false,
        false
      ),
      DateOfPayment__c: formatDate(record.DateOfPayment__c, true, false),
    }));
  }, [filteredRecords, sortConfig]);

  const offset = currentPage * entriesPerPage;
  const pageCount = Math.ceil(filteredRecords.length / entriesPerPage);

  const handleVendorChange = useCallback((e) => {
    setSelectedVendor(e.target.value);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const handleFilter = useCallback((period, status = 'Tous') => {
    setFilter({ period, status });
  }, []);

  const handlePageClick = useCallback(({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }, []);

  const handleDownloadExcel = useCallback(() => {
    if (filteredRecords.length === 0) return;

    const sortedData = sortedRecords.map((record) => ({
      ...record,
      CreatedDate: record.CreatedDate,
      ConnectingDatePlanned__c: record.ConnectingDatePlanned__c,
      DateOfPayment__c: record.DateOfPayment__c,
    }));

    const worksheet = XLSX.utils.json_to_sheet(sortedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SalesData');
    XLSX.writeFile(workbook, 'sales_data.xlsx');
  }, [filteredRecords, sortedRecords]);

  const toggleSortDirection = useCallback((key) => {
    setSortConfig((prevConfig) => ({
      key,
      ascending: prevConfig.key === key ? !prevConfig.ascending : true,
    }));
  }, []);

  const handleEntriesPerPageChange = useCallback((e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(0);
  }, []);

  const handlePostalCodeChange = useCallback((e) => {
    setPostalCodeFilter(e.target.value);
  }, []);

  const applyPostalCodeFilter = useCallback(() => {
    setAppliedPostalCodeFilter(postalCodeFilter);
  }, [postalCodeFilter]);

  const resetDatePicker = useCallback(() => {
    setSelectedDate(null);
  }, []);

  const handleOpenModal = useCallback((record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  }, []);

  const renderPaginationButtons = useCallback(() => {
    const buttons = [];
    const totalPages = pageCount;
    const currentPageIndex = currentPage;

    if (!isMobile) {
      // Version Desktop
      buttons.push(
        <Button
          key={0}
          onClick={() => handlePageClick({ selected: 0 })}
          colorScheme={currentPageIndex === 0 ? 'blue' : 'gray'}
          variant={currentPageIndex === 0 ? 'solid' : 'outline'}
        >
          1
        </Button>
      );

      if (currentPageIndex > 3) {
        buttons.push(<Text key="ellipsis-1">...</Text>);
      }

      for (
        let i = Math.max(1, currentPageIndex - 1);
        i <= Math.min(totalPages - 2, currentPageIndex + 1);
        i++
      ) {
        buttons.push(
          <Button
            key={i}
            onClick={() => handlePageClick({ selected: i })}
            colorScheme={currentPageIndex === i ? 'blue' : 'gray'}
            variant={currentPageIndex === i ? 'solid' : 'outline'}
          >
            {i + 1}
          </Button>
        );
      }

      if (currentPageIndex < totalPages - 4) {
        buttons.push(<Text key="ellipsis-2">...</Text>);
      }

      if (totalPages > 1) {
        buttons.push(
          <Button
            key={totalPages - 1}
            onClick={() => handlePageClick({ selected: totalPages - 1 })}
            colorScheme={currentPageIndex === totalPages - 1 ? 'blue' : 'gray'}
            variant={currentPageIndex === totalPages - 1 ? 'solid' : 'outline'}
          >
            {totalPages}
          </Button>
        );
      }
    } else {
      // Version Mobile
      buttons.push(
        <Text key="current-page" fontWeight="bold" mx={2}>
          {currentPageIndex + 1} / {totalPages}
        </Text>
      );
    }

    return buttons;
  }, [currentPage, pageCount, isMobile, handlePageClick]);

  // Calcul des statistiques
  const stats = useMemo(() => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const calculatedStats = {
      ventesValidees: 0,
      ventesRaccordees: 0,
      contratsEnCours: 0,
      contratsMobile: 0,
      totalVentes: 0,
    };

    sortedRecords.forEach((record) => {
      const recordDate = new Date(record.CreationDate__c || record.CreatedDate);

      if (
        statsPeriod === 'current' &&
        (recordDate < startOfMonth || recordDate > endOfMonth)
      ) {
        return;
      }

      calculatedStats.totalVentes++;

      if (
        record.Status__c === 'Validated' &&
        !record.OfferName__c?.includes('Mobile')
      ) {
        calculatedStats.ventesValidees++;
      }

      if (record.ConnectionStatus__c === 'RaccordOK') {
        calculatedStats.ventesRaccordees++;
      }

      if (
        record.ConnectionStatus__c === 'EnCours' ||
        record.Status__c === 'EnCours'
      ) {
        calculatedStats.contratsEnCours++;
      }

      if (
        record.OfferName__c?.includes('Mobile') &&
        record.Status__c === 'Validated'
      ) {
        calculatedStats.contratsMobile++;
      }
    });

    const calculateRate = (numerator, denominator) => {
      if (denominator === 0) return 0;
      return parseFloat(((numerator / denominator) * 100).toFixed(2));
    };

    calculatedStats.tauxRaccordement = calculateRate(
      calculatedStats.ventesRaccordees,
      calculatedStats.ventesValidees
    );
    calculatedStats.tauxConversionMobile = calculateRate(
      calculatedStats.contratsMobile,
      calculatedStats.ventesValidees
    );
    calculatedStats.tauxContratsEnCours = calculateRate(
      calculatedStats.contratsEnCours,
      calculatedStats.totalVentes
    );

    return calculatedStats;
  }, [sortedRecords, statsPeriod]);

  const statBoxes = useMemo(
    () => [
      {
        icon: FaCheckCircle,
        title: 'Ventes validées',
        value: stats.ventesValidees,
        color: 'blue.500',
      },
      {
        icon: FaCheckCircle,
        title: 'Raccordés',
        value: stats.ventesRaccordees,
        color: 'teal.500',
      },
      {
        icon: FaSpinner,
        title: 'En cours',
        value: stats.contratsEnCours,
        color: 'orange.500',
      },
      {
        icon: FaMobileAlt,
        title: 'Mobiles validés',
        value: stats.contratsMobile,
        color: 'purple.500',
      },
    ],
    [stats]
  );

  const handleStatsPeriodChange = (e) => {
    setStatsPeriod(e.target.value);
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderRadius="md" boxShadow="xl">
        <ModalHeader bg="blue.500" color="white" borderTopRadius="md">
          <Text fontSize="2xl" fontWeight="bold">
            {isTeam ? "Ventes de l'équipe" : 'Ventes de l\'agence'}
          </Text>
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody py={6}>
          {/* Header */}
          <VStack spacing={6} align="stretch">
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ base: 'stretch', md: 'center' }}
              gap={4}
            >
              <Heading size="lg" color="blue.600">
                Tableau de bord des ventes
              </Heading>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={2}
                width={{ base: '100%', md: 'auto' }}
              >
                {/* Boutons d'exportation */}
                <Button
                  onClick={handleDownloadExcel}
                  colorScheme="blue"
                  variant="solid"
                  leftIcon={<MdDownload />}
                  width="100%"
                >
                  Exporter en Excel
                </Button>
              </Flex>
            </Flex>

            {/* Filtres et recherche */}
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdSearch color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Rechercher..."
                  value={searchValue}
                  onChange={handleSearchChange}
                  borderColor="gray.300"
                />
              </InputGroup>

              {/* Filtre par équipe */}
              {!isTeam && (
                <Select value={selectedTeam} onChange={handleTeamChange}>
                  <option value="all">Toutes les équipes</option>
                  {teams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  ))}
                </Select>
              )}

              <Select
                value={selectedVendor}
                onChange={handleVendorChange}
                placeholder="Filtrer par vendeur"
              >
                <option value="">Tous les vendeurs</option>
                {vendors.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </option>
                ))}
              </Select>

              <Select
                onChange={(e) => setOfferFilter(e.target.value)}
                placeholder="Filtrer par fournisseur"
              >
                <option value="Tous">Tous</option>
                <option value="SFR">SFR</option>
                <option value="Bouygues">Bouygues</option>
              </Select>
              <Select
                onChange={(e) => setSelectedOffer(e.target.value)}
                placeholder="Filtrer par offre"
                value={selectedOffer}
              >
                <option value="Tous">Toutes les offres</option>
                {availableOffers.map((offer) => (
                  <option key={offer} value={offer}>
                    {offer}
                  </option>
                ))}
              </Select>
              <Select
                onChange={(e) => handleFilter(e.target.value, filter.status)}
                value={filter.period}
              >
                <option value="Tous">Toutes les périodes</option>
                {periods.map((period) => (
                  <option key={period} value={period}>
                    {period}
                  </option>
                ))}
              </Select>
              <Select
                onChange={(e) => handleFilter(filter.period, e.target.value)}
                value={filter.status}
              >
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {t(status)}
                  </option>
                ))}
              </Select>

              <Flex alignItems="center">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  customInput={<Input />}
                  placeholderText="Choisir une date de facturation"
                />
                <IconButton
                  aria-label="Réinitialiser la date"
                  icon={<MdOutlineRotateLeft />}
                  onClick={resetDatePicker}
                  ml={2}
                />
              </Flex>
              {/* Filtre par code postal */}
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Filtrer par code postal"
                  value={postalCodeFilter}
                  onChange={handlePostalCodeChange}
                  borderColor="gray.300"
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={applyPostalCodeFilter}>
                    Filtrer
                  </Button>
                </InputRightElement>
              </InputGroup>
              {/* Entrées par page */}
              <Select
                value={entriesPerPage}
                onChange={handleEntriesPerPageChange}
                placeholder="Entrées par page"
              >
                <option value={10}>10 entrées</option>
                <option value={50}>50 entrées</option>
                <option value={100}>100 entrées</option>
              </Select>
            </SimpleGrid>

            {/* Statistiques */}
            <Flex justify="space-between" align="center" mt={4}>
              <Text fontSize="lg" fontWeight="medium">
                Statistiques
              </Text>
              <Select
                value={statsPeriod}
                onChange={handleStatsPeriodChange}
                width="200px"
              >
                <option value="current">Mois actuel</option>
                <option value="all">Tout</option>
              </Select>
            </Flex>

            <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4} mb={6}>
              {statBoxes.map((box, index) => (
                <Box
                  key={index}
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderRadius="lg"
                  p={4}
                  textAlign="center"
                  transition="all 0.3s"
                  _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
                >
                  <Icon as={box.icon} boxSize={6} color={box.color} mb={2} />
                  <Text fontSize="sm" fontWeight="medium" mb={1}>
                    {box.title}
                  </Text>
                  <Text fontSize="xl" fontWeight="bold" color={box.color}>
                    {box.value}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>

            {/* Tableau des ventes */}
            <Box overflowX="auto">
              <Table
                variant="simple"
                size="sm"
                borderWidth="1px"
                borderColor="gray.200"
                color={textColor}
              >
                <Thead bg={tableHeaderBg}>
                  <Tr>
                    <Th
                      onClick={() => toggleSortDirection('CreatedDate')}
                      cursor="pointer"
                    >
                      Date de la vente{' '}
                      {sortConfig.key === 'CreatedDate' &&
                        (sortConfig.ascending ? '↑' : '↓')}
                    </Th>
                    <Th>Vendeur</Th>
                    <Th>Nom</Th>
                    <Th>Numéro de téléphone</Th>
                    <Th>Code Postal</Th>
                    <Th>Offre</Th>
                    <Th
                      onClick={() =>
                        toggleSortDirection('ConnectingDatePlanned__c')
                      }
                      cursor="pointer"
                    >
                      Date de raccordement prévue{' '}
                      {sortConfig.key === 'ConnectingDatePlanned__c' &&
                        (sortConfig.ascending ? '↑' : '↓')}
                    </Th>
                    <Th>Statut</Th>
                    <Th>Raccordement</Th>
                    <Th>Numéro de Panier</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedRecords
                    .slice(offset, offset + entriesPerPage)
                    .map((record, index) => (
                      <Tr
                        key={record.OrderNumber__c || index}
                        onClick={() => handleOpenModal(record)}
                        _hover={{
                          bg: isMobile ? undefined : 'gray.100',
                        }}
                        cursor="pointer"
                        borderBottom="1px solid"
                        borderColor={borderColor}
                        bg={index % 2 === 0 ? tableRowBg : tableRowAltBg}
                      >
                        <Td>{record.CreatedDate}</Td>
                        <Td>{record.VendorName}</Td>
                        <Td>{record.TchProspectName__c}</Td>
                        <Td>
                          <ChakraLink
                            href={`tel:${record.ProspectMobilePhone__c}`}
                            color="blue.500"
                          >
                            {record.ProspectMobilePhone__c}
                          </ChakraLink>
                        </Td>
                        <Td>{extractPostalCode(record.TchAddress__c)}</Td>
                        <Td>{record.OfferName__c}</Td>
                        <Td>{record.ConnectingDatePlanned__c}</Td>
                        <Td>
                          <Tag
                            colorScheme={getStatusColorScheme(
                              record.Status__c
                            )}
                            size="sm"
                          >
                            {t(record.Status__c)}
                          </Tag>
                        </Td>
                        <Td>
                          {record.ConnectionStatus__c && (
                            <Tag
                              colorScheme={getStatusColorScheme(
                                record.ConnectionStatus__c
                              )}
                              size="sm"
                            >
                              {translateStatus(record.ConnectionStatus__c)}
                            </Tag>
                          )}
                        </Td>
                        <Td>{record.BasketNumber__c}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>

            {/* Pagination */}
            <Flex justifyContent="center" mt={4}>
              <HStack spacing={2}>
                <IconButton
                  icon={<ChevronLeftIcon />}
                  onClick={() =>
                    handlePageClick({ selected: Math.max(0, currentPage - 1) })
                  }
                  isDisabled={currentPage === 0}
                  aria-label="Page précédente"
                  variant="outline"
                  colorScheme="blue"
                  size={isMobile ? 'sm' : 'md'}
                />
                {renderPaginationButtons()}
                <IconButton
                  icon={<ChevronRightIcon />}
                  onClick={() =>
                    handlePageClick({
                      selected: Math.min(pageCount - 1, currentPage + 1),
                    })
                  }
                  isDisabled={currentPage === pageCount - 1}
                  aria-label="Page suivante"
                  variant="outline"
                  colorScheme="blue"
                  size={isMobile ? 'sm' : 'md'}
                />
              </HStack>
            </Flex>
          </VStack>
        </ModalBody>

        {/* Modal pour les détails de la vente */}
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent bg={bgColor} borderRadius="md">
            <ModalHeader bg="blue.600" color="white" borderTopRadius="md">
              Détails de la vente
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody py={6}>
              {currentRecord && (
                <VStack align="stretch" spacing={6} width="100%">
                  {/* Informations client */}
                  <Box>
                    <Heading size="md" color="blue.600" mb={3}>
                      Informations client
                    </Heading>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                      <GridItem>
                        <Text fontWeight="bold">Nom</Text>
                        <Text>{currentRecord.TchProspectName__c}</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Téléphone</Text>
                        <ChakraLink
                          href={`tel:${currentRecord.ProspectMobilePhone__c}`}
                          color="blue.500"
                        >
                          {currentRecord.ProspectMobilePhone__c}
                        </ChakraLink>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Email</Text>
                        <Text>{currentRecord.ProspectEmail__c}</Text>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Text fontWeight="bold">Adresse</Text>
                        <Text>{currentRecord.TchAddress__c}</Text>
                      </GridItem>
                    </Grid>
                  </Box>

                  <Divider />

                  {/* Détails de la commande */}
                  <Box>
                    <Heading size="md" color="blue.600" mb={3}>
                      Détails de la commande
                    </Heading>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                      <GridItem>
                        <Text fontWeight="bold">Offre</Text>
                        <Text>{currentRecord.OfferName__c}</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Date de création</Text>
                        <Text>{currentRecord.CreatedDate}</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">
                          Date de raccordement prévue
                        </Text>
                        <Text>{currentRecord.ConnectingDatePlanned__c}</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Statut</Text>
                        <Tag
                          colorScheme={getStatusColorScheme(
                            currentRecord.Status__c
                          )}
                        >
                          {t(currentRecord.Status__c)}
                        </Tag>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Statut de raccordement</Text>
                        <Tag
                          colorScheme={getStatusColorScheme(
                            currentRecord.ConnectionStatus__c
                          )}
                        >
                          {translateStatus(currentRecord.ConnectionStatus__c)}
                        </Tag>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Numéro de commande</Text>
                        <Text>{currentRecord.OrderNumber__c}</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">Numéro de panier</Text>
                        <Text>{currentRecord.BasketNumber__c}</Text>
                      </GridItem>
                    </Grid>
                  </Box>

                  <Divider />

                  {/* Informations vendeur */}
                  <Box>
                    <Heading size="md" color="blue.600" mb={3}>
                      Informations vendeur
                    </Heading>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                      <GridItem>
                        <Text fontWeight="bold">Nom du vendeur</Text>
                        <Text>{currentRecord.VendorName}</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">ID du vendeur</Text>
                        <Text>{currentRecord.VendorId__c}</Text>
                      </GridItem>
                    </Grid>
                  </Box>
                </VStack>
              )}
            </ModalBody>
            <ModalFooter bg="gray.100" borderBottomRadius="md">
              <Button
                colorScheme="blue"
                onClick={() => setIsModalOpen(false)}
              >
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ModalContent>
    </Modal>
  );
};

export default LeaderTable;