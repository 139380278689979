import React, { useContext, useMemo, useState } from 'react';
import { 
  Box, 
  Spinner, 
  Flex, 
  Heading, 
  Text, 
  useColorModeValue, 
  Icon, 
  SimpleGrid, 
  VStack, 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionPanel, 
  AccordionIcon, 
  Select,
  Tooltip
} from "@chakra-ui/react";
import { FaFileContract, FaMobileAlt, FaCheckCircle, FaSpinner, FaClipboardList } from 'react-icons/fa';
import { AuthContext } from "contexts/AuthContext";
import { useData } from 'contexts/DataProvider';

const ContratsValides = () => {
    const { user } = useContext(AuthContext);
    const { data, getVendorName } = useData();
    const bgColor = useColorModeValue("white", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const hoverBgColor = useColorModeValue("gray.50", "gray.600");

    const [selectedSeller, setSelectedSeller] = useState('Tous');

    const calculateRate = (numerator, denominator) => {
        if (denominator === 0) return 0;
        return parseFloat(((numerator / denominator) * 100).toFixed(2));
    };


    const sellerOptions = useMemo(() => {
        if (!data || !Array.isArray(data)) return ['Tous'];
        const sellers = new Set(data.map(record => record.VendorName));
        return ['Tous', ...Array.from(sellers).sort()];
    }, [data]);

    const handleSellerChange = (e) => {
        setSelectedSeller(e.target.value);
    };

    const { startOfMonth, endOfMonth } = useMemo(() => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return {
            startOfMonth: start.getTime(),
            endOfMonth: end.getTime()
        };
    }, []);

    const stats = useMemo(() => {
        if (!data || !Array.isArray(data)) return {};

        const counts = {
            ventesValidees: 0,
            ventesRaccordees: 0,
            contratsEnCours: 0,
            contratsMobile: 0,
            contratsDuMois: 0
        };

        data.forEach(record => {
            if (selectedSeller.toLowerCase() !== 'tous' && record.VendorName !== selectedSeller) {
                return;
            }

            const recordDate = new Date(record.CreationDate__c || record.CreatedDate).getTime();

            if (recordDate >= startOfMonth && recordDate <= endOfMonth) {
                counts.contratsDuMois++;

                if (record.OrderNumber__c && record.OrderNumber__c.startsWith('THD')) {
                    counts.ventesValidees++;
                }
                if (record.ConnectionStatus__c === 'RaccordOK') {
                    counts.ventesRaccordees++;
                }
                if (record.ConnectionStatus__c === 'EnCours') {
                    counts.contratsEnCours++;
                }
                if (record.OfferName__c?.includes('Mobile') && record.Status__c === 'Validated') {
                    counts.contratsMobile++;
                }
            }
        });

        counts.tauxRaccordement = calculateRate(counts.ventesRaccordees, counts.ventesValidees);

        return counts;
    }, [data, startOfMonth, endOfMonth, selectedSeller]);


    const isAdminOrManager = user?.profileData?.leader || user?.profileData?.admin || user?.profileData?.manager;

    if (!data || !Array.isArray(data)) {
        return <Spinner size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />;
    }

    const statBoxes = [
        { icon: FaCheckCircle, title: "Ventes validées Box", value: stats.ventesValidees, color: "blue.500" },
        { icon: FaCheckCircle, title: "Raccordés", value: stats.ventesRaccordees, color: "teal.500" },
        { icon: FaSpinner, title: "En cours", value: stats.contratsEnCours, color: "orange.500" },
        { icon: FaMobileAlt, title: "Mobiles validés", value: stats.contratsMobile, color: "purple.500" }
    ];

    return (
        <Accordion allowToggle>
            <AccordionItem border="none">
                <AccordionButton 
                    as={Box} 
                    bg={bgColor} 
                    borderRadius="xl" 
                    boxShadow="md" 
                    p={4} 
                    mt={8}
                    _hover={{ bg: hoverBgColor }}
                >
                    <Flex align="center" justify="space-between" width="100%">
                        <Heading size="md" textAlign="left" color={textColor}>
                            <Icon as={FaClipboardList} mr={2} />
                            Votre production du mois
                        </Heading>
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <Box bg={bgColor} borderRadius="xl" boxShadow="xl" p={6}>
                        {isAdminOrManager && (
                            <Flex mb={4} align="center">
                                <Text mr={2} fontWeight="medium">Filtrer par vendeur:</Text>
                                <Select 
                                    value={selectedSeller}
                                    onChange={handleSellerChange}
                                    maxW="200px"
                                    placeholder="Sélectionnez un vendeur"
                                >
                                    {sellerOptions.map((seller, index) => (
                                        <option key={index} value={seller}>
                                            {seller}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        )}
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
                            {statBoxes.map((box, index) => (
                                <Box
                                    key={index}
                                    borderWidth="1px"
                                    borderColor={borderColor}
                                    borderRadius="lg"
                                    p={4}
                                    textAlign="center"
                                    transition="all 0.3s"
                                    _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
                                >
                                    <Icon as={box.icon} boxSize={8} color={box.color} mb={3} />
                                    <Text fontSize="lg" fontWeight="medium" mb={2} color={textColor}>{box.title}</Text>
                                    <Text fontSize="3xl" fontWeight="bold" color={box.color}>{box.value}</Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                        <Tooltip label="Pourcentage de contrats raccordés par rapport aux contrats validés" hasArrow placement="top">
                            <Box
                                borderWidth="1px"
                                borderColor={borderColor}
                                borderRadius="lg"
                                p={4}
                                textAlign="center"
                                transition="all 0.3s"
                                _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
                                mt={6}
                            >
                                <Text fontSize="lg" fontWeight="medium" mb={2} color={textColor}>Taux de raccordement</Text>
                                <Text fontSize="3xl" fontWeight="bold" color="teal.500">{stats.tauxRaccordement}%</Text>
                            </Box>
                        </Tooltip>
                    </Box>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default ContratsValides;