import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { getCachedData, cacheData } from "../utils/cache";
import { db } from "../lib/firebase"; // Ajoutez cette ligne
import { collection, getDocs } from "firebase/firestore"; // Ajoutez cette ligne

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const { user } = useContext(AuthContext);
  const [vendorNames, setVendorNames] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Pour suivre si les données ont déjà été chargées
  const [cachedData, setCachedData] = useState({});

  const fetchVendorNames = useCallback(async () => {
    try {
      const cachedVendors = await getCachedData('vendorNames');
      if (cachedVendors) {
        setVendorNames(cachedVendors);
      } else {
        const vendorsRef = collection(db, "vendors");
        const vendorsSnapshot = await getDocs(vendorsRef);
        const vendors = {};
        vendorsSnapshot.forEach((doc) => {
          vendors[doc.id] = doc.data().Name;
        });
        setVendorNames(vendors);
        await cacheData('vendorNames', vendors, 24 * 60 * 60 * 1000); // Cache for 24 hours
      }
    } catch (error) {
      console.error("Erreur lors du chargement des noms des vendeurs:", error);
    }
  }, []);

  useEffect(() => {
    fetchVendorNames();
  }, [fetchVendorNames]);

  const fetchData = useCallback(async () => {
    if (!user || isDataLoaded || Object.keys(vendorNames).length === 0) {
      console.log("Les données ont déjà été chargées ou sont en attente des prérequis.");
      return;
    }

    const cacheKey = user.profileData?.admin ? 'admin' : user.profileData?.salesCode;
    
    const cachedUserData = await getCachedData(cacheKey);
    if (cachedUserData) {
      console.log("Utilisation des données en cache");
      setData(cachedUserData);
      setIsDataLoaded(true);
      return;
    }

    try {
      let allRecords = [];

      console.log("Données de l'utilisateur:", user.profileData);

      if (user.profileData?.admin) {
        console.log("Chargement des données pour l'admin...");
        const response = await axios.get(
          `https://app.falconmarketing.fr:3001/uploads/sales_data.json?nocache=${new Date().getTime()}`,
          { headers: { "Cache-Control": "no-cache" } }
        );
        
        if (response.data && Array.isArray(response.data)) {
          allRecords = response.data.map(record => ({
            ...record,
            VendorName: vendorNames[record.Seller__c] || "Nom de vendeur inconnu",
          }));
          console.log("Données chargées pour l'admin:", allRecords);
        } else {
          throw new Error("Les données reçues ne sont pas dans le format attendu");
        }
      } else if (user.profileData?.superManager || user.profileData?.manager || user.profileData?.leader) {
        console.log("Chargement des données pour le super manager/manager/leader...");
        const teamSalescode = user.profileData.teamSalescode.filter(code => code);
        
        console.log("teamSalescode après filtrage:", teamSalescode);

        if (Array.isArray(teamSalescode) && teamSalescode.length > 0) {
          allRecords = await Promise.all(teamSalescode.map(async (salesCode) => {
            const response = await axios.get(
              `https://app.falconmarketing.fr:3001/api/salesforce_data?salesCode=${salesCode}`
            );
            return response.data.records.map(record => ({
              ...record,
              VendorName: vendorNames[record.Seller__c] || "Nom de vendeur inconnu",
            }));
          })).then(recordArrays => recordArrays.flat());
          console.log("Données chargées pour le super manager/manager/leader:", allRecords);
        } else {
          console.error("teamSalescode n'est pas un tableau valide ou est vide.");
          allRecords = [];
        }
      } else {
        console.log("Chargement des données pour un utilisateur standard...");
        const response = await axios.get(
          `https://app.falconmarketing.fr:3001/api/salesforce_data?salesCode=${user.profileData.salesCode}`
        );
        allRecords = response.data.records.map(record => ({
          ...record,
          VendorName: vendorNames[record.Seller__c] || "Nom de vendeur inconnu",
        }));
        console.log("Données chargées pour l'utilisateur:", allRecords);
      }

      await cacheData(cacheKey, allRecords, 5 * 60 * 1000); // Cache for 5 minutes
      setData(allRecords);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      setData([]);
    }
  }, [user, vendorNames, isDataLoaded]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (user) {
      setIsDataLoaded(false);
    }
  }, [user]);

  const getVendorName = useCallback((vendorId) => {
    return vendorNames[vendorId] || "Nom de vendeur inconnu";
  }, [vendorNames]);

  const contextValue = useMemo(() => ({
    data: Array.isArray(data) ? data : [], // Assurez-vous que data est toujours un tableau
    getVendorName,
    // Vous pouvez ajouter d'autres valeurs ou fonctions ici si nécessaire
  }), [data, getVendorName]);

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};