import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from 'contexts/NotificationContext';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react';

const NotificationActivation = () => {
    const { requestPermission, isSupported } = useContext(NotificationContext);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!isSupported) {
            return;
        }

        // Vérifier si l'utilisateur a déjà fait son choix
        const hasUserMadeChoice = localStorage.getItem('notificationChoice');
        
        if (!hasUserMadeChoice && typeof Notification !== 'undefined' && Notification.permission === 'default') {
            setShowModal(true);
        }
    }, [isSupported]);

    const handleRequestClick = async () => {
        if (typeof Notification !== 'undefined') {
            try {
                await requestPermission();
                localStorage.setItem('notificationChoice', 'accepted');
                setShowModal(false);
            } catch (error) {
                console.error('Erreur lors de la demande de permission:', error);
                localStorage.setItem('notificationChoice', 'rejected');
                setShowModal(false);
            }
        }
    };

    const handleRefuseClick = () => {
        localStorage.setItem('notificationChoice', 'rejected');
        setShowModal(false);
    };

    return (
        <Modal isOpen={showModal} onClose={() => {
            localStorage.setItem('notificationChoice', 'rejected');
            setShowModal(false);
        }}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Notifications</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <p>Voulez-vous activer les notifications pour rester informé ?</p>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleRequestClick}>
                        Activer les Notifications
                    </Button>
                    <Button colorScheme="red" onClick={handleRefuseClick}>
                        Refuser
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NotificationActivation;