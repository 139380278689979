import React, { useState, useMemo, useCallback } from "react";
import Map, { Marker, Popup, NavigationControl, ScaleControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MdOutlineMoney } from "react-icons/md";
import { Box } from "@chakra-ui/react";

const MAPBOX_TOKEN = "pk.eyJ1IjoiZG9rZ28iLCJhIjoiY2xzZDA4cHNsMGpiNjJpbWhobm03azNyeCJ9.rfLUyJVQL_JaaHkZV-tmhA";

const MapComponent = ({ salesData }) => {
  const [selectedSale, setSelectedSale] = useState(null);
  const [viewState, setViewState] = useState({
    latitude: 50.758216,
    longitude: 3.010372,
    zoom: 12,
  });

  const markers = useMemo(() => {
    return salesData.map((sale) => (
      <Marker
        key={sale.result_id}
        latitude={sale.latitude}
        longitude={sale.longitude}
      >
        <Box
          width="20px"
          height="20px"
          backgroundColor="rgba(0, 128, 0, 0.6)"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          onClick={() => setSelectedSale(sale)}
        >
          <MdOutlineMoney color="white" size={14} />
        </Box>
      </Marker>
    ));
  }, [salesData]);

  const handleViewStateChange = useCallback(({ viewState }) => {
    setViewState(viewState);
  }, []);

  return (
    <Box>
      <Map
        {...viewState}
        onMove={handleViewStateChange}
        style={{
          width: "100%",
          height: "600px",
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {markers}

        {selectedSale && (
          <Popup
            latitude={selectedSale.latitude}
            longitude={selectedSale.longitude}
            onClose={() => setSelectedSale(null)}
            closeOnClick={false}
          >
            <Box>
              <Box as="h3">{selectedSale.address}</Box>
              <Box>Prix: {selectedSale.price} €</Box>
              <Box>Date: {new Date(selectedSale.date).toLocaleDateString()}</Box>
            </Box>
          </Popup>
        )}

        <NavigationControl position="top-right" />
        <ScaleControl position="bottom-right" />
      </Map>
    </Box>
  );
};

export default MapComponent;
