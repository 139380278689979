import React, { createContext, useContext, useEffect, useState } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { app as firebaseApp } from "lib/firebase";

export const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    // Vérifier si le navigateur supporte les fonctionnalités requises
    const checkSupport = () => {
      const isSupported = 'serviceWorker' in navigator && 'Notification' in window;
      setIsSupported(isSupported);
      return isSupported;
    };

    if (checkSupport()) {
      const messaging = getMessaging(firebaseApp);
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log("Message reçu: ", payload);
        setNotification(payload);
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  const requestPermission = async () => {
    if (!isSupported) {
      setError(new Error("Les notifications ne sont pas supportées sur ce navigateur"));
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const messaging = getMessaging(firebaseApp);
        const currentToken = await getToken(messaging, {
          vapidKey: "BO12gm0zq7wuWzATEBEQavzqDW7a_1afAgZhtfDMJO7TcsrEZbc8Z-wj2ltw3hjynCNM8wDSCvVLhhRvepUthNc"
        });
        setToken(currentToken);
      }
    } catch (error) {
      console.error('Erreur lors de la demande de permission:', error);
      setError(error);
    }
  };

  const value = {
    notification,
    requestPermission,
    token,
    error,
    isSupported
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
